<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                tenant_id: '',
                type_id: 2,
                concept: '',
                number: '',
                service_id: '',
                file: '',
                xml: '',
            },
            file: '',
            fileName: '',
            xml: '',
            xmlName: '',
            send: false,
            load: false,
        }
    },
    async mounted() {
       // this.catalogue.get_types_bill_number();
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.type_id = 2;
            this.form.tenant_id = '';
            this.form.number = '';
            this.form.service_id = '';
            this.form.file = '';
            this.form.concept = '';
            this.form.xml = '';
            this.send = false;
            this.load = false;
            this.fileName = '';
            this.xmlName = '';
            this.file = '';
            this.xml = '';
            this.$modal.hide('addNumberServiceTenant');
        },
        catch_file(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.file = event.target.files[0];
                this.fileName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        catch_xml(event) {
            console.log(event.target.files[0].type);
            if (event.target.files[0].type == "text/xml") {
                this.form.xml = event.target.files[0];
                this.xmlName = event.target.files[0].name;
                this.catchDataXML(event.target.files[0]);


            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos XML',
                });
            }
        },
        async saveNumberBill() {
            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('type_id', this.form.type_id)
            data_form.append('tenant_id', this.form.tenant_id)
            data_form.append('service_id', this.form.service_id)
            data_form.append('number', this.form.number)
            data_form.append('file', this.form.file);
            data_form.append('xml', this.form.xml);
            data_form.append('concept', this.form.concept);


            let _endpoint = '/tenant_service_bill_number';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/tenant_service_bill_number/${this.form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-debts');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }

        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-addNumberServiceTenants", async (data, type, number) => {

            console.log(data);
            console.log(type);
            console.log(number);

            let concept = '';
            if(data.department){
                concept += `${data.department.number} en la planta ${data.department.level}`;
            }

            let nowDate = this.moment(data.star_date);
            let dateConcept = nowDate.format('MMMM [de] YYYY');

            if(data.building){
                if(data.building.address != ''){
                concept += `, del inmueble ubicado en ${data.address}`;
                }
            }

            concept += `, renta del mes de ${dateConcept}`;
            this.form.concept = concept;

            if (number) {
                this.form.id = number.id;
                this.form.tenant_id = number.tenant_id;
                this.form.number = number.number;
                this.form.service_id = number.service_id;
                this.form.type_id = 2;
                this.form.concept = number.concept;

                if (number.file) {
                    this.file = number.file;
                }
                if (number.xml) {
                    this.xml = number.xml;
                }
            } else {
                if (type == 'tenant') {
                    this.form.tenant_id = data.id;
                } else {
                    this.form.service_id = data.id;
                }
            }


            this.$modal.show('addNumberServiceTenant');

        })
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },

}
</script>
<template>
    <modal name="addNumberServiceTenant" :width="'80%'" :maxHeight="300" :height="'auto'"
        v-bind:click-to-close="false">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveNumberBill" method="post" id="formTax" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Agregar número de factura</h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-md-6 mt-3">
                        <label>Número de factura</label>
                        <input class="form-control" v-model="form.number">
                        <div v-if="errors && errors.number" class="text-danger">{{ errors.number[0] }}</div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <label>Concepto</label>
                        <input class="form-control" v-model="form.concept">
                        <div v-if="errors && errors.concept" class="text-danger">{{ errors.concept[0] }}</div>
                    </div>


                    <div class="col-md-6 mt-3">
                        <label>PDF</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input :disabled="!prop_edit" type="file" class="custom-file-input"
                                :class="file != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>
                    <div class="col-md-6 mt-3">
                        <label>XML</label><br>
                        <div class="custom-file" :style="xml != '' ? 'width: 87%' : 'width: 100%'">
                            <input :disabled="!prop_edit" type="file" id="fileXML" class="custom-file-input"
                                :class="xml != '' ? 'pr-5' : ''" ref="file" @change="catch_xml">
                            <label class="custom-file-label text-left" for="customFile">{{ xmlName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${xml}`" v-if="xml != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.xml" class="text-danger">{{ errors.xml[0] }}</div>
                    </div>


                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>

                </div>
            </div>
        </form>
    </modal>
</template>