<template>
	<modal v-bind:click-to-close="false" name="ListPayServices" :width="'80%'" :scrollable="true" :maxHeight="300"
		:height="'auto'">
		<button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
				class="fa-solid fa-xmark"></i> </button>
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel">Lista de Pagos - {{ department.level }} / {{
		department.number }}</h5>
		</div>
		<div class="modal-body">
			<div class="row">
				<div class="col-12 text-right">

					<button @click="editBuy()" v-can="'add_buy'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Pago
					</button>
				</div>
				<div class="col-12 mt-3">

					<div class="table-responsive">
						<table class="table table-bordered text-center text-sm">
							<thead>
								<tr>
									<th>ID</th>
									<th style="width: 50%;">Datos del pago</th>
									<th>Fecha</th>
									<th>Servicio</th>
									<th style="width: 140px;">Monto</th>
									<th>Opciones</th>
								</tr>
							</thead>
							<tbody v-if="load">
								<tr>
									<td align="center" colspan="7">
										<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
										<h5>Cargando</h5>
									</td>
								</tr>
							</tbody>
							<tbody v-else-if="buys.total > 0">
								<tr :class="buy.authorization_user_id ? 'bg-info' : ''"
									v-for="(buy, index) in buys.data" :key="index">
									<td>
										{{ buy.id }}
										<!-- <div class=" mt-3" v-if="!buy.file">
											<i
												class="fa-solid fa-file-circle-exclamation text-warning fa-xl  fa-fade"></i>
										</div> -->

									</td>
									<td class="text-left">
										<strong>Usuario:</strong><br>
										<span v-if="buy.user">
											{{ buy.user.name }} {{ buy.user.last_name }}
										</span>
										<span v-else>Sin usuario</span>
										<br>

										<!-- Recurrencia -->
										<strong>Recurrencia:</strong><br>
										<span v-if="buy.payments_recurring"> {{ buy.payments_recurring.name
											}}</span>
										<span v-else>Sin Recurrencia</span>
										<br>



									</td>
									<td>
										<span v-if="buy.star_date"> {{ buy.star_date | toDateTime }} </span>
										<span v-if="buy.end_date"> - {{ buy.end_date | toDateTime }} </span>

									</td>
									<td >
										<span v-if="buy.service">
											{{ buy.service.name }} 
										</span>
										<span v-else>S/N</span>
									</td>
									<td class="text-right">
										{{ buy.amount | toCurrency }}
									</td>
									
									<!-- <td class="text-right">
										{{ paysCheks(buy.checks) | toCurrency }}
									</td>
									<td class="text-right">
										{{ buy.amount - paysCheks(buy.checks) | toCurrency }}
									</td> -->

									<td>

										<button @click="editBuy(buy, false)" type="button"
											class="btn btn-sm  m-1 btn-outline-secondary ">
											<i class="fa-solid fa-eye"></i> Ver
										</button>

										<button @click="showPays(buy)" type="button"
											class="btn btn-sm  m-1 btn-outline-warning ">
											<i class="fa-solid fa-hand-holding-dollar"></i> Ver pagos
										</button>

										<button @click="editBuy(buy)" v-can="'edit_buy'"
											class="btn btn-outline-primary m-1 btn-sm">
											<i class="fas fa-edit "> </i> Editar
										</button>

										<button type="button" @click="deleted(buy.id)" v-can="'deleted_buy'"
											class="btn-outline-danger btn m-1 btn-sm ">
											<i class="fa fa-trash"></i> Eliminar
										</button>

										<button type="button" v-if="buy.checks.length > 0"
											@click="showCheck(buy.checks)"
											class="btn m-1 btn-outline-secondary btn btn-sm ">
											<i class="fa-solid fa-hand-holding-dollar"></i> Pagos
										</button>
									</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td align="center" colspan="7">Sin resultados.</td>
								</tr>
							</tbody>
							<!-- <tfoot v-if="buys.total > 0 && load == false">
								<tr class="table-secondary">
									<td colspan="3">
										Total :
									</td>
									<td class="text-right">
										{{ sumdata()[0] | toCurrency }}
									</td>
									<td class="text-right">
										{{ sumdata()[1] | toCurrency }}
									</td>
									<td class="text-right">
										{{ sumdata()[2] | toCurrency }}
									</td>
									<td></td>
								</tr>
							</tfoot> -->
						</table>
						<pagination v-model="page" :records="buys.total" :per-page="buys.per_page" @paginate="list" />
					</div>
				</div>

				<ModalForm :prop_edit="editForm"/>
				<ModalTablePays />
			</div>
		</div>
	</modal>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import Pagination from 'vue-pagination-2';
import request from '@/services/request';
import Catalogue from '@/services/catalogue';
import ModalForm from '@/components/departments/ModalUserDepartamentComponent.vue';
import ModalTablePays from '@/components/departments/ModalTablePaysComponent.vue'

export default {
	name: "companies-table",
	components: {
		Pagination,
		ModalForm,
		ModalTablePays
	},
	data() {
		return {
			name: '',
			buys: {},
			department: {},
			search: {
				search: '',
				department_id: '',
				payment_period_id: [1, 2, 3, 4, 6, 7, 8, 9]
			},
			page: 1,
			load: false,
			catalogue: new Catalogue(),
			editForm:false,
		}
	},
	mounted() {
		this.list();
		this.catalogue.get_buildings();
		this.catalogue.get_companies_buildings();
		this.catalogue.get_users_profile(2);
		this.catalogue.get_concepts();
		this.setHeaderTitle('Gastos recurrentes');
	},
	created() {
		Event.$on("event-load-pays-services", (department) => {
			this.search.department_id = department.id;
			this.department = department;
			this.$modal.show('ListPayServices');
			this.list();
		});
		Event.$on("event-load-pays", () => {
			this.list();
		});
	},
	methods: {
		closedModal() {
			this.$modal.hide('ListPayServices');
		},
		showPays(pay){
			Event.$emit('event-show-tablePays', pay);

		},
		editBuy(buy = {}, edit = true) {
			this.editForm = edit;

			let department = this.department;
			Event.$emit('event-show-addDepartamentUser', department, buy);
		},
		sumdata() {
			let vm = this;
			let sum1 = 0;
			let sum2 = 0;
			let sum3 = 0;
			if (this.buys) {
				this.buys.data.forEach(function (buy) {
					sum1 += parseFloat(buy.amount);
					sum2 += vm.paysCheks(buy.checks);
					sum3 += parseFloat(buy.amount) - vm.paysCheks(buy.checks);
				});
			}

			return [sum1, sum2, sum3];
		},
		viewPay(id) {
			Event.$emit('event-load-buy', id);
		},
		showBuysRelation(buy) {
			Event.$emit('event-show-BuysRelation', buy);
		},
		exportData() {
			let filter = this.globalUrlFiles + '/buys/export?' + new URLSearchParams(this.search).toString();
			filter += '&user_login=' + this.authUser.id;
			window.open(filter, '_blank');
		},
		showCheck(checks) {
			Event.$emit('event-show-listChecks', checks);
		},
		paysCheks(checks) {
			let sum = 0;
			console.log(checks);
			checks.forEach((s) => {
				s.pay.forEach((b) => {
					if (b.amount) {
						sum += parseFloat(b.amount);
					}
				});

			});
			return sum;
		},
		deleted: function (id) {
			let tit = 'Eliminar pago';
			let txt = 'Seguro que quiere eliminar el pago';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		approve: function (id) {
			let tit = 'Aprobar Presupuestos';
			let txt = 'Seguro que quiere aprobar el presupuestos';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let _endpoint = '/buys_approve';
					let form = {
						id: id
					};
					try {
						let response = await request.post(_endpoint, form).then(function (response) { return response; });
						this.list();
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/tenant/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list(page = 1) {
			this.load = true;
			let service = request;
			let search = this.search;
			search.search = this.name;
			let _endpoint = '/tenant';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.buys = data;
					this.load = false;
				}
			} catch (error) {
				console.log(error);
				this.load = false;
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	watch: {
		'search': {
			handler: function () {
				this.list();
			},
			deep: true
		}
	},
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
