<script>
import request from '@/services/request'
import { mapActions } from 'vuex';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            errors: {},
            tenant: {},
            pays: []
        }
    },
    methods: {
        closedModal() {
            this.pays = [];
            this.tenant = {};
            Event.$emit('event-load-tenants');
            this.$modal.hide('ListServicesExtras');
        },

        async list() {
            this.pays = [];
            let service = request;
            let _endpoint = this.tenant.id + '/services_extras';
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    console.log(data);
                    this.pays = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async saveAll() {
            let _endpoint = this.tenant.id + '/services_extras';
            let data_form = {
                services: this.pays
            };
            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    Event.$emit('event-load-debts');
                    this.closedModal();
                    this.successNotification(data);
                }
            } catch (error) {
                console.log(error);
            }
        },
        addServices() {
            let service = {
                id: '',
                service_id: null,
                tenant_id: this.tenant.id,
                subtotal: '',
                iva: '',
                ieps: '',
                isr_ret: '',
                iva_ret: '',
                discount: '',
                amount: '',
                name: '',
                extra: '',
                negative: 0,              
            };
            this.pays.push(service);
        },
        deleteServices(index) {
            this.$swal({
                title: 'Eliminar servicio',
                text: 'Se eliminar el servicio',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        this.pays.splice(index, 1);

                        this.successNotification('Se elimino correctamente, asegúrate de guardar');
                    } catch (error) {
                        this.errors = await this.errorNotification(error)
                    }
                }
            });
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-list-extra-services", (tenant) => {
            this.tenant = tenant;
            this.list();
            this.$modal.show('ListServicesExtras');
        });
        Event.$on("event-load-pays", () => {
            this.list();
        });
    },
    computed: {
        totalAmount() {
            return this.pays.reduce((sum, pay) => {
                const amount = parseFloat(pay.amount || 0);
                return pay.negative == 1 ? sum - amount : sum + amount;
            }, 0);
        }
    },
    watch: {
        pays: {
            handler(newPays) {
                newPays.forEach(pay => {
                    pay.amount = parseFloat(pay.subtotal || 0) + parseFloat(pay.iva || 0) + parseFloat(pay.ieps || 0)
                        - parseFloat(pay.isr_ret || 0) - parseFloat(pay.iva_ret || 0) - parseFloat(pay.discount || 0);
                });
            },
            deep: true
        }
    }
}
</script>
<template>
    <modal name="ListServicesExtras" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Lista de pagos</h5>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table table-bordered text-center text-sm">
                            <thead>
                                <tr>
                                    <th>Contra</th>
                                    <th>Nombre</th>
                                    <th>Subtotal</th>
                                    <th>Iva</th>
                                    <th>IEPS</th>
                                    <th>ISR RET</th>
                                    <th>IVA RET</th>
                                    <th>Descuento</th>
                                    <th>Monto</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-if="pays.length > 0">
                                <tr v-for="(pay, index) in pays" :key="index">
                                    <td>
                                        <input type="checkbox" :disabled="!prop_edit"
                                            class="form-control form-control-sm" v-model="pay.negative" :value="1">
                                    </td>
                                    <td>
                                        <input :disabled="!prop_edit" class="form-control form-control-sm"
                                            v-model="pay.name">
                                    </td>
                                    <td>
                                        <money :disabled="!prop_edit" class="form-control form-control-sm"
                                            v-model="pay.subtotal"></money>
                                    </td>
                                    <td>
                                        <money :disabled="!prop_edit" class="form-control form-control-sm"
                                            v-model="pay.iva"></money>
                                    </td>
                                    <td>
                                        <money :disabled="!prop_edit" class="form-control form-control-sm"
                                            v-model="pay.ieps"></money>
                                    </td>
                                    <td>
                                        <money :disabled="!prop_edit" class="form-control form-control-sm"
                                            v-model="pay.isr_ret"></money>
                                    </td>
                                    <td>
                                        <money :disabled="!prop_edit" class="form-control form-control-sm"
                                            v-model="pay.iva_ret"></money>
                                    </td>
                                    <td>
                                        <money :disabled="!prop_edit" class="form-control form-control-sm"
                                            v-model="pay.discount"></money>
                                    </td>
                                    <td>
                                        <money :disabled="!prop_edit" class="form-control form-control-sm"
                                            v-model="pay.amount"></money>
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-danger" v-if="prop_edit"
                                            @click="deleteServices(index)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td align="center" colspan="10">Sin servicios.</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="8">
                                        Total a pagar :
                                    </td>
                                    <td>
                                        {{ totalAmount | toCurrency }}
                                    </td>
                                    <td>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="10" align="center">
                                        <button v-if="prop_edit" @click="addServices()"
                                            class="btn btn-sm btn-outline-info">
                                            <i class="fa fa-plus"></i> Servicio
                                        </button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div class="col-md-12">
                    <hr>
                    <button v-if="prop_edit" type="button" @click="saveAll()"
                        class="btn float-right btn-outline-success">
                        <i class="fas fa-save"></i> Guardar
                    </button>
                    <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                        <i class="fas fa-undo-alt"></i>
                        Regresar
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>