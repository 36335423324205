<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            id_provider: '',
            form: {
                id: '',
                name: '',
                beneficiary: '',
                bank_id: '',
                file: '',
                account_number: '',
                clue_number: '',
                interbank_number: ''
            },
            file: '',
            fileName: ''
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.name = '';
            this.form.beneficiary = '';
            this.form.bank_id = '';
            this.form.file = '';
            this.form.account_number = '';
            this.form.clue_number = '';
            this.form.interbank_number = '';
            this.$modal.hide('addContBanckProvider');
        },
        catch_file(event) {
            const validation = [
                "application/pdf",
                "image/gif",
                "image/jpeg",
                "image/jpg",
                "image/png",
            ];
            if (validation.includes(event.target.files[0].type)) {
                this.form.file = event.target.files[0];
                this.fileName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF e imágenes',
                });
            }
        },
        async saveBuildings() {
            const data_form = new FormData();
            data_form.append('id', this.form.id);
            data_form.append('name', this.form.name);
            data_form.append('beneficiary', this.form.beneficiary);
            data_form.append('bank_id', this.form.bank_id);
            data_form.append('file', this.form.file);
            data_form.append('account_number', this.form.account_number);
            data_form.append('clue_number', this.form.clue_number);
            data_form.append('interbank_number', this.form.interbank_number);
            data_form.append('_method', 'POST');

            let _endpoint = `/${this.id_provider}/bank_account_provider`;
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/${this.id_provider}/bank_account_provider/${this.form.id}`;
            }
            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-cont-banck-provider');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    async mounted() {
        await this.catalogue.get_banks();
        let id = this.$route.params.id;
        if (id) {
            this.id_provider = id;
        }
        if (this.authUser.organization_charts_id == 2) {
            this.id_provider = this.authUser.id;
        }
    },
    created() {
        Event.$on("event-show-addContBanckProvider", (account) => {
            console.log(account);
            if (account.id) {

                this.form.id = account.id;
                this.form.name = account.name;
                this.form.beneficiary = account.beneficiary;
                this.form.bank_id = account.bank_id;
                this.form.account_number = account.account_number;
                this.form.clue_number = account.clue_number;
                this.form.interbank_number = account.interbank_number;
                if( account.file){
                    this.file = account.file;
                }
            }
            this.$modal.show('addContBanckProvider');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }
}
</script>
<template>
    <modal v-bind:click-to-close="false" name="addContBanckProvider" :width="'80%'" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Cuenta de banco</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <label>Nombre</label>
                        <input type="text" :disabled="!prop_edit"  class="form-control" v-model="form.name">
                        <div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Beneficiario</label>
                        <input type="text" :disabled="!prop_edit"  class="form-control" v-model="form.beneficiary">
                        <div v-if="errors && errors.beneficiary" class="text-danger">{{ errors.beneficiary[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Banco</label>
                        <select  :disabled="!prop_edit"  class="form-control" v-model="form.bank_id">
                            <option v-for="(bank, index) in catalogue.banks" :value="bank.id" :key="index">{{ bank.name }}
                            </option>
                        </select>
                        <div v-if="errors && errors.bank_id" class="text-danger">{{ errors.bank_id[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>No. Cuenta</label>
                        <input  :disabled="!prop_edit"  type="number" class="form-control" v-model="form.account_number">
                        <div v-if="errors && errors.account_number" class="text-danger">{{ errors.account_number[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>No. Cta. Clave</label>
                        <input  :disabled="!prop_edit"  type="number" class="form-control" v-model="form.clue_number">
                        <div v-if="errors && errors.clue_number" class="text-danger">{{ errors.clue_number[0] }}</div>
                    </div>                   
                    <div class="col-md-12 mt-3">
                        <label>Caratula del estado de cta</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input  :disabled="!prop_edit"  type="file" class="custom-file-input" :class="file != '' ? 'pr-5' : ''" id="customFile"
                                ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>


                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit"  type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>