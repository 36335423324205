<template>
	<modal name="LoadPay" v-bind:click-to-close="false" :width="400" :height="300" :adaptive="true" style="background-color: #193e6b85;">
		<div class="load_pay text-white" style="background: #17375ff0;" >
			<i class="fa-solid fa-money-bill-transfer fa-beat fa-3x"></i>
			<h4 class="mt-2">Pagando</h4>
		</div>
	</modal>
</template>
<script>

export default {
	name: "import-stores",
	data() {
		return {
		}
	},
	mounted() {
	},
	methods: {
	}
}
</script>
<style>
.load_pay {
	padding: 50px;
    text-align: center;
}
</style>
