<script>
import { mapActions } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                name: '',
                number: '',
                type_account: '',
                bank_id: '',
                number_bis: '',
                file: '',

            },
            file: '',
            fileName: ''
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.name = '';
            this.form.number = '';
            this.form.type_account = '';
            this.form.bank_id = '';
            this.form.number_bis = '';
            this.form.file = '';
            this.$modal.hide('addContBanck');
        },
        catch_file(event) {
            const validation = [
                "application/pdf",
                "image/gif",
                "image/jpeg",
                "image/jpg",
                "image/png",
            ];
            if (validation.includes(event.target.files[0].type)) {
                this.form.file = event.target.files[0];
                this.fileName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF e imágenes',
                });
            }
        },
        async saveBuildings() {
            let form = this.form;
            console.log(form);
            let _endpoint = '/bank_accounts';
            if (form.id != "") {
                form._method = 'PUT';
                _endpoint = `/bank_accounts/${form.id}`;
            }
            try {
                let { data } = await request.postMedia(_endpoint, form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-bank_accounts');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    mounted() {
        this.catalogue.get_banks();
        this.catalogue.get_type_account();
    },
    created() {
        Event.$on("event-show-addContBanck", (account) => {
            console.log(account);
            if (account.id) {
                this.form.id = account.id;
                this.form.name = account.name;
                this.form.number = account.number;
                this.form.type_account = account.type_account;
                this.form.bank_id = account.bank_id;
                this.form.number_bis = account.number_bis;
                this.file = account.file;
            }
            this.$modal.show('addContBanck');
        });
    }
}
</script>

<template>
    <modal v-bind:click-to-close="false" name="addContBanck" :width="'80%'" :height="'auto'">
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Cuenta de banco</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <label>Nombre</label>
                        <input type="text" class="form-control" v-model="form.name">
                        <div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Número de cuenta</label>
                        <input type="number" class="form-control" v-model="form.number">
                        <div v-if="errors && errors.number" class="text-danger">{{ errors.number[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Tipo de cuenta</label>
                        <select class="form-control" v-model="form.type_account">
                            <option v-for="(type_account, index) in catalogue.type_account" :value="type_account.id"
                                :key="index">{{ type_account.name }}</option>
                        </select>
                        <div v-if="errors && errors.type_account" class="text-danger">{{ errors.type_account[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>No. Cta. Clave</label>
                        <input type="number" class="form-control" v-model="form.number_bis">
                        <div v-if="errors && errors.number_bis" class="text-danger">{{ errors.number_bis[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Banco</label>
                        <select class="form-control" v-model="form.bank_id">
                            <option v-for="(bank, index) in catalogue.banks" :value="bank.id" :key="index">{{ bank.name
                                }}</option>
                        </select>
                        <div v-if="errors && errors.bank_id" class="text-danger">{{ errors.bank_id[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Caratula del estado de cta</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" class="custom-file-input" :class="file != '' ? 'pr-5' : ''"
                                id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>

                    <div class="col-md-12">
                        <hr>
                        <button type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>