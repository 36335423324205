<script>
import { mapGetters } from 'vuex';
import request from '@/services/request';
import Catalogue from '@/services/catalogue';
import { mapActions } from 'vuex';

export default {
	name: "debts-table",
	components: {
	},
	data() {
		return {
			load: false,
			catalogue: new Catalogue(),
			tenants: [],
			send: false,
			search: {				
				building_id: '',				
			},
		}
	},
	methods: {	
		downloadExcel() {
			let data = new URLSearchParams(this.search).toString();
			let url = this.globalUrlFiles + '/tenant_report?' + data;
			window.open(url, '_blank');
		},	
		async list() {
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/tenant_all';
			try {
				let { data } = await service.get(_endpoint, search)
				if (data) {
					console.log(data);
					this.tenants = data;
					this.load = false;
				}
			} catch (error) {
				console.log(error);
				this.load = false;
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	mounted() {
		let DateBill = this.moment().format('YYYY-MM');
		this.search.date = DateBill;
		this.list();
		this.catalogue.get_buildings();
		this.catalogue.get_companies_all();
		this.catalogue.get_users_profile(7);
	},
	watch: {
		'search': {
			handler: function () {
				this.list();
			},
			deep: true
		},
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}

</script>
<template>
	<div class="card card-outline card-info">
		<div class="card-header">
			<h3 class="card-title">Lista de adeudos</h3>
			<div class="card-tools">			
				<button @click="downloadExcel()" class="btn btn-sm btn-outline-success mr-1">
					<i class="fa-solid fa-file-excel"></i> Excel
				</button>
				<!-- <button @click="editBuy()" v-can="'add_contracts'" class="btn btn-sm btn-outline-info">
                        <i class="fa fa-plus"></i> Contrato
                    </button> -->
			</div>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-md-5">
					<label>Propiedad :</label>
					<select class="form-control" v-model="search.building_id" @change="list()">
						<option value="">Todas</option>
						<option :value="building.id" v-for="(building, index) in catalogue.buildings" :key="index">
							{{ building.name }}</option>
					</select>
				</div>
			</div>
			<br>
			<div class="table-responsive">
				<div class="col-12 table-responsive">
					<table class="table tbl-pays table-bordered text-center text-sm" border="1">
						<thead>
							<tr>
								<th style="width: 200px;">LOCALES</th>
								<th style="width: 250px;">ARRENDATARIO</th>
								<th style="width: 220px;" colspan="2">VIGENCIA</th>
								<th style="width: 110px;">FACTURADO</th>
								<th style="width: 100px;">DEPOSITO</th>
								<th style="width: 100px;">RENTA</th>
								<th style="width: 100px;">MANTENIMIENTO</th>
								<th style="width: 100px;">EXTRAS</th>
								<th style="width: 100px;">IVA</th>
								<th style="width: 100px;">IEPS</th>
								<th style="width: 100px;">ISR RET</th>
								<th style="width: 100px;">IVA RET</th>
								<th style="width: 100px;">DESCUENTO</th>
								<th style="width: 100px;">TOTAL</th>
								<th style="width: 100px;">OBSERVACIONES</th>
								<th style="width: 200px;">CONTACTO</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="16">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="tenants.length > 0">
							<tr v-for="(tenant, index) in tenants" :key="index">
								<td colspan="11" class="p-0">
									<table class="table  tbl-pays table-bordered text-center text-sm m-0">
										<tr>
											<td style="width: 200px;" :rowspan="tenant.tenants.length + 1">
												{{ tenant.department ? tenant.department.number + '-' +
					tenant.department.level : 'S/N' }}
												<br>
												<h5 v-if="tenant.signed">
													<span class="badge badge-success"> Firmado </span>
												</h5>
											</td>
											<td style="width: 250px;" :rowspan="tenant.tenants.length + 1">
												{{ tenant.user ? tenant.user.name + ' ' + tenant.user.last_name : 'S/N'
												}}
											</td>
											<td style="width: 110px;">
												{{ tenant.star_date | toDateTime }}
											</td>
											<td style="width: 110px;">
												{{ tenant.dateEnd | toDateTime }}
											</td>
											<td style="width: 110px;">
												{{ tenant.invoiced  }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ tenant.paysSum | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ tenant.amount | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ tenant.mantenimiento | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ tenant.extras | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ tenant.sum_iva | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ tenant.sum_ieps | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ tenant.sum_isr_rent | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ tenant.sum_iva_ret | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ tenant.sum_discount | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ tenant.sum | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;"></td>
											<td :rowspan="tenant.tenants.length + 1" style="text-align: center; width: 200px;">
												{{ tenant.user ? tenant.user.phone : 'S/N' }} <br>
												{{ tenant.user ? tenant.user.email : 'S/N' }}
											</td>
										</tr>
										<tr v-for="(subTenant, subIndex) in tenant.tenants" :key="subIndex">
											<td style="width: 110px;">
												{{subTenant.star_date | toDateTime }}
											</td>
											<td style="width: 110px;">
												{{subTenant.dateEnd | toDateTime }}
											</td>
											<td style="width: 110px;">
												{{ subTenant.invoiced  }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ subTenant.paysSum | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ subTenant.amount | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ subTenant.mantenimiento | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ subTenant.extras | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ subTenant.sum_iva | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ subTenant.sum_ieps | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ subTenant.sum_isr_rent | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ subTenant.sum_iva_ret | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ subTenant.sum_discount | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;">
												{{ subTenant.sum | toCurrency }}
											</td>
											<td style="text-align: right; width: 100px;"></td>
										</tr>

									</table>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="16">
									<h5>Sin resultados</h5>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<style type="text/css">
.tbl-pays {
	table-layout: fixed;
	min-width: 1000px;
	width: 100%;
}
</style>