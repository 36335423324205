<script>

export default {
    data() {
        return {
            bill: {},
            checks: {},
        }
    },
    methods: {
        closedModal() {
            this.checks = {};
            this.$modal.hide('listChecksBills');
        },
        sumPays(pays) {
            let sum = 0;
            pays.forEach(function (valor) {
                sum += valor.amount;
            });
            return sum;
        },
        showCheck(edit = {}) {
            Event.$emit('event-show-addChecks', edit);
        },
        downloadRecibe(id) {
            window.open(this.globalUrlFiles + '/bills/pdf/' + id, '_blank');
        },
    },
    created() {
        Event.$on("event-show-listChecksBills", (bill) => {
            this.bill = bill;
            if (bill.checks) {
                this.checks = bill.checks;
            }
            this.$modal.show('listChecksBills');
        });
    },
}
</script>
<template>
    <modal name="listChecksBills" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Cheques relacionados</h5>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table table-bordered text-center text-sm">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Tipo</th>
                                    <th>Concepto</th>
                                    <th>Monto</th>
                                    <th>Pagado</th>
                                    <th>Por Pagar</th>
                                    <th>Fecha</th>
                                    <th>Tipo de saldo</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-if="checks">
                                <tr v-for="(buy, index) in checks" :key="index">
                                    <td>{{ buy.id }}</td>
                                    <td>
                                        <span v-if="buy.type_id == 1"> Presupuesto </span>
                                        <span v-else> Gasto recurrente </span>
                                    </td>
                                    <td>
                                        <span v-if="buy.concept"> {{ buy.concept.name }}</span>
                                        <span v-else>Sin Concepto</span>
                                    </td>
                                    <td>
                                        {{ buy.amount_payable | toCurrency }}
                                    </td>
                                    <td>
                                        {{ sumPays(buy.pay) | toCurrency }}
                                    </td>
                                    <td>
                                        {{ buy.amount_payable - sumPays(buy.pay) | toCurrency }}
                                    </td>
                                    <td>
                                        {{ buy.date }}
                                    </td>

                                    <td>
                                        <span v-if="buy.type_payment"> {{ buy.type_payment.name }}</span>
                                        <span v-else>Sin Tipo de Saldo</span>
                                    </td>
                                    <td>
                                        <button type="button" v-if="buy" @click="showCheck(buy)"
                                            class="btn m-1 btn-outline-success btn btn-sm ">
                                            <i class="fa-solid fa-hand-holding-dollar"></i> Información del cheque
                                        </button>
                                        <button type="button" v-if="buy.signature && bill.biill_type_id == 3"
                                            @click="downloadRecibe(buy.id)"
                                            class="btn m-1 btn-outline-info btn btn-sm ">
                                            <i class="fa-solid fa-file-pdf"></i> Recibo de pago
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td align="center" colspan="5">Sin resultados.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-md-12">
                    <hr>
                    <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                        <i class="fas fa-undo-alt"></i>
                        Regresar
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>