<script>
import request from '@/services/request'
import { mapActions, mapGetters } from 'vuex';

import ModalFormComponent from '@/components/contanct_provider/ModalContanctProviderComponent.vue';
export default {
	components: {
		ModalFormComponent,
	},
    data() {
        return {
            id_provider: '',
            listAr: []
        }
    },
    methods: {
        newDepartment(account = {}) {
			Event.$emit('event-show-addContProvider', account);
		},
        async list(page = 1) {
            let search = {};
            let _endpoint = `/${this.id_provider}/contanct_provider`;
            try {
                let { data } = await request.list(_endpoint, page, search)
                if (data) {
                    console.log(data);
                    this.listAr = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        deleted: function (id) {
            let tit = 'Eliminar cuenta';
            let txt = 'Seguro que quiere eliminar al cuenta';

            this.$swal({
                title: tit,
                text: txt,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then(async(result) => {
                if (result.isConfirmed) {
                    let _endpoint = `/${this.id_provider}/contanct_provider/${id}`;
                    try {
                        let response = await request.destroy(_endpoint).then(function (response) { return response; });
                        this.list();
                        this.successNotification(response.data.message);
                    } catch (error) {
                        this.errors = await this.errorNotification(error)
                    }
                }
            });
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    async mounted() {
        let id = this.$route.params.id;
        if (id) {
            this.id_provider = id;
        }
        if (this.authUser.organization_charts_id == 2) {
            this.id_provider = this.authUser.id;
        }
        await this.list();
    },
    created() {
        Event.$on("event-load-contanct-provider", () => {
            this.list();
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <h5 v-if="this.authUser.organization_charts_id == 2">Mis contactos</h5>
            <h5 v-else>Contactos</h5>           
        </div>
        <div class="col-12 mt-3">
            <div class="table-responsive">
                <table class="table table-bordered text-center text-sm">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Teléfono</th>
                            <th>Celular</th>
                            <th>Correo</th>
                            <th>Opciones</th>
                        </tr>
                    </thead>
                    <tbody v-if="listAr.length > 0">
                        <tr v-for="(account, index) in listAr" :key="index">
                            <td>{{ account.id }}</td>
                            <td>{{ account.name }}</td>
                            <td>{{ account.phone }}</td>
                            <td>{{ account.cell_phone }}</td>
                            <td>{{ account.email }}</td>
                            <td>                               
                                <button @click="newDepartment(account)"
                                    class="m-1 btn btn-outline-primary btn-sm">
                                    <i class="fas fa-eye "> </i> Ver más
                                </button>                              
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td align="center" colspan="6">Sin resultados.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ModalFormComponent :prop_edit="false"/>
    </div>
</template>