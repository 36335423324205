import axios from "axios"


let ENDPOINT = '';
let urlBase = window.location.hostname;
if (urlBase == 'localhost') {
    ENDPOINT = 'http://127.0.0.1:8000/api/auth';
    // ENDPOINT = 'https://api.grupomcloud.com/api/auth';
}

if (urlBase == 'gms.sistemasdm.com') {
     ENDPOINT = 'https://api.sistemasdm.com/api/auth';
}

if (urlBase == 'grupomcloud.com') {
    ENDPOINT = 'https://api.grupomcloud.com/api/auth';
}

console.log(ENDPOINT);
console.log(urlBase);

export const login = (payload) => {
    let endpoint = `${ENDPOINT}/login`
    return axios.post(
        endpoint,
        payload
    )
}

export const register = (payload) => {
    let endpoint = `${ENDPOINT}/register`
    return axios.post(
        endpoint,
        payload
    )
}

export const logout = () => {
    let endpoint = `${ENDPOINT}/logout`
    return axios.post(endpoint)
}

export const getUser = () => {
    let endpoint = `${ENDPOINT}/me`
    return axios.get(endpoint);
}

export const updateUser = () => {
    let endpoint = `${ENDPOINT}/user`
    return axios.post(endpoint,)
}


export default {
    login,
}
