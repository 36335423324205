<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    props: {
        organization_charts_id: {
            default: 1,
        },
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            id_provider: '',
            form: {
                id: '',
                business_name: '',
                rfc: '',
                cedula: '',
                compliance_opinion: '',
                fiscal_address: '',
                bill_data_type_id: '',
                repse_required: '',
                repse: '',
                banks_ids: [],
                organization_charts_id: this.organization_charts_id,
                tax_regime: '',
                street: '',
                outside_number: '',
                inside_number: '',
                cologne: '',
                state: '',
                city: '',
                cp: '',
                country: '',
                cfdi: '',
                concept: ''
            },
            repseName: null,
            file_repse: '',
            cedularName: null,
            file_cedula: '',
            complianceName: null,
            file_compliance: '',
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.business_name = '';
            this.form.rfc = '';
            this.form.cedula = '';
            this.form.bill_data_type_id = '';
            this.form.compliance_opinion = '';
            this.form.fiscal_address = '';
            this.cedularName = '';
            this.file_cedula = '';
            this.complianceName = '';
            this.file_compliance = '';
            this.repseName = '';
            this.file_repse = '';

            this.form.tax_regime = '';
            this.form.street = '';
            this.form.outside_number = '';
            this.form.inside_number = '';
            this.form.cologne = '';
            this.form.state = '';
            this.form.city = '';
            this.form.cp = '';
            this.form.country = '';
            this.form.cfdi = '';

            this.form.concept = '';
            this.form.banks_ids = [];
            this.$modal.hide('addBillingProvider');
        },
        compliance_file(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.compliance_opinion = event.target.files[0];
                this.complianceName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        cedula_file(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.cedula = event.target.files[0];
                this.cedularName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        repse_file(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.repse = event.target.files[0];
                this.repseName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        async saveBuildings() {
            const data_form = new FormData();
            data_form.append('id', this.form.id);
            data_form.append('business_name', this.form.business_name);
            data_form.append('rfc', this.form.rfc);
            data_form.append('fiscal_address', this.form.fiscal_address);
            data_form.append('cedula', this.form.cedula);
            data_form.append('compliance_opinion', this.form.compliance_opinion);
            data_form.append('bill_data_type_id', this.form.bill_data_type_id);
            data_form.append('banks_ids', this.form.banks_ids);
            data_form.append('repse_required', this.form.repse_required);
            data_form.append('repse', this.form.repse);
            data_form.append('repse', this.form.repse);

            data_form.append('organization_charts_id', this.organization_charts_id);
            data_form.append('tax_regime', this.form.tax_regime);
            data_form.append('street', this.form.street);
            data_form.append('outside_number', this.form.outside_number);
            data_form.append('inside_number', this.form.inside_number);
            data_form.append('cologne', this.form.cologne);
            data_form.append('state', this.form.state);
            data_form.append('city', this.form.city);
            data_form.append('cp', this.form.cp);
            data_form.append('country', this.form.country);
            data_form.append('cfdi', this.form.cfdi);
            data_form.append('concept', this.form.concept);

            data_form.append('_method', 'POST');

            let _endpoint = `/${this.id_provider}/billing_data`;
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/${this.id_provider}/billing_data/${this.form.id}`;
            }
            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-bills-provider');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    async mounted() {
        await this.catalogue.getBillingDataType();
        let id = this.$route.params.id;
        if (id) {
            this.id_provider = id;
        }
        if (this.authUser.organization_charts_id == 2) {
            this.id_provider = this.authUser.id;
        }
        await this.catalogue.getBankAccountProvider(this.id_provider);

    },
    created() {
        Event.$on("event-show-addBillingProvider", (account) => {
            console.log(account);
            if (account.id) {
                this.form.id = account.id;
                this.form.business_name = account.business_name;
                this.form.rfc = account.rfc;
                this.form.fiscal_address = account.fiscal_address;
                this.form.bill_data_type_id = account.bill_data_type_id;

 this.form.tax_regime = account.tax_regime;
 this.form.street = account.street;
 this.form.outside_number = account.outside_number;
 this.form.inside_number = account.inside_number;
 this.form.cologne = account.cologne;
 this.form.state = account.state;
 this.form.city = account.city;
 this.form.cp = account.cp;
 this.form.country = account.country;
 this.form.cfdi = account.cfdi;
 this.form.concept = account.concept;


                if (account.banks) {
                    let ids = account.banks.map(s => s.id);
                    this.form.banks_ids = ids;
                }

                if (account.cedula) {
                    this.file_cedula = account.cedula;
                }
                if (account.compliance_opinion) {
                    this.file_compliance = account.compliance_opinion;
                }

                if (account.repse_required) {
                    this.form.repse_required = account.repse_required;
                }

                if (account.repse) {
                    this.file_repse = this.globalUrlFiles + account.repse;
                }

            }
            this.$modal.show('addBillingProvider');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }
}
</script>
<template>
    <modal v-bind:click-to-close="false" name="addBillingProvider" :width="'80%'" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Datos de facturación</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-4">
                        <label>Razón social</label>
                        <input type="text" :disabled="!prop_edit" class="form-control" v-model="form.business_name">
                        <div v-if="errors && errors.business_name" class="text-danger">{{ errors.business_name[0] }}
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label>R.F.C</label>
                        <input type="text" :disabled="!prop_edit" class="form-control" v-model="form.rfc">
                        <div v-if="errors && errors.rfc" class="text-danger">{{ errors.rfc[0] }}</div>
                    </div>
                    <div class="col-md-4">
                        <label>Tipo</label>
                        <select :disabled="!prop_edit" class="form-control" v-model="form.bill_data_type_id">
                            <option v-for="(type, index) in catalogue.bill_data_types" :value="type.id" :key="index">{{
        type.name }}</option>
                        </select>
                        <div v-if="errors && errors.bill_data_type_idº" class="text-danger">{{
        errors.bill_data_type_id[0]
    }}</div>
                    </div>
                    <div class="col-md-12 mt-3" v-if="organization_charts_id != 7">
                        <label>Dirección Fiscal</label>
                        <textarea :disabled="!prop_edit" class="form-control" v-model="form.fiscal_address">
                        </textarea>
                        <div v-if="errors && errors.fiscal_address" class="text-danger">{{ errors.fiscal_address[0] }}
                        </div>
                    </div>

                    <div class="col-md-12" v-if="organization_charts_id == 7">
                        <div class="row">

                            <div class="col-md-4">
                                <label> Regimen Fiscal </label>
                                <input type="text" v-model="form.tax_regime" class="form-control">
                                <div v-if="errors && errors.tax_regime" class="text-danger">{{
        errors.tax_regime[0] }}</div>
                            </div>

                            <div class="col-md-4">
                                <label> Calle </label>
                                <input type="text" v-model="form.street" class="form-control">
                                <div v-if="errors && errors.street" class="text-danger">{{ errors.street[0]
                                    }}</div>
                            </div>

                            <div class="col-md-4">
                                <label> Número exterior </label>
                                <input type="text" v-model="form.outside_number" class="form-control">
                                <div v-if="errors && errors.outside_number" class="text-danger">{{
        errors.outside_number[0] }}</div>
                            </div>

                            <div class="col-md-4">
                                <label> Número interior </label>
                                <input type="text" v-model="form.inside_number" class="form-control">
                                <div v-if="errors && errors.inside_number" class="text-danger">{{
        errors.inside_number[0] }}</div>
                            </div>

                            <div class="col-md-4">
                                <label> Colonia </label>
                                <input type="text" v-model="form.cologne" class="form-control">
                                <div v-if="errors && errors.cologne" class="text-danger">{{
        errors.cologne[0] }}</div>
                            </div>

                            <div class="col-md-4">
                                <label> Estado </label>
                                <input type="text" v-model="form.state" class="form-control">
                                <div v-if="errors && errors.state" class="text-danger">{{ errors.state[0] }}
                                </div>
                            </div>

                            <div class="col-md-4">
                                <label> Ciudad </label>
                                <input type="text" v-model="form.city" class="form-control">
                                <div v-if="errors && errors.city" class="text-danger">{{ errors.city[0] }}
                                </div>
                            </div>

                            <div class="col-md-4">
                                <label> C.P. </label>
                                <input type="text" v-model="form.cp" class="form-control">
                                <div v-if="errors && errors.cp" class="text-danger">{{ errors.cp[0] }}</div>
                            </div>

                            <div class="col-md-4">
                                <label> País </label>
                                <input type="text" v-model="form.country" class="form-control">
                                <div v-if="errors && errors.country" class="text-danger">{{
        errors.country[0] }}</div>
                            </div>

                            <div class="col-md-4">
                                <label> Uso de CFDI </label>
                                <input type="text" v-model="form.cfdi" class="form-control">
                                <div v-if="errors && errors.cfdi" class="text-danger">{{ errors.cfdi[0] }}
                                </div>
                            </div>                           

                        </div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Constancia de situación fiscal</label><br>
                        <div class="custom-file" :style="file_cedula != '' ? 'width: 87%' : 'width: 100%'">
                            <input :disabled="!prop_edit" type="file" class="custom-file-input"
                                :class="file_cedula != '' ? 'pr-5' : ''" id="customFile" ref="file"
                                @change="cedula_file">
                            <label class="custom-file-label text-left" for="customFile">{{ cedularName
                                }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file_cedula}`" v-if="file_cedula != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info">
                            <i class="fa-regular fa-file-pdf"></i></a>
                        <div v-if="errors && errors.cedula" class="text-danger">{{ errors.cedula[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Opinion de cumplimiento</label><br>
                        <div class="custom-file" :style="file_compliance != '' ? 'width: 87%' : 'width: 100%'">
                            <input :disabled="!prop_edit" type="file" class="custom-file-input"
                                :class="file_compliance != '' ? 'pr-5' : ''" id="customFile" ref="file"
                                @change="compliance_file">
                            <label class="custom-file-label text-left" for="customFile">{{ complianceName
                                }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file_compliance}`" v-if="file_compliance != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info">
                            <i class="fa-regular fa-file-pdf"></i></a>
                        <div v-if="errors && errors.compliance_opinion" class="text-danger">{{
        errors.compliance_opinion[0]
    }}</div>
                    </div>
                    <div class="col-md-4 text-center mt-3" v-if="organization_charts_id != 7">
                        <div class="form-group">
                            <label> REPSE requerido </label>
                            <input :disabled="!prop_edit" type="checkbox" :value="1" v-model="form.repse_required"
                                class="form-control">
                            <div v-if="errors && errors.repse_required" class="text-danger">{{ errors.repse_required[0]
                                }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8 mt-3" v-if="organization_charts_id != 7">

                        <div class="form-group">
                            <label> Archivo REPSE </label> <br>
                            <div class="custom-file" :style="file_repse != '' ? 'width: 87%' : 'width: 100%'">
                                <input type="file" :disabled="form.repse_required != 1 || !prop_edit"
                                    class="custom-file-input" :class="file_repse != '' ? 'pr-5' : ''" id="customFile"
                                    ref="file" @change="repse_file">
                                <label class="custom-file-label text-left" for="customFile">{{ repseName
                                    }}</label>
                            </div>
                            <a :href="file_repse" v-if="file_repse != ''" target="_blank"
                                style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info">
                                <i class="fa-regular fa-file-pdf"></i></a>

                            <div v-if="errors && errors.repse" class="text-danger">{{ errors.repse[0] }}
                            </div>
                        </div>
                        <div v-if="errors && errors.web" class="text-danger">{{ errors.web[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3" v-if="catalogue.bank_account_provider.length > 0">
                        <hr>
                    </div>
                    <div class="col-md-12 mt-3">
                        <div class="row">
                            <div class="col-md-6" v-for="(bank, index) in catalogue.bank_account_provider" :key="index">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <input type="checkbox" style="float: right; width: 30px;"
                                                    :value="bank.id" :disabled="!prop_edit" v-model="form.banks_ids"
                                                    class="form-control form-contro-sm">
                                                <p class="mb-0"><strong>Nombre:</strong> {{ bank.name }}</p>
                                                <p class="mb-0"><strong>Beneficiario:</strong> {{ bank.beneficiary }}
                                                </p>
                                                <p class="mb-0"><strong>Banco:</strong> {{ bank.bank.name }}</p>
                                                <p class="mb-0"><strong>No. Cuenta:</strong> {{ bank.account_number }}
                                                </p>
                                                <p class="mb-0"><strong>No. Cta. Clave:</strong> {{ bank.clue_number }}
                                                </p>
                                                <p class="mb-0"><strong>Caratula del estado de cta:</strong>
                                                    <a class="btn btn-sm btn-block btn-info m-1"
                                                        :href="`${globalUrlFiles}${bank.file}`" v-if="bank.file"
                                                        target="_blank">
                                                        <i class="fa-regular fa-file-pdf"></i> Caratula
                                                    </a>
                                                    <span v-else>
                                                        S/C
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>