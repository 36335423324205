<template>
	<div class="col-12 ">
		<div class="card card-outline card-navy">
			<div class="card-body">
				<form @submit.prevent="triggerSave">
					<div class="row">
						<div class="col-md-4">
							<div class="form-group">
								<label> Propiedad </label>
								<select type="text" class="form-control" v-model="form.building_id">
									<option v-for="(building, index) in catalogue.buildings" :value="building.id"
										:key="index">
										{{ building.name }}</option>
								</select>
								<div v-if="errors && errors.building_id" class="text-danger">{{ errors.building_id[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Departamento </label>
								<select v-model="form.indiviso_id" class="form-control">
									<option value="">Sin departamento</option>
									<option v-for="(departament, index) in catalogue.departamens_buildings" :key="index"
										:value="departament.id"> Número {{ departament.number }} / Piso {{
					departament.level
				}}</option>
								</select>
								<div v-if="errors && errors.indiviso_id" class="text-danger">{{ errors.indiviso_id[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Empresa </label>
								<select v-model="form.company_id" class="form-control">
									<option value="">Sin empresa</option>
									<option v-for="(company, index) in catalogue.companies_buildings" :key="index"
										:value="company.id"> {{ company.name }}
									</option>
								</select>
								<div v-if="errors && errors.company_id" class="text-danger">{{ errors.company_id[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Usuario </label>
								<select type="text" class="form-control" v-model="form.user_id">
									<option v-for="(user, index) in catalogue.users_profile" :value="user.id"
										:key="index">
										{{ user.name }} {{ user.last_name }}</option>
								</select>
								<div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
							</div>
						</div>
						<div class="col-md-4">
							<label>Datos de Facturación</label>
							<select type="text" class="form-control" v-model="form.billing_data_id">
								<option value="">Sin datos de facturación</option>
								<option v-for="(billD, index) in catalogue.billing_data_user" :value="billD.id"
									:key="index">{{
					billD.business_name }} - {{ billD.rfc }}</option>
							</select>
							<div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Categoría </label>
								<select type="text" class="form-control" v-model="form.concept_id">
									<option v-for="(concept, index) in catalogue.concepts" :value="concept.id"
										:key="index">
										{{ concept.name }}</option>
								</select>
								<div v-if="errors && errors.concept_id" class="text-danger">{{ errors.concept_id[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-2">
							<label>Subtotal</label>
							<money class="form-control" v-model="form.subtotal"></money>
							<div v-if="errors && errors.subtotal" class="text-danger">{{ errors.subtotal[0] }}</div>
						</div>
						<div class="col-md-2">
							<label>IVA</label>
							<money class="form-control" v-model="form.iva"></money>
							<div v-if="errors && errors.iva" class="text-danger">{{ errors.iva[0] }}</div>
						</div>
						<div class="col-md-2">
							<label>ISR RET</label>
							<money class="form-control" v-model="form.isr_ret"></money>
							<div v-if="errors && errors.isr_ret" class="text-danger">{{ errors.isr_ret[0] }}</div>
						</div>
						<div class="col-md-2">
							<label>IVA RET</label>
							<money class="form-control" v-model="form.iva_ret"></money>
							<div v-if="errors && errors.iva_ret" class="text-danger">{{ errors.iva_ret[0] }}</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Monto </label>
								<money readonly class="form-control" v-model="form.amount"></money>
								<div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}
								</div>
							</div>
						</div>

						<div class="col-md-3">
							<div class="form-group">
								<label> Recurencia </label>
								<select type="text" class="form-control" v-model="form.payment_period_id">
									<option v-show="payment_period.id != 5"
										v-for="(payment_period, index) in catalogue.payment_periods"
										:value="payment_period.id" :key="index">
										{{ payment_period.name }}</option>
								</select>
								<div v-if="errors && errors.payment_period_id" class="text-danger">{{
					errors.payment_period_id[0] }}</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label> Tipo </label>
								<select type="text" class="form-control" v-model="form.payments_recurring_id">
									<option v-for="(payments_recurring, index) in catalogue.payments_recurrings"
										:value="payments_recurring.id" :key="index">
										{{ payments_recurring.name }}</option>
								</select>
								<div v-if="errors && errors.payments_recurring_id" class="text-danger">{{
					errors.payments_recurring_id[0] }}</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label> Fecha Inicio </label>
								<date-picker v-model="form.star_date" valueType="format"></date-picker>
								<div v-if="errors && errors.star_date" class="text-danger">{{ errors.star_date[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label> Fecha fin </label>
								<date-picker v-model="form.end_date" valueType="format"></date-picker>
								<div v-if="errors && errors.end_date" class="text-danger">{{ errors.end_date[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group">
								<label> Descripción </label>
								<textarea type="text" v-model="form.description" class="form-control"></textarea>
								<div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-12 ">
							<label>Contrato</label><br>
							<div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
								<input type="file" class="custom-file-input" :class="file != '' ? 'pr-5' : ''"
									id="customFile" ref="file" @change="catch_file">
								<label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
							</div>
							<a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
								style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
									class="fa-regular fa-file-pdf"></i>
							</a>
							<br>
							<div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

						</div>


						<div class="col-12">
							<hr>
							<div class="row">
								<div class="col-12 text-center">

									<h3>Archivo adjuntos.</h3>
									<p>Maximo 5 archivos.</p>
									<div class="row mt-5 mb-5">
										<div class="col-12" v-if="(form.files.length + files.length) == 0">
											<i class="fa-solid fa-file fa-4x"></i>
											<p>Sin archivos adjuntos</p>
										</div>
										<div class="col-md-4 col-12 p-2" v-for="(fAdd, index_fAdd) in files"
											:key="index_fAdd" v-show="files.length > 0">
											<div class="card shadow">
												<button class="btn btn-sm btn-danger del" type="button"
													@click="delImgUpload(index_fAdd)">
													<i class="fa fa-trash"></i></button>
												<div class="card-body text-center">
													<a :href="`${globalUrlFiles}${fAdd.file}`" target="_blank">
														<i class="fa fa-file fa-4x"></i>
														<p>{{ fAdd.name }}</p>
													</a>
												</div>
											</div>
										</div>
										<div class="col-md-4 col-12 p-2" v-for="(img, index_img) in form.files"
											:key="index_img" v-show="form.files.length > 0">
											<div class="card shadow">
												<button class="btn btn-sm btn-danger del" type="button"
													@click="delImg(index_img)"><i class="fa fa-trash"></i></button>
												<div class="card-body text-center">
													<a :href="createURL(img)" target="_blank">
														<i class="fa fa-file fa-4x"></i>
														<p>{{ img.name }}</p>
													</a>
												</div>
											</div>
										</div>
									</div>									
									<div class="image-upload btn btn-outline-secondary btn-lg"
										v-if="(form.files.length + files.length) <= 4">
										<label for="file-input" class="m-0">
											<h4 class="m-0"> <i class="fa-solid fa-file fa-1x mr-2"></i>
												Subir
												Archivo</h4>
										</label>
										<input id="file-input" type="file" @change="uploadFiles" />
									</div>
								</div>

							</div>
						</div>

						<div class="col-12">
							<hr>
						</div>
						<div class="col-md-12">
							<button type="submit" class="btn float-right btn-outline-success">
								<i class="fas fa-save"></i> Guardar
							</button>
							<router-link to="/buy" class="btn float-left btn-outline-warning">
								<i class="fas fa-undo-alt"></i>
								Regresar
							</router-link>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import request from '@/services/request'
import { mapActions } from 'vuex';
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
	components: {
		DatePicker
	},
	name: "buy-form",
	data() {
		return {
			form: {
				id: '',
				building_id: '',
				indiviso_id: '',
				user_id: '',
				amount: '',
				concept_id: '',
				payment_period_id: '',
				payments_recurring_id: '',
				star_date: '',
				end_date: '',
				description: '',
				file: '',
				bill: '',
				company_id: '',
				billing_data_id: '',
				subtotal: '',
				iva: '',
				isr_ret: '',
				iva_ret: '',
				folio: '',
                files: [],
			},
			files:[],
			bank_accounts: [],
			errors: {},
			// 
			file_cedula: '',
			cedularName: '',
			catalogue: new Catalogue(),
			file: '',
			fileName: '',
			load: false,
		}
	},
	mounted() {
		this.catalogue.get_buildings();
		this.catalogue.get_concepts();
		this.catalogue.get_payment_periods();
		this.catalogue.get_payments_recurrings();
		this.catalogue.get_users_profile(2);
		this.catchParameters();
		let id = this.$route.params.id;
		if (id) {
			this.get_id(id);
		}
	},
	methods: {
		createURL(file) {
            let link = URL.createObjectURL(file);
            return link;
        },
		uploadFiles(event) {
			console.log(event.target.files[0]);
			this.form.files.push(event.target.files[0]);
		},
		delImg(pos) {
			this.form.files.splice(pos, 1);
			this.successNotification('El archivo se elimino correctamente.');
		},
		async delImgUpload(pos) {
			let img = this.files[pos];
			let id = img.id;
			let _endpoint = '/buys/deleted-documents';
			let form = {
				id: id
			};
			let { data } = await request.postMedia(_endpoint, form)
			if (data) {
				this.successNotification(data.message);
				this.files.splice(pos, 1);
			}

		},
		async catchParameters() {
			let params = this.$route.query;
			if (params.bill) {
				this.form.bill = params.bill;
			}
			if (params.supplier) {
				this.form.user_id = params.supplier;
			}
		},
		calcTotal() {
			this.form.amount = this.form.subtotal + this.form.iva - this.form.isr_ret - this.form.iva_ret;
		},
		catch_file(event) {
			if (event.target.files[0].type == "application/pdf") {
				this.form.file = event.target.files[0];
				this.fileName = event.target.files[0].name;
			} else {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos PDF',
				});
			}
		},
		async triggerSave() {
			const data_form = new FormData();
			data_form.append('id', this.form.id);
			data_form.append('building_id', this.form.building_id);
			data_form.append('indiviso_id', this.form.indiviso_id);
			data_form.append('user_id', this.form.user_id);
			data_form.append('amount', this.form.amount);
			data_form.append('concept_id', this.form.concept_id);
			data_form.append('payment_period_id', this.form.payment_period_id);
			data_form.append('payments_recurring_id', this.form.payments_recurring_id);
			data_form.append('star_date', this.form.star_date);
			data_form.append('end_date', this.form.end_date);
			data_form.append('description', this.form.description);
			data_form.append('file', this.form.file);
			data_form.append('bill', this.form.bill);
			data_form.append('company_id', this.form.company_id);
			data_form.append('billing_data_id', this.form.billing_data_id);
			data_form.append('subtotal', this.form.subtotal);
			data_form.append('iva', this.form.iva);
			data_form.append('isr_ret', this.form.isr_ret);
			data_form.append('iva_ret', this.form.iva_ret);

			this.form.files.forEach(function (valor, index) {
				data_form.append('files[' + index + ']', valor);
			});

			let _endpoint = '/buys';
			if (this.form.id != "") {
				data_form.append('_method', 'PUT');
				_endpoint = `/buys/${this.form.id}`;
			}

			try {
				let { data } = await request.postMedia(_endpoint, data_form)
				if (data) {
					console.log(data);
					this.successNotification(data.message);
					this.$router.push('/buy');
				}
			} catch (error) {
				this.errors = await this.errorNotification(error);
			}
		},
		async get_id(id) {
			let _endpoint = `/buys/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.load = true;
					this.form.id = id;
					this.form.building_id = data.building_id
					this.form.indiviso_id = data.indiviso_id
					this.form.user_id = data.user_id
					this.form.amount = data.amount
					this.form.concept_id = data.concept_id
					this.form.payment_period_id = data.payment_period_id
					this.form.payments_recurring_id = data.payments_recurring_id
					this.form.star_date = data.star_date
					this.form.end_date = data.end_date
					this.form.description = data.description
					this.form.company_id = data.company_id
					this.form.billing_data_id = data.billing_data_id
					this.form.subtotal = data.subtotal;
					this.form.iva = data.iva;
					this.form.iva_ret = data.iva_ret;
					this.form.isr_ret = data.isr_ret;

					if (data.file) {
						this.file = data.file;
					}

					if (data.files) {
						this.files = data.files;
					}

					this.catalogue.get_departamens_buildings(data.building_id);
					this.catalogue.get_companies_buildings(data.building_id);

				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	created() {
		Event.$on("event-load-bank_accounts", () => {
			this.accountBanks();
		});
	},
	watch: {
		'form.building_id'(value) {
			if (this.form.id == '') {
				this.catalogue.get_departamens_buildings(value);
				this.catalogue.get_companies_buildings(value);
			}
		},
		'form.user_id'(value) {
			this.catalogue.getBillingDataUser(value);
		},
		'form.billing_data_id'() {
			if (!this.load) {
				this.form.bank_account_providers_id = '';
			}
			if (this.load) {
				this.load = false;
			}
		},
		'form.subtotal'() {
			this.calcTotal();
		},
		'form.iva'() {
			this.calcTotal();
		},
		'form.isr_ret'() {
			this.calcTotal();
		},
		'form.iva_ret'() {
			this.calcTotal();
		}
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.mx-datepicker {
	width: 100%;
}

.fs-6 {
	font-size: 12px;
}

.custom-file-label::after {
	content: "Archivo";
}

.image-upload>input {
    display: none;
}

.del {
    position: absolute;
    right: 0px;
    margin: 10px;
}
</style>
