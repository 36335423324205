<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Ingresos</h3>
				<div class="card-tools">
					<button @click="showCheck()" v-can="'add_incomes'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Ingreso
					</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Propiedad</th>
								<th>Departamento</th>
								<th>Monto</th>								
								<th>Concepto</th>
								<th>Periodo</th>								
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="incomes">
							<tr :class="incomes.authorization_user_id ? 'bg-info' : ''" v-for="(incomes, index) in incomes.data"
								:key="index">
								<td>{{ incomes.id }}</td>
								<td>
									<span v-if="incomes.building"> {{ incomes.building.name }}</span>
									<span v-else>Sin propiedad</span>
								</td>
								<td>
									<span v-if="incomes.departament"> Número {{ incomes.departament.number }} / Piso {{
										incomes.departament.level }}</span>
									<span v-else>Sin propiedad</span>
								</td>
								<td>
									{{ incomes.amount | toCurrency }}
								</td>							
							
								<td>
									<span v-if="incomes.payment_period"> {{ incomes.payment_period.name }}</span>
									<span v-else>Sin concepto</span>
								</td>
								<td>
									<span v-if="incomes.payments_recurring"> {{ incomes.payments_recurring.name }}</span>
									<span v-else>Sin Periodo</span>
								</td>
																								

								<td>
									<button @click="showCheck(incomes)" v-can="'edit_incomes'"
										class="btn btn-outline-primary m-1 btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</button>
									<button type="button" @click="deleted(incomes.id)" v-can="'deleted_incomes'"
										class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>									
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
					<pagination v-model="page" :records="incomes.total" :per-page="incomes.per_page" @paginate="list" />

				</div>
			</div>
		</div>
		<ModalFormComponent/>
	
	</div>
</template>



<script>
// import ModalFormComponent from '@/components/incomes/ModalFormComponent.vue';
import ModalFormComponent from '../../components/incomes/ModalFormComponent.vue';

import { mapMutations, mapActions } from 'vuex';
import Pagination from 'vue-pagination-2';
import request from '@/services/request'
export default {
	name: "incomes-table",
	components: {
		ModalFormComponent,
		Pagination
	},
	data() {
		return {
			incomes: {},
			search: '',
			page: 1
		}
	},	
	mounted() {
		this.list();
		this.setHeaderTitle('Ingresos');
	},
	created() {
		Event.$on("event-load-incomes", () => {
			this.list();
		});
	},
	methods: {
		showCheck(income){	
			console.log('poso1');
			console.log(income);
			Event.$emit('event-form-income', income);
		},
		paysCheks(checks){
			let sum = 0;
			console.log(checks);
			checks.forEach((s) => {
				console.log(s);
                if (s.amount) {
                    sum += parseFloat(s.amount);
                }
            });
			return sum;
		},
		deleted: function (id) {
			let tit = 'Eliminar ingreso';
			let txt = 'Seguro que quiere eliminar la ingreso';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/incomes/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/incomes';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.incomes = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
