<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                user_id: '',
                building_id: '',
                company_id: '',
                type_id: 10,
                amount: '',
                billing_data_id: '',
                bank_account_user_id: '',
                items: []
            },
            cataloguePays: [],
            checks: [],
            rent: '',
            file: '',
            file_name: '',
            send: false,
            load: false,
        }
    },
    methods: {
        get_company() {
            this.catalogue.get_companies_buildings(this.form.building_id);
        },
        closedModal() {
            this.form.id = '';
            this.form.building_id = '';
            this.form.user_id = '';
            this.form.company_id = '';
            this.form.type_id = 10;
            this.form.amount = '';
            this.form.billing_data_id = '';
            this.form.items = [];
            this.send = false;
            this.load = false;
            this.$modal.hide('addPettyCashContribution');
        },
        async savePayTaxes() {
            this.send = true;
            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('user_id', this.form.user_id)
            data_form.append('building_id', this.form.building_id)
            data_form.append('company_id', this.form.company_id)
            data_form.append('type_id', this.form.type_id)
            data_form.append('amount', this.form.amount)
            data_form.append('billing_data_id', this.form.billing_data_id)
            data_form.append('bank_account_user_id', this.form.bank_account_user_id)

            let _endpoint = '/petty_cash_contry';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/petty_cash/${this.form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                console.log(data.message);
                if (data) {
                    this.send = false;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-petty-cash');
                }
            } catch (error) {
                this.errors = {};
                console.log(error);

                if (error.response) {
                    this.send = false;
                    this.errors = await this.errorNotification(error);
                }
            }
        },        
        getBankBill() {
            let billing_data_id = this.form.billing_data_id;
            this.catalogue.getBankBillAccountProvider(billing_data_id);
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    async mounted() {
        this.catalogue.get_users_profile(5);
        this.catalogue.get_bills_types();
        this.catalogue.get_types_bill_number();
    },
    created() {
        Event.$on("event-show-addBillPettyCashCont", async (resident, movement = false) => {
            this.form.user_id = resident.user_id;
            this.form.building_id = resident.building_id;

            this.catalogue.get_buildings_resident(this.form.user_id);
            this.catalogue.getBillingDataUser(this.form.user_id);
            this.get_company();

            console.log(resident);
            console.log(movement);
            if (movement) {
                this.form.id = movement.id;
                this.form.company_id = movement.company_id;
                this.form.amount = movement.amount;
                this.form.billing_data_id = movement.billing_data_id;
                this.getBankBill();               
                this.form.bank_account_user_id = movement.bank_account_user_id;
            //     this.form.building_id = petty.building_id;
            //     this.form.company_id = petty.company_id;
            //     this.form.type_id = petty.type_id;
            //     this.form.amount = petty.amount;
            //     this.form.items = petty.items;
            //     this.form.billing_data_id = petty.billing_data_id;
            //     this.getBankBill();
            //     this.form.bank_account_user_id = petty.bank_account_user_id;
            //     this.get_company();
            }

            this.$modal.show('addPettyCashContribution');

        })
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        }),
        totalAmount() {
            return this.form.items.reduce((total, item) => {
                return total + parseFloat(item.amount || 0);
            }, 0);
        },
    },
}
</script>
<template>
    <modal name="addPettyCashContribution" :width="'90%'" :maxHeight="300" :height="'auto'"
        v-bind:click-to-close="false">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="savePayTaxes" method="post" id="formTax" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información de reembolso</h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-md-4 mt-3">
                        <label>Residente de Obra</label>
                        <select disabled="" type="text" class="form-control" v-model="form.user_id">
                            <option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">{{
                                user.name }} {{ user.last_name }}</option>
                        </select>
                        <div v-if="errors && errors.building_id" class="text-danger">{{ errors.building_id[0] }}</div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Propiedad</label>
                        <select class="form-control" v-model="form.building_id" disabled=""
                            required="required" @change="get_company()">
                            <option value="">Selecciona un propiedad</option>
                            <option v-for="(building, index) in catalogue.buildings_resident" :value="building.id"
                                :key="index">
                                {{ building.name }}
                            </option>
                        </select>
                        <div v-if="errors && errors.building_id" class="text-danger">{{ errors.building_id[0] }}</div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Empresa</label>
                        <select class="form-control" v-model="form.company_id" :disabled="!prop_edit"
                            required="required">
                            <option value="">Selecciona un empresa</option>
                            <option v-for="(company, index) in catalogue.companies_buildings" :value="company.id"
                                :key="index">
                                {{ company.name }}
                            </option>
                        </select>
                        <div v-if="errors && errors.company_id" class="text-danger">{{ errors.company_id[0] }}</div>
                    </div>

                    <div class="col-md-3 mt-3">
                        <label>Tipo</label>
                        <select disabled class="form-control" v-model="form.type_id" @change="list()">
                            <option v-for="(type, index) in catalogue.bills_types" :value="type.id" :key="index">
                                {{ type.name }}
                            </option>
                        </select>
                        <div v-if="errors && errors.type_id" class="text-danger">{{ errors.type_id[0] }}</div>
                    </div>

                    <div class="col-md-3 mt-3">
                        <label>Datos de Facturación</label>
                        <select :disabled="!prop_edit" type="text" class="form-control" v-model="form.billing_data_id"
                            @change="getBankBill">
                            <option value="">Sin datos de facturación</option>
                            <option v-for="(billD, index) in catalogue.billing_data_user" :value="billD.id"
                                :key="index">{{
                                billD.business_name }} - {{ billD.rfc }}</option>
                        </select>
                        <div v-if="errors && errors.billing_data_id" class="text-danger">{{ errors.billing_data_id[0] }}
                        </div>
                    </div>

                    <div class="col-md-3 mt-3">
                        <label>Cuenta Bancaria</label>
                        <select :disabled="!prop_edit" type="text" class="form-control"
                            v-model="form.bank_account_user_id">
                            <option value="">Sin cuenta bancaria</option>
                            <option v-for="(bank, index) in catalogue.bank_bill_account_provider" :value="bank.id"
                                :key="index">
                                {{
                                bank.bank.name }} - {{ bank.account_number }}</option>
                        </select>
                        <div v-if="errors && errors.bank_account_user_id" class="text-danger">{{
                            errors.bank_account_user_id[0] }}</div>
                    </div>
                  
                            <div class="col-3 mt-3">
                                <label>Monto a pagar</label>
                                <money :disabled="!prop_edit" class="form-control"  v-model="form.amount"></money>
                                <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                            </div>
                    


                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" :disabled="send" type="submit"
                            class="btn float-right btn-outline-success">
                            <span v-if="send">
                                <i class="fa-solid fa-circle-notch fa-spin"></i> Guardando
                            </span>
                            <span v-else>
                                <i class="fas fa-save"></i> Guardar
                            </span>
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>

                </div>
            </div>
        </form>
    </modal>
</template>

<style>
.btnDel {
    position: absolute;
    right: 0px;
    top: -5px;
}
</style>