<template>
	<div class="col-12 ">
		<div class="row">
			<div class="col-12 ">
				<div class="card">
					<div class="card-header">
						<ul class="nav nav-justified">
							<li class="nav-item"><a data-toggle="tab" href="#tab-eg7-0"
									class="active nav-link">GENERALES</a></li>
							<!-- <li v-if="building && rules.length > 0" class="nav-item"><a data-toggle="tab" href="#tab-eg7-1" class="nav-link">REGLAMENTO</a></li> -->
							<li v-if="buildingd.id != ''" class="nav-item"><a data-toggle="tab" href="#tab-eg7-6"
									class="nav-link">INDIVISOS</a></li>
							<li v-if="buildingd.id != ''" class="nav-item"><a data-toggle="tab" href="#tab-eg7-2"
									class="nav-link">SERVICIOS</a></li>

							<!-- <li v-if="buildingd.id != ''" class="nav-item"><a data-toggle="tab" href="#tab-eg7-3"
									class="nav-link">FINANZAS</a></li> -->
							<li v-if="buildingd.id != ''" class="nav-item"><a data-toggle="tab" href="#tab-eg7-4"
									class="nav-link">DOCUMENTOS</a></li>
						</ul>
					</div>
					<div class="card-body">
						<div class="tab-content">
							<div class="tab-pane active" id="tab-eg7-0" role="tabpanel">
								<form @submit.prevent="saveBuildings">
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label for="name" class="control-label">Folio Interno:</label>
												<input required name="folio_int" type="text" class="form-control"
													v-model="buildingd.folio_int">
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="name" class="control-label">Nombre:</label>
												<input required name="name" type="text" class="form-control"
													v-model="buildingd.name">
											</div>
										</div>

										<div class="col-md-12">
											<div class="form-group">
												<label for="description" class="control-label">Descripción:</label>
												<textarea name="description" class="form-control" rows="3"
													v-model="buildingd.description"></textarea>
											</div>
										</div>

										<div class="col-md-4">
											<div class="form-group">
												<label for="name" class="control-label">Cuenta predial:</label>
												<input name="account_property" type="text" class="form-control"
													v-model="buildingd.account_property">
											</div>
										</div>

										<div class="col-md-4">
											<div class="form-group">
												<label for="name" class="control-label">Cuenta AGUA:</label>
												<input name="account_water" type="text" class="form-control"
													v-model="buildingd.account_water">
											</div>
										</div>

										<div class="col-md-4">
											<div class="form-group">
												<label for="name" class="control-label">No. Contrato LUZ:</label>
												<input name="account_light" type="text" class="form-control"
													v-model="buildingd.account_light">
											</div>
										</div>

										<input name="address" type="hidden" :value="place_form.address">
										<input name="latitude" type="hidden" :value="place_form.location.lat">
										<input name="longitude" type="hidden" :value="place_form.location.lng">

										<div class="col-md-12">
											<div class="form-group">
												<label for="description" class="control-label">Dirección:</label>
												<gmap-autocomplete @keydown.native.enter.prevent ref="mapsautocomplete"
													:value="buildingd.address" class="form-control"
													@place_changed="setPlace" />
											</div>
										</div>

										<div class="col-md-12">
											<gmap-map :center="place_form.location" :zoom="place_form.zoom"
												style="width: 100%; height: 400px">
												<gmap-marker v-if="place_form.location.lng"
													:position="place_form.location"></gmap-marker>
											</gmap-map>
										</div>
										<div class="col-12">
											<hr>
											<label class="control-label">Empresas:</label>
											<div class="table-responsive">
												<table class="table table-bordered text-center text-sm">
													<thead>
														<tr>
															<th>ID</th>
															<th>Nombre</th>
															<th>R.F.C</th>
															<th>Opciones</th>
														</tr>
													</thead>
													<tbody v-if="companies">
														<tr v-for="(user, index) in companies.data" :key="index">
															<td>{{ user.id }}</td>
															<td>{{ user.name }}</td>
															<td>{{ user.rfc }}</td>
															<td>
																<input type="checkbox" class="form-control form-control-sm"
																	:value="user.id" v-model="buildingd.companies">
															</td>
														</tr>
													</tbody>
													<tbody v-else>
														<tr>
															<td align="center" colspan="5">Sin resultados.</td>
														</tr>
													</tbody>
												</table>
											</div>
											<pagination v-model="page" :records="companies.total" :per-page="companies.per_page" @paginate="listCompanies" />

										</div>
									</div>

									<div class="row mt-4">
										<div class="offset-md-6 col-md-6 text-right">
											<a href="/buildings" class="btn btn-danger">Cancelar</a>
											<button type="submit" class="btn ml-2 btn-primary"><i class="fa fa-save"></i>
												Guardar</button>
										</div>
									</div>
								</form>
							</div>
							<div v-if="building && rules.length > 0" class="tab-pane" id="tab-eg7-1" role="tabpanel">
								<form :action="`/buildings/store-rules/${building.id}`" method="post"
									enctype="multipart/form-data">
									<ul class="list-unstyled rules">
										<table>
											<thead>
												<tr>
													<th>Regla</th>
													<th class="text-center">Multa</th>
													<th class="text-center">Tolerancia</th>
												</tr>
											</thead>
											<tr v-for="(rule, index) in rules" :key="index">
												<td>
													<div class="custom-checkbox custom-control">
														<input :checked="rule.active" type="checkbox"
															:name="`rules[${index}][id]`" :value="rule.id"
															:id="`exampleCustomCheckbox${index}`"
															class="custom-control-input">
														<label class="custom-control-label"
															:for="`exampleCustomCheckbox${index}`">{{ rule.name
															}}</label>
													</div>
												</td>
												<td>
													<div class="col-md-12">
														<input type="number" class="form-control"
															:name="`rules[${index}][penalty]`" :value="rule.penalty">
													</div>
												</td>
												<td>
													<div class="col-md-12">
														<input type="number" class="form-control"
															:name="`rules[${index}][tolerance]`" :value="rule.tolerance">
													</div>
												</td>
											</tr>
										</table>

									</ul>
									<div class="row mt-4">
										<div class="offset-md-6 col-md-6 text-right">
											<a href="/buildings" class="btn btn-danger">Cancelar</a>
											<button type="submit" class="btn btn-primary"><i class="fa fa-save"></i>
												Guardar</button>
										</div>
									</div>
								</form>
							</div>
							<div v-if="building" class="tab-pane" id="tab-eg7-2" role="tabpanel">

								<form @submit.prevent="storeServiceSave" method="POST">
									<div class="row">
										<div class="col-md-9">
											<div class="form-group">
												<label for="" class="control-label">Nombre</label>
												<input type="text" v-model="storeService.name" class="form-control" required
													name="name">
											</div>
										</div>
										<div class="col-md-3">
											<div class="form-group" style="margin-top: 35px">
												<button type="submit" class="btn btn-primary">
													<i class="fa fa-save"></i> Guardar
												</button>
											</div>
										</div>
									</div>
								</form>


								<table class="mb-0 table table-striped">
									<thead class="bg-navy text-white">
										<tr>
											<th>Nombre</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(service, index) in this.catalogue.services" :key="index">
											<td>{{ service.name }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div v-if="building" class="tab-pane" id="tab-eg7-3" role="tabpanel">
								<form @submit.prevent="storeFinanceSave" id="form-finance" method="POST">
									<div class="row">
										<div class="col-md-12">
											<div class="form-group">
												<label for="" class="control-label">Banco</label>
												<select required="" v-model="buildingd.bank_id" name="bank_id"
													class="form-control">
													<option value="">Selecciona un banco</option>
													<option :value="1">csdacas</option>
													<option v-for="(bank, index) in banks" :key="index" :value="bank.id">{{
														bank.name }}
													</option>
												</select>
											</div>
										</div>
										<div class="col-md-12">
											<div class="form-group">
												<label for="" class="control-label">Número cuenta:</label>
												<input required="" type="text" name="no_account" class="form-control"
													v-model="buildingd.no_account">
											</div>
										</div>
										<div class="col-md-12">
											<div class="form-group">
												<label for="" class="control-label">CLABE Interbancaria:</label>
												<input required="" type="text" name="clabe" v-mask="['##################']"
													class="form-control" v-model="buildingd.clabe">
											</div>
										</div>
										<div class="col-md-12">
											<div class="form-group">
												<label for="" class="control-label">Monto acumulado cuenta
													bancaria:*</label>
												<input type="hidden" id="amount_bank" name="amount_bank">
												<input type="text" :readonly="clearMoney(buildingd.amount_bank) > 0"
													name="monto_banco" v-money="$root.money" class="form-control"
													v-model.lazy="buildingd.amount_bank">
											</div>
										</div>
										<div class="col-md-12">
											<div class="form-group">
												<label for="" class="control-label">Monto acumulado caja chica:*</label>
												<input type="hidden" id="amount_concentrated" name="amount_concentrated">
												<input type="text" :readonly="clearMoney(buildingd.amount_concentrated) > 0"
													name="caja_chica" v-money="$root.money" class="form-control"
													v-model.lazy="buildingd.amount_concentrated">
											</div>
											<small>
												* Verifica las cantidades que ingreses, una vez que guardes esa
												información ya no podrás modificarlo.
											</small>
										</div>
										<div class="col-md-12">
											<div class="form-group text-right">
												<button class="btn btn-primary">
													<i class="fa fa-save"></i> Guardar
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
							<div v-if="building" class="tab-pane" id="tab-eg7-4" role="tabpanel">
								<div v-if="loadFiles" class="col-12 text-center p-5">
									<div class="fa fa-spinner fa-spin fa-5x"></div>
								</div>
								<form v-else :action="building.id ? `/buildings/store/${building.id}` : '/buildings/store'"
									method="post" enctype="multipart/form-data">
									<div class="row">
										<div class="col-12">
											<div class="form-group">
												<label for="name" class="control-label">Documento:</label>
												<input required name="name" type="file" class="form-control"
													@change="uploadFile">
											</div>
											<hr>
										</div>
										<div class="col-md-3" v-for="(file, index)  in files" :key="index">
											<div class="card">
												<div class="card-body text-center">
													<a :href="`${globalUrlFiles}/buildingsf/${file.pivot.file}`"
														target="_blank">
														<i class="fa fa-file fa-5x"></i>
														<p>{{ file.pivot.file_name }}</p>
													</a>

													<hr>
													<button type="button" class="btn btn-sm btn-danger float-right"
														@click="deleteFile(file.pivot.id)"><i
															class="fa fa-trash"></i></button>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
							<div v-if="building" class="tab-pane" id="tab-eg7-6" role="tabpanel">
								<DepartamentsList />
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- <div class="col-6">
				<div class="main-card mb-3 card">
					<div class="card-body">
						<h3>Cuenta bancaria:</h3>
						<h2> {{ getBanco(general_payments.data) | money }}</h2>
					</div>
				</div>
			</div>
			<div class="col-6">
				<div class="main-card mb-3 card">
					<div class="card-body">
						<h3>Caja chica:</h3>
						<h2> {{ getCajaChica(general_payments.data) | money }}</h2>
					</div>
				</div>
			</div> -->

		</div>
		<ModalDepartment :building_id="buildingd.id" />
		<ModalServices :building_id="buildingd.id" />
	</div>
</template>

<script>
import request from '@/services/request'
import { mapActions } from 'vuex';

import Catalogue from '../../services/catalogue';
import { VMoney } from 'v-money'

import ModalDepartment from '@/components/buildings/ModalDepartment.vue'
import ModalServices from '@/components/buildings/ModalServices.vue';
import DepartamentsList from '@/components/departments/ListComponent.vue';
import Pagination from 'vue-pagination-2';

export default {
	props: {
	},
	name: "users-table",
	directives: { money: VMoney },
	components: {
		ModalDepartment,
		ModalServices,
		DepartamentsList,
		Pagination
	},
	data() {
		return {
			catalogue: new Catalogue(),
			companies: {},
			// PROPS
			building: {
				id: ''
			},
			rules: [],
			services: [],
			banks: [],
			// 
			general_payments: {
				type: Object,
				default: null
			},
			filtro_general_payments: {
				search: '',
				building_id: ''
			},
			load: false,
			storeService: {
				name: ''
			},
			storeFinance: {
				name: ''
			},
			buildingd: {
				id: '',
				name: '',
				folio_int: '',
				description: '',
				address: '',
				account_property: '',
				account_water: '',
				account_light: '',
				companies: []
			},
			department: {
				building: '',
				condominum: '',
				number: '',
				level: '',
				area: ''
			},
			files: [],
			loadFiles: false,
			place: '',
			place_form: {
				address: '',
				location: {
					lat: 19.432608,
					lng: -99.133209
				},
				zoom: 13
			},
			page:1,
		}
	},
	methods: {
		newDepartment(department) {
			Event.$emit('event-show-addDepartment', department);
		},
		addServices(department) {
			Event.$emit('event-show-addServices', department);
		},
		async uploadFile(event) {
			let _endpoint = '/buildings/store-files/' + this.buildingd.id;

			this.loadFiles = true;
			const data_form = new FormData();
			data_form.append('file', event.target.files[0]);
			try {
				let { data } = await request.postMedia(_endpoint, data_form)
				if (data) {
					this.list_documents();
					this.loadFiles = false;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.loadFiles = false;
				}
			}
		},
		async listCompanies(page = 1) {
			let search = {};
			let _endpoint = '/companies';
			try {
				let { data } = await request.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.companies = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		async list_documents() {
			this.loadFiles = true;
			let _endpoint = '/buildings/get-documents/' + this.buildingd.id;
			try {

				let { data } = await request.get(_endpoint);
				this.files = data.documents;
				this.loadFiles = false;
			} catch (error) {
				console.error(error);
				this.loadFiles = false;
			}
		},
		async storeServiceSave() {
			let _endpoint = `/buildings/store-service/${this.buildingd.id}`;
			let form = this.storeService;
			try {
				let { data } = await request.postMedia(_endpoint, form)
				if (data) {
					this.storeService.name = '';
					// this.users = data.data;
					await this.catalogue.get_services();
					this.successNotification(data.message);
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					await this.errorNotification(error);
				}
			}
		},
		async storeFinanceSave() {
			let _endpoint = `/buildings/store-finance/${this.buildingd.id}`;
			let form = this.buildingd;
			try {
				let { data } = await request.postMedia(_endpoint, form)
				if (data) {
					// this.users = data.data;
					this.successNotification(data.message);
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					await this.errorNotification(error);
				}
			}
		},
		async saveBuildings() {
			let building = this.buildingd;
			let _endpoint = '/buildings';
			if (this.buildingd.id != "") {
				building._method = 'PUT';
				_endpoint = `/buildings/${this.buildingd.id}`;
			}
			building.latitude = this.place_form.location.lat;
			building.longitude = this.place_form.location.lng;

			try {
				let { data } = await request.postMedia(_endpoint, building)
				if (data) {
					console.log(data);
					// this.users = data.data;
					this.successNotification(data.message);
					this.$router.push('/buildings');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					await this.errorNotification(error);
				}
			}
		},
		getBanco(data = null) {
			let caja = 0;
			if (data) {
				data.forEach(function callback(currentValue) {
					if (currentValue.payment_destination_id == 1) {
						if (currentValue.payment_types_id == 1) {
							caja += parseFloat(currentValue.amount);
						} else {
							caja -= parseFloat(currentValue.amount);
						}
					}
				});
			}
			return caja;
		},
		setPlace(place) {
			let result = new Promise(function (resolve, reject) {

				if (typeof place.location != undefined) {
					console.debug('place');
					console.debug(place);
					resolve(place);
				} else {
					console.debug('result');
					console.debug(result);
					reject(result);
				}
			});

			result.then((place) => {
				this.place_form.address = place.formatted_address;
				this.place_form.location.lat = place.geometry.location.lat();
				this.place_form.location.lng = place.geometry.location.lng();
				this.place_form.zoom = 17;

				this.buildingd.address = place.formatted_address;
			});
		},
		clearMoney(value) {
			if (value) {
				let re = /,/g;
				var resultado = value.replace(re, '');
				return resultado;
			} else {
				return 0;
			}
		},
		getCajaChica(data = null) {
			let caja = 0;
			if (data) {
				data.forEach(function callback(currentValue) {
					if (currentValue.payment_destination_id == 2) {
						if (currentValue.payment_types_id == 1) {
							caja += parseFloat(currentValue.amount);
						} else {
							caja -= parseFloat(currentValue.amount);
						}
					}
				});
			}
			return caja;
		},
		async list_general_payments(page = 1) {
			this.load = true;
			let params = this.filtro_general_payments;
			try {

				let url = `/general_payments_building?page=${page}`;
				let { data } = await request.get(url, params);
				this.general_payments = data;
				this.load = false;
			} catch (error) {
				this.errors = {};
				this.load = false;
				if (error.response) {
					await this.errorNotification(error);
				}
			}
		},
		async get_id(id) {
			let _endpoint = `/buildings/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.buildingd.name = data.building.name;
					this.buildingd.folio_int = data.building.folio_int;
					this.buildingd.description = data.building.description;
					this.buildingd.address = data.building.address;
					this.buildingd.account_property = data.building.account_property;
					this.buildingd.account_water = data.building.account_water;
					this.buildingd.account_light = data.building.account_light;

					this.place_form.address = data.building.address;
					this.place_form.location.lat = data.building.latitude ? parseFloat(data.building.latitude) : 19.432608;
					this.place_form.location.lng = data.building.longitude ? parseFloat(data.building.longitude) : -99.133209;
					this.place_form.zoom = 17;

					this.filtro_general_payments.building_id = this.building.id;

					this.buildingd.bank_id = data.building.bank_id;
					this.buildingd.no_account = data.building.no_account;
					this.buildingd.clabe = data.building.clabe;
					this.buildingd.amount_bank = data.building.amount_bank;
					this.buildingd.amount_concentrated = data.building.amount_concentrated;
					this.buildingd.departments = data.building.departments;
					await this.catalogue.get_services();
					await this.list_general_payments();
					await this.list_documents();

					let companies = [];
					data.companies.forEach(function (valor) {
						companies.push(valor.company_id);
					});
					console.log(companies);

					this.buildingd.companies = companies;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	mounted() {
		let id = this.$route.params.id;
		this.listCompanies();
		if (id) {
			this.buildingd.id = id;
			this.get_id(id);
		}
	},
	created() {
		Event.$on("event-load-building", () => {
			this.get_id(this.buildingd.id);
		});
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.mx-datepicker {
	width: 100%;
}

.fs-6 {
	font-size: 12px;
}
</style>
