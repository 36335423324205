<script>
import request from '@/services/request'
import { mapActions } from 'vuex';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            errors: {},
            send: false,
            amount: 0,
            form: {
                id: '',
                tenant_id: '',
                type: '',
                percentage: '',
                amount: '',
            },
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.tenant_id = '';
            this.form.type = '';
            this.form.percentage = '';
            this.form.amount = '';
            this.$modal.hide('addIncreaseIncome');
        },
        async triggerSave() {
            const data_form = new FormData();

            data_form.append('id', this.form.id);
            data_form.append('tenant_id', this.form.tenant_id);
            data_form.append('type', this.form.type);
            data_form.append('percentage', this.form.percentage);
            data_form.append('amount', this.form.amount);

            let _endpoint = '/increase_rent';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/increase_rent/${this.form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-debts');
                }
            } catch (error) {
                this.errors = await this.errorNotification(error);
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    created() {
        Event.$on("event-show-addIncreaseIncomes", async (tenant, increase = '') => {
            console.log(tenant.increase);
            console.log(increase);
            if(tenant.increase.type == 2 || tenant.increase.type == 3){
                this.form.type = 1;
            }else{
                this.form.type = 2;
            }
             
            this.amount = tenant.amount;
            this.form.tenant_id = tenant.id;
            if (increase) {
                this.form.id = increase.id;
                this.form.type = increase.type;
                this.form.percentage = increase.percentage;
                this.form.amount = increase.amount;
            }
            this.$modal.show('addIncreaseIncome');
        });
    },
    watch: {
        'form.percentage'(value) {
            let vm = this;
            console.log(value);
            console.log(vm.form.id);
            console.log(vm.form.id == '');
            if (vm.form.id == '') {
                vm.form.amount = vm.amount * (value / 100);
            }
        }
    }
}
</script>
<template>
    <modal name="addIncreaseIncome" :width="'80%'" :maxHeight="300" :height="'auto'" v-bind:click-to-close="false">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveNumberBill" method="post" id="formTax" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Aumento de renta</h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-md-6 mt-3">
                        <label>Monto Actual</label>
                        <h5> {{ amount | toCurrency }} </h5>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Tipo</label>
                        <select disabled class="form-control" v-model="form.type" >
                            <option value="">-- Sin opción --</option>
                            <option value="1">Porcentaje</option>
                            <option value="2">Monto</option>
                        </select>
							<div v-if="errors && errors.type" class="text-danger">{{ errors.type[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3" v-if="form.type == 1">
                        <label>Porcentaje</label>
                        <input type="number" class="form-control" v-model="form.percentage" :disabled="form.id != ''">
                    </div>
                    <div class="mt-3" :class="form.type == 1 ? 'col-md-6' : 'col-md-12'">
                        <label>Monto</label>
                        <money class="form-control" v-model="form.amount" :disabled="form.id != ''"></money>
                        <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                    </div>
                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col-md-12">
                        <button type="button" v-if="form.id == ''" @click="triggerSave()" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>

                </div>
            </div>
        </form>
    </modal>
</template>