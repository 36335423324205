<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                building_id: '',
                company_id: '',
                type_id: 8,
                amount: '',
                billing_data_id: '',
                bank_account_user_id:'',
                items: []
            },
            cataloguePays: [],
            checks: [],
            rent: '',
            file: '',
            file_name: '',
            send: false,
            load: false,
        }
    },
    methods: {
        get_company() {
            this.catalogue.get_companies_buildings(this.form.building_id);
        },
        closedModal() {
            this.form.id = '';
            this.form.building_id = '';
            this.form.company_id = '';
            this.form.type_id = 8;
            this.form.amount = '';
            this.form.billing_data_id = '';
            this.form.items = [];
            this.send = false;
            this.load = false;
            this.$modal.hide('addPettyCash');
        },

        handleFileChange(event, index, field) {
            const file = event.target.files[0];
            this.$set(this.form.items[index], field, file);
            let isXML = event.target.files[0].type == 'text/xml' ? true : false;
            if(isXML){
                this.catchDataXML(file, index);
            }  
        },

        async catchDataXML(file, index) {
            console.log(file);
            const data_form = new FormData();
            data_form.append('xml', file);

            let _endpoint = `/bill_load_xml`;

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {

                    const form = this.form.items[index];
                    form.rfc = data.RfcEmisor;
                    form.folio = data.Folio;
                    form.tax_folio = data.UUID;
                    form.subtotal = data.SubTotal;
                    form.iva = data.iva;
                    form.iva_ret = data.retencion;
                    form.discount = data.Descuento;
                    form.ieps = data.ieps;                                                                    
                
                }
            } catch (error) {
                this.send = false;
                this.errors = {};
                if (error.response) {

                    this.errors = await this.errorNotification(error);
                }
            }

        },

        async savePayTaxes() {
            this.send = true;
            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('building_id', this.form.building_id)
            data_form.append('company_id', this.form.company_id)
            data_form.append('type_id', this.form.type_id)
            //data_form.append('amount', this.form.amount)
            data_form.append('amount', this.totalAmount)
            data_form.append('billing_data_id', this.form.billing_data_id)
            data_form.append('bank_account_user_id', this.form.bank_account_user_id)

            this.form.items.forEach((item, index) => {
                data_form.append(`items[${index}][id]`, item.id);
                data_form.append(`items[${index}][type_id]`, item.type_id);
                data_form.append(`items[${index}][fileUpload]`, item.fileUpload);
                data_form.append(`items[${index}][xmlUpload]`, item.xmlUpload);
                data_form.append(`items[${index}][rfc]`, item.rfc);
                data_form.append(`items[${index}][concepto]`, item.concepto);
                data_form.append(`items[${index}][subtotal]`, item.subtotal);
                data_form.append(`items[${index}][iva]`, item.iva);
                data_form.append(`items[${index}][ieps]`, item.ieps);
                data_form.append(`items[${index}][isr_ret]`, item.isr_ret);
                data_form.append(`items[${index}][iva_ret]`, item.iva_ret);
                data_form.append(`items[${index}][discount]`, item.discount);
                data_form.append(`items[${index}][amount]`, item.amount);
            });

            let _endpoint = '/petty_cash';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/petty_cash/${this.form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                console.log(data.message);
                if (data) {
                    this.send = false;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-petty-cash');
                }
            } catch (error) {
                this.errors = {};
                console.log(error);

                if (error.response) {
                    this.send = false;
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        addItem() {
            let item = {
                id: '',
                type_id: '',
                fileUpload: '',
                xmlUpload: '',
                rfc: '',
                concepto: '',
                subtotal: 0,
                iva: 0,
                ieps: 0,
                isr_ret: 0,
                iva_ret: 0,
                discount: 0,
                amount: 0,
            };
            this.form.items.push(item);
        },
        deleteItem(index) {
            let vm = this;
            this.$swal({
                title: 'Eliminar item',
                text: 'Se eliminara el item',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    vm.form.items.splice(index, 1);
                }
            });
        },
        updateItemTotal(index) {
            const item = this.form.items[index];
            const subtotal = parseFloat(item.subtotal || 0);
            const iva = parseFloat(item.iva || 0);
            const ieps = parseFloat(item.ieps || 0);
            const isr_ret = parseFloat(item.isr_ret || 0);
            const iva_ret = parseFloat(item.iva_ret || 0);
            const discount = parseFloat(item.discount || 0);
            const total = subtotal + iva + ieps - isr_ret - iva_ret - discount;
            item.amount = total;
        },
        getBankBill(){
            let billing_data_id = this.form.billing_data_id;
            this.catalogue.getBankBillAccountProvider(billing_data_id);
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    async mounted() {
        this.catalogue.get_buildings_resident(this.authUser.id);
        this.catalogue.get_bills_types();
        this.catalogue.get_types_bill_number();
        this.catalogue.getBillingDataUser(this.authUser.id);
    },
    created() {
        Event.$on("event-show-addPettyCash", async (petty = false) => {
            if (petty) {
                this.form.id = petty.id;
                this.form.building_id = petty.building_id;
                this.form.company_id = petty.company_id;
                this.form.type_id = petty.type_id;
                this.form.amount = petty.amount;
                this.form.items = petty.items;
                this.form.billing_data_id = petty.billing_data_id;
                this.getBankBill();
                this.form.bank_account_user_id = petty.bank_account_user_id;
                this.get_company();
            }

            this.$modal.show('addPettyCash');

        })
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        }),
        totalAmount() {
            return this.form.items.reduce((total, item) => {
                return total + parseFloat(item.amount || 0);
            }, 0);
        },        
    },
}
</script>
<template>
    <modal name="addPettyCash" :width="'90%'" :maxHeight="300" :height="'auto'" v-bind:click-to-close="false">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="savePayTaxes" method="post" id="formTax" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información de reembolso</h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-md-4 mt-3">
                        <label>Propiedad</label>
                        <select class="form-control" v-model="form.building_id" :disabled="!prop_edit"
                            required="required" @change="get_company()">
                            <option value="">Selecciona un propiedad</option>
                            <option v-for="(building, index) in catalogue.buildings_resident" :value="building.id"
                                :key="index">
                                {{ building.name }}
                            </option>
                        </select>
                        <div v-if="errors && errors.building_id" class="text-danger">{{ errors.building_id[0] }}</div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Empresa</label>
                        <select class="form-control" v-model="form.company_id" :disabled="!prop_edit"
                            required="required">
                            <option value="">Selecciona un empresa</option>
                            <option v-for="(company, index) in catalogue.companies_buildings" :value="company.id"
                                :key="index">
                                {{ company.name }}
                            </option>
                        </select>
                        <div v-if="errors && errors.company_id" class="text-danger">{{ errors.company_id[0] }}</div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Tipo</label>
                        <select disabled class="form-control" v-model="form.type_id" @change="list()">
                            <option v-for="(type, index) in catalogue.bills_types" :value="type.id" :key="index">
                                {{ type.name }}
                            </option>
                        </select>
                        <div v-if="errors && errors.type_id" class="text-danger">{{ errors.type_id[0] }}</div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <label>Datos de Facturación</label>
                        <select :disabled="!prop_edit" type="text" class="form-control" v-model="form.billing_data_id" @change="getBankBill">
                            <option value="">Sin datos de facturación</option>
                            <option v-for="(billD, index) in catalogue.billing_data_user" :value="billD.id"
                                :key="index">{{
        billD.business_name }} - {{ billD.rfc }}</option>
                        </select>
                        <div v-if="errors && errors.billing_data_id" class="text-danger">{{ errors.billing_data_id[0] }}</div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <label>Cuenta Bancaria</label>
                        <select :disabled="!prop_edit" type="text" class="form-control"
                            v-model="form.bank_account_user_id">
                            <option value="">Sin cuenta bancaria</option>
                            <option v-for="(bank, index) in catalogue.bank_bill_account_provider" :value="bank.id"
                                :key="index">
                                {{
        bank.bank.name }} - {{ bank.account_number }}</option>
                        </select>
                        <div v-if="errors && errors.bank_account_user_id" class="text-danger">{{
        errors.bank_account_user_id[0] }}</div>
                    </div>

                    <div class="col-md-12 mt-3">
                        <hr>
                        <h5>Items</h5>
                        <div class="row">
                            <div class="col-12">
                                <div class="card p-0 mt-3" v-for="(item, index_value) in form.items" :key="index_value">
                                    <div class="card-body">
                                        <div class="row">
                                            <button class="btn btn-sm btn-danger btnDel" type="button" v-if="prop_edit"
                                                @click="deleteItem(index_value)"><i class="fa fa-trash"></i> </button>
                                            <div class="col-md-4">
                                                <label>Tipo</label>
                                                <select class="form-control" :disabled="!prop_edit"
                                                    v-model="item.type_id">
                                                    <option :value="type.id" v-show="type.id != 1"
                                                        v-for="(type, index) in catalogue.types_bill_number"
                                                        :key="index">
                                                        {{ type.name }}</option>
                                                </select>
                                                <div v-if="errors && errors[`items.${index_value}.type_id`]"
                                                    class="text-danger">
                                                    {{ errors[`items.${index_value}.type_id`][0] }}
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <label>PDF o Imagen</label>

                                                <input :disabled="!prop_edit" type="file" class="form-control"
                                                    accept="image/jpeg,image/gif,image/png,application/pdf"
                                                    @change="handleFileChange($event, index_value, 'fileUpload')">

                                                <a class="btn btn-info btn-sm" target="_blank"
                                                    :href="`${globalUrlFiles}/storage/${item.file}`"
                                                    v-if="item.id && item.file">
                                                    <i class="fa-regular fa-file-pdf"></i>
                                                </a>

                                            </div>
                                            <div class="col-md-4">
                                                <label>XML</label>
                                                <input :disabled="!prop_edit" type="file" class="form-control"
                                                    accept="text/xml"
                                                    @change="handleFileChange($event, index_value, 'xmlUpload')">
                                                <a class="btn btn-info btn-sm" target="_blank"
                                                    :href="`${globalUrlFiles}/storage/${item.xml}`"
                                                    v-if="item.id && item.xml">
                                                    <i class="fa-regular fa-file"></i>
                                                </a>
                                            </div>
                                            <div class="col-md-4">
                                                <label>RFC</label>
                                                <input :disabled="!prop_edit" class="form-control" v-model="item.rfc">
                                            </div>
                                            <div class="col-md-4">
                                                <label>Concepto</label>
                                                <input :disabled="!prop_edit" class="form-control"
                                                    v-model="item.concepto">
                                            </div>
                                            <div class="col-md-4">
                                                <label>Subtotal</label>
                                                <money :disabled="!prop_edit" class="form-control"
                                                    @input="updateItemTotal(index_value)" v-model="item.subtotal">
                                                </money>
                                            </div>
                                            <div class="col-md-4">
                                                <label>IVA</label>
                                                <money :disabled="!prop_edit" class="form-control"
                                                    @input="updateItemTotal(index_value)" v-model="item.iva"></money>
                                            </div>
                                            <div class="col-md-4">
                                                <label>IEPS</label>
                                                <money :disabled="!prop_edit" class="form-control"
                                                    @input="updateItemTotal(index_value)" v-model="item.ieps"></money>
                                            </div>
                                            <div class="col-md-4">
                                                <label>ISR RET</label>
                                                <money :disabled="!prop_edit" class="form-control"
                                                    @input="updateItemTotal(index_value)" v-model="item.isr_ret">
                                                </money>
                                            </div>
                                            <div class="col-md-4">
                                                <label>IVA RET</label>
                                                <money :disabled="!prop_edit" class="form-control"
                                                    @input="updateItemTotal(index_value)" v-model="item.iva_ret">
                                                </money>
                                            </div>
                                            <div class="col-md-4">
                                                <label>Descuento</label>
                                                <money :disabled="!prop_edit" class="form-control"
                                                    @input="updateItemTotal(index_value)" v-model="item.discount">
                                                </money>
                                            </div>
                                            <div class="col-md-4">
                                                <label>Total</label>
                                                <money disabled class="form-control" v-model="item.amount"></money>
                                                <div v-if="errors && errors[`items.${index_value}.amount`]"
                                                    class="text-danger">
                                                    {{ errors[`items.${index_value}.amount`][0] }}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div v-if="errors && errors.items" class="text-danger">{{ errors.items[0] }}</div>
                            </div>
                            <div class="col-12 text-center" v-if="prop_edit">
                                <button type="button" @click="addItem()" class="btn btn-success">
                                    <i class="fa fa-plus"></i>Agregar
                                </button>
                            </div>
                        </div>
                        <hr>
                    </div>


                    <div class="col-md-12 mt-3">
                        <div class="row justify-content-end">
                            <div class="col-4">
                                <label>Monto a pagar</label>
                                <money disabled class="form-control" :value="totalAmount"></money>
                                <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" :disabled="send" type="submit"
                            class="btn float-right btn-outline-success">
                            <span v-if="send">
                                <i class="fa-solid fa-circle-notch fa-spin"></i> Guardando
                            </span>
                            <span v-else>
                                <i class="fas fa-save"></i> Guardar
                            </span>
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>

                </div>
            </div>
        </form>
    </modal>
</template>

<style>
.btnDel {
    position: absolute;
    right: 0px;
    top: -5px;
}
</style>