<script>
import PageMenu from '@/pages/layouts/PageMenu.vue';
import titlePage from '@/pages/layouts/TitlePage.vue';
import { mapActions, mapGetters } from 'vuex';
import Catalogue from '@/services/catalogue';
import request from '@/services/request'

export default {
	components: {
		PageMenu,
		titlePage,
	},
	data() {
		return {
			imageBackground: `/img/oficina.jpg`,
			catalogue: new Catalogue(),
		}
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	methods: {
		async postLogout() {
			try {
				let logout = await this.authLogout()
				if (logout) {
					this.$nextTick(() => {
						location.reload()
					})
				}

			} catch (error) {
				console.log(error);
			}
		},
		getMessages() {
			this.catalogue.getMessagesuser(this.authUser.id);
		},
		getCheckCancel(){
			console.log('cascsac,sadclks,cdsclk');
			console.log(this.authUser);
			if(this.authUser.organization_charts_id == 1 || this.authUser.organization_charts_id == 3){
				this.catalogue.get_check_cancel();
			}
		},
		rediretCheck(id){
			this.$router.push({ name: 'checks', query: { id: id }})
		},
		async loadMessage(message) {
			this.$swal({
				title: "El mensaje enviado es :",
				html: message.message,
				showCloseButton: true,
				showCancelButton: false,
				confirmButtonText: 'Ok',
			});

			let form = {
				id: message.id,				
			};
			let _endpoint = '/message_view';
			try {
				let { data } = await request.postMedia(_endpoint, form)
				if (data) {
					if(data){
						this.getMessages()
					}
					// await this.successNotification(data.message);
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = this.errorNotification(error);
				}
			}

		},
		...mapActions({
			authLogout: 'auth/logout'
		})
	},
	mounted() {
		this.getMessages();
		this.getCheckCancel();		
	},
	created() {
		Event.$on("event-check-cancel", () => {
			this.getCheckCancel();		
		});
	},
}
</script>
<template>
	<div class="wrapper">

		<!-- Navbar -->
		<nav class="main-header navbar navbar-expand navbar-white bg-dark">
			<!-- Left navbar links -->
			<ul class="navbar-nav">
				<li class="nav-item">
					<a class="nav-link" data-widget="pushmenu" href="#" role="button">
						<i class="fas fa-bars"></i></a>
				</li>
			</ul>


			<!-- Right navbar links -->
			<ul class="navbar-nav ml-auto">

				<li class="nav-item dropdown" v-if="this.authUser.organization_charts_id == 1 || this.authUser.organization_charts_id == 3">
					<a class="nav-link" data-toggle="dropdown" href="#">						
						<i class="fa-solid fa-filter-circle-xmark"></i>
						<span class="badge badge-warning navbar-badge">{{ catalogue.checksCancel.length }}</span>
					</a>
					<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="left: inherit; right: 0px;">
						<span class="dropdown-item dropdown-header">{{ catalogue.checksCancel.length }} Cheques Cancelados <span
								v-if="catalogue.checksCancel.length > 1"></span> </span>
						<div class="dropdown-divider"></div>
						<router-link :to="`/checks?id=${message.id}`" class="dropdown-item" 
							v-show="catalogue.checksCancel.length > 0" v-for="(message, index) in catalogue.checksCancel"
							:key="index">
							<i class="fa-solid fa-receipt mr-2"></i> Ver cheque con el # {{message.id}}							
						</router-link>
						<div class="dropdown-divider"></div>
					</div>
				</li>
				
				<li class="nav-item dropdown" v-if="authUser.organization_charts_id == 2">
					<a class="nav-link" data-toggle="dropdown" href="#">
						<i class="far fa-bell"></i>
						<span class="badge badge-warning navbar-badge">{{ catalogue.messages_user.length }}</span>
					</a>
					<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="left: inherit; right: 0px;">
						<span class="dropdown-item dropdown-header">{{ catalogue.messages_user.length }} Mensaje <span
								v-if="catalogue.messages_user.length > 1"></span> </span>
						<div class="dropdown-divider"></div>
						<a href="#" class="dropdown-item" @click="loadMessage(message)"
							v-show="catalogue.messages_user.length > 0" v-for="(message, index) in catalogue.messages_user"
							:key="index">
							<i class="fas fa-envelope mr-2"></i> Ver mensaje
							<span class="float-right text-muted text-sm">
								<span class="text-info" v-if="message.read == 1"> Visto </span>
								<span class="text-success" v-else> Nuevo </span>
							</span>
						</a>
						<div class="dropdown-divider"></div>
					</div>
				</li>
				<!-- Notifications Dropdown Menu -->
				<li class="nav-item dropdown">
					<a class="nav-link" data-toggle="dropdown" href="#">
						<i class="fa fa-user-circle" aria-hidden="true"></i>
					</a>
					<div class="dropdown-menu dropdown-menu dropdown-menu-right">
						<router-link to="/profile" class="dropdown-item text-center">
							Editar cuenta
						</router-link>
						<div class="dropdown-divider"></div>
						<a href="#" @click="postLogout" class="dropdown-item text-center">
							<i class="fa fa-external-link" aria-hidden="true"></i> Cerrar sesión
						</a>
					</div>

				</li>
				<li class="nav-item">
					<a class="nav-link" data-widget="fullscreen" href="#" role="button">
						<i class="fas fa-expand-arrows-alt"></i>
					</a>
				</li>
			</ul>
		</nav>
		<!-- /.navbar -->

		<!-- Main Sidebar Container -->
		<aside class="main-sidebar elevation-4 sidebar-dark-navy">
			<!-- Brand Logo -->
			<a href="/admin/home" class="text-center">
				<div class="div_logo">
					<img src="@/assets/img/logo.png" alt="logo" class="img-circle elevation-3"
						style="opacity: .8; width: 80%; ">
				</div>
			</a>

			<!-- Sidebar -->
			<div class="sidebar">
				<!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
					<div class="image">
						<img :src="`${config.BaseUrl}/assets/img/logo.png`" class="img-circle elevation-2" alt="User Image">
					</div>
					<div class="info">
						<a href="#" class="d-block">{{ authUser.name }} {{ authUser.last_name }}</a>
					</div>
				</div> -->

				<PageMenu></PageMenu>

			</div>
			<!-- /.sidebar -->
		</aside>
		<div class="content-wrapper">
			<div class="col-12">

				<!-- Main content -->
				<section class="content">
					<div class="container-fluid">
						<titlePage />
						<div class="container-fluid">
							<router-view></router-view>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>
