<template>
	<div class="row bg align-items-center justify-content-center">
		<div class="login-box">
			<div class="card card-outline card-secondary" style=" background-color: #fff;">
				<div class="card-body">
					<div class="col-12 text-center mb-4">
						<img src="@/assets/img/logo.png" class="mt-3 mb-4" alt="Logo" width="150px;"><br>
						<h4 class="login-box-msg mt-n2 text-secondary">Registrarse</h4>
						<p class="mt-n3">Ingresa los datos para registrarte</p>
					</div>
					<form @submit.prevent="triggerRegister">
						<div class="row">

							<div class="col-12 col-md-6 mb-3">
								<div class="input-group ">
									<div class="input-group-append">
									</div>
									<input type="text" v-model="form.name" class="form-control" placeholder="Nombres"
										:class="{ 'is-invalid': submitted && !form.name }" />
								</div>
								<div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
							</div>
							<div class="col-12 col-md-6 mb-3">
								<div class="input-group ">
									<input type="text" v-model="form.last_name" class="form-control" placeholder="Apellidos"
										:class="{ 'is-invalid': submitted && !form.last_name }" />
								</div>
								<div v-if="errors && errors.last_name" class="text-danger">{{ errors.last_name[0] }}</div>
							</div>
							<div class="col-12 col-md-12-6 mb-3">
								<div class="input-group ">
									<input type="text" v-model="form.email" class="form-control" placeholder="Correo"
										:class="{ 'is-invalid': submitted && !form.email }" />
								</div>
								<div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
							</div>
							<div class="col-12 col-md-6 mb-3">
								<div class="input-group ">
									<input type="text" v-model="form.phone" class="form-control" placeholder="Teléfono"
										:class="{ 'is-invalid': submitted && !form.phone }" />
								</div>
								<div v-if="errors && errors.phone" class="text-danger">{{ errors.phone[0] }}</div>
							</div>
							<div class="col-12 col-md-6 mb-3">
								<div class="input-group ">
									<input type="text" v-model="form.whathsapp" class="form-control" placeholder="WhatsApp"
										:class="{ 'is-invalid': submitted && !form.whathsapp }" />
								</div>
								<div v-if="errors && errors.whathsapp" class="text-danger">{{ errors.whathsapp[0] }}</div>
							</div>
							<div class="col-12 col-md-6 mb-3">
								<div class="input-group ">
									<input type="text" v-model="form.business_name" class="form-control"
										placeholder="Razon social"
										:class="{ 'is-invalid': submitted && !form.business_name }" />
								</div>
								<div v-if="errors && errors.business_name" class="text-danger">{{ errors.business_name[0] }}
								</div>
							</div>
							<div class="col-12 col-md-6 mb-3">
								<div class="input-group ">
									<input type="text" v-model="form.rfc" class="form-control" placeholder=" R.F.C"
										:class="{ 'is-invalid': submitted && !form.rfc }" />
								</div>
								<div v-if="errors && errors.rfc" class="text-danger">{{ errors.rfc[0] }}</div>
							</div>
							<div class="col-12 mb-3">

								<div class="form-group text-center">
									<button class="btn btn-secondary" style="width: 200px;" :disabled="submitted">
										Registrarse
									</button>
								</div>
								<div class="col-md-12 text-center">
									<router-link to="/login" class="mt-5">Iniciar sesión</router-link>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
	},
	data() {
		return {
			form: {
				name: '',
				last_name: '',
				email: '',
				phone: '',
				whatsapp: '',
			},
			errors: {},
			submitted: false
		}
	},
	methods: {
		async triggerRegister() {
			try {
				let register = await this.registerAction(this.form)
				if (register) {
					this.successNotification(register.message);
					this.$router.push('/login')
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			registerAction: 'auth/register',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
};
</script>
<style scoped>
.login-box {
	width: 90%;
	max-width: 600px;
}</style>
