<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
    components: {
        DatePicker
    },
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                building_id: '',
                department_id: '',
                user_id: '',
                billing_data_id: '',
                amount: '',
                star_date: '',
                end_date: '',
                payment_period_id: '',
                file: '',
                description: '',
                services: [],
                files: [],
                type_payment_id: '',
                company_id: '',
                bank_account_id: '',
                currency_id: 1,
                _method: 'POST',
                // 
                deposit: '',
                forced_deadline: '',
                increase_date: '',
                percentage_increase: '',
                obligation: [{"title":"USO DEL INMUEBLE","rules":"CASA HABITACI\u00d3N"},{"title":"FECHA DE PAGO ","rules":"PRIMERO DIEZ DIAS DE CADA MES"},{"title":"SERVICIOS","rules":"ARRENDATARIA ES LA RESPONSABLE DEL PAGO, INSTALACI\u00d3N, CANCELACI\u00d3N Y MANTENIMIENTO DE TODOS LOS SERVICIOS PUBLICOS Y PRIVADOS QUE SEAN SUMISTRADOS EN EL INMUEBLE TALES COMO AGUA, ENERGIA ELECTRICA, GAS, TELEFONO, E INTERNET, SERVICIOS DE CABLE ENTRE OTROS."},{"title":"ESTACIONAMIENTO","rules":"2 LUGARES DE ESTACIONAMIENTO"},{"title":"MEJORAS","rules":"PREVIA AUTORIZACI\u00d3N POR PARTE DEL ARRENDADOR QUEDANDO AL TERMINO DEL CONTRATO EN BENEFICIO DEL ARRENDADOR, PODR\u00c1N SER RETIRADAS AQUELLAS QUE SEAN DE FACIL DESPRENDIMIENTO"},{"title":"SUBARRENDAMIENTO","rules":"QUEDA EXPRESAMENTE PROHIBIDO"},{"title":"INTERESES MORATORIOS","rules":"EL EQUIVALENTE A LA TASA DE INTERES INTERBANCARIA TIIE M\u00c1S 50% A RAZON DEL 5 PORCIENTO MENSUAL"},{"title":"INCREMENTO","rules":"EN CASO DE CONTINUAR CON EL ARRENDAMIENTO POSTERIOR A LA TERMINACI\u00d3N SUFRIRA UN INCREMENTO DE LA RENTA MENSUAL CONFORME AL INDICE NACIONAL DE PRECIOS AL CONSUMIDOR DENTRO DE LOS 12 MESES ANTERIORES A LA FECHA DLE INCREMENTO"}],
                rent_increase: [],
                subtotals: [],
                ivas: [],
                iepss: [],
                isr_rets: [],
                iva_rets: [],
                discounts: [],
                amounts: [],
                subtotal: '',
                iva: '',
                ieps: '',
                isr_ret: '',
                iva_ret: '',
                discount: '',
                type_increase_id: '',
                signed_contract: '',
            },
            file: '',
            fileName: '',
            files: []
        }
    },
    methods: {
        uploadFilesBill(event) {
            let vm = this;

            let file = event.target.files[0];
            // let allowedImageTypes = ["image/jpeg", "image/gif", "image/png"];
            // if (!allowedImageTypes.includes(file.type)) {
            //     this.$swal({
            //         icon: 'error',
            //         title: 'Error en el tipo de archivo',
            //         text: 'Solo se acepta archivo tipo imagen',
            //     });
            // } else {
            vm.form.files.push(file);
            console.log(vm.form.files);
            this.$forceUpdate();

            // }
        },
        // updateAmounts() {
        //     console.log('pasio');
        //     this.form.services.forEach(serviceId => {
        //         const subtotal = parseFloat(this.form.subtotals['id_' + serviceId]) || 0;
        //         const iva = parseFloat(this.form.ivas['id_' + serviceId]) || 0;
        //         const ieps = parseFloat(this.form.iepss['id_' + serviceId]) || 0;
        //         const isr_ret = parseFloat(this.form.isr_rets['id_' + serviceId]) || 0;
        //         const iva_ret = parseFloat(this.form.iva_rets['id_' + serviceId]) || 0;
        //         const discount = parseFloat(this.form.discounts['id_' + serviceId]) || 0;

        //         this.form.amounts['id_' + serviceId] = subtotal + iva + ieps - isr_ret - iva_ret - discount;
        //     });
        // },
        get_department() {
            let id = this.form.building_id;
            this.catalogue.get_departamens_buildings(id);
            this.catalogue.get_companies_buildings(id);
        },
        get_billing_data_user() {
            let user_id = this.form.user_id;
            this.catalogue.getBillingDataUser(user_id);
        },
        calcTotal() {
            this.form.amount = this.form.subtotal + this.form.iva + this.form.ieps - this.form.isr_ret - this.form.iva_ret - this.form.discount;
        },
        closedModal() {
            this.form.id = '';
            this.form.building_id = '';
            this.form.department_id = '';
            this.form.user_id = '';
            this.form.billing_data_id = '';
            this.form.amount = '';
            this.form.star_date = '';
            this.form.end_date = '';
            this.form.payment_period_id = '';
            this.form.file = '';
            this.form.company_id = '';
            this.form.services = [];
            this.form.obligation = [{"title":"USO DEL INMUEBLE","rules":"CASA HABITACI\u00d3N"},{"title":"FECHA DE PAGO ","rules":"PRIMERO DIEZ DIAS DE CADA MES"},{"title":"SERVICIOS","rules":"ARRENDATARIA ES LA RESPONSABLE DEL PAGO, INSTALACI\u00d3N, CANCELACI\u00d3N Y MANTENIMIENTO DE TODOS LOS SERVICIOS PUBLICOS Y PRIVADOS QUE SEAN SUMISTRADOS EN EL INMUEBLE TALES COMO AGUA, ENERGIA ELECTRICA, GAS, TELEFONO, E INTERNET, SERVICIOS DE CABLE ENTRE OTROS."},{"title":"ESTACIONAMIENTO","rules":"2 LUGARES DE ESTACIONAMIENTO"},{"title":"MEJORAS","rules":"PREVIA AUTORIZACI\u00d3N POR PARTE DEL ARRENDADOR QUEDANDO AL TERMINO DEL CONTRATO EN BENEFICIO DEL ARRENDADOR, PODR\u00c1N SER RETIRADAS AQUELLAS QUE SEAN DE FACIL DESPRENDIMIENTO"},{"title":"SUBARRENDAMIENTO","rules":"QUEDA EXPRESAMENTE PROHIBIDO"},{"title":"INTERESES MORATORIOS","rules":"EL EQUIVALENTE A LA TASA DE INTERES INTERBANCARIA TIIE M\u00c1S 50% A RAZON DEL 5 PORCIENTO MENSUAL"},{"title":"INCREMENTO","rules":"EN CASO DE CONTINUAR CON EL ARRENDAMIENTO POSTERIOR A LA TERMINACI\u00d3N SUFRIRA UN INCREMENTO DE LA RENTA MENSUAL CONFORME AL INDICE NACIONAL DE PRECIOS AL CONSUMIDOR DENTRO DE LOS 12 MESES ANTERIORES A LA FECHA DLE INCREMENTO"}];
            this.form.rent_increase = [];
            this.form.type_payment_id = '';
            this.form.description = '';
            this.form.building_id = '';
            this.form.bank_account_id = '';
            this.file = '';
            this.fileName = '';
            this.form.currency_id = 1;
            this.form.type_increase_id = '';
            this.form.signed_contract = '';

            this.form.deposit = '';
            this.form.forced_deadline = '';
            this.form.increase_date = '';
            this.form.percentage_increase = '';
            this.form.rent_increase = [];

            this.form.subtotals = [];
            this.form.ivas = [];
            this.form.iepss = [];
            this.form.isr_rets = [];
            this.form.iva_rets = [];
            this.form.discounts = [];
            this.form.amounts = [];

            this.form.subtotal = '';
            this.form.iva = '';
            this.form.ieps = '';
            this.form.isr_ret = '';
            this.form.iva_ret = '';
            this.form.discount = '';

            this.form.files = [];
            this.files = [];

            this.$modal.hide('addDepartamentUser');
        },
        catch_file(event) {
            this.form.file = event.target.files[0];
            this.fileName = event.target.files[0].name;
        },
        getBankCompany() {
            let company = this.form.company_id;
            this.catalogue.getBankAccountCompany(company);
        },
        addObligation() {
            let data = { title: '', rules: '' };
            this.form.obligation.push(data);
        },
        addRentIncrease() {
            let data = { date: '', type: '', value: '', check: '' };
            this.form.rent_increase.push(data);
        },
        deleteObligation(index) {
            let vm = this;
            this.$swal({
                title: 'Eliminar obligación',
                text: 'Se eliminara el titulo de obligación',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    vm.form.obligation.splice(index, 1);
                }
            });
        },
        deleteRentIncrease(index) {
            let vm = this;
            this.$swal({
                title: 'Eliminar incremento',
                text: 'Se eliminara el incremento',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    vm.form.rent_increase.splice(index, 1);
                }
            });
        },
        async saveBuildings() {
            try {
                const data_form = new FormData();
                data_form.append('id', this.form.id);
                data_form.append('department_id', this.form.department_id);
                data_form.append('user_id', this.form.user_id);
                data_form.append('billing_data_id', this.form.billing_data_id);
                data_form.append('amount', this.form.amount);
                data_form.append('currency_id', this.form.currency_id);
                data_form.append('type_payment_id', this.form.type_payment_id);
                data_form.append('building_id', this.form.building_id);

                if (this.form.star_date) {
                    let dt = this.moment(this.form.star_date).format("YYYY-MM-DD");
                    data_form.append('star_date', dt);
                }
                if (this.form.end_date) {
                    let dt = this.moment(this.form.end_date).format("YYYY-MM-DD");
                    data_form.append('end_date', dt);
                }

                data_form.append('payment_period_id', this.form.payment_period_id);
                data_form.append('file', this.form.file);
                data_form.append('description', this.form.description);
                data_form.append('company_id', this.form.company_id);
                data_form.append('bank_account_id', this.form.bank_account_id);

                data_form.append('deposit', this.form.deposit);
                data_form.append('forced_deadline', this.form.forced_deadline);
                data_form.append('increase_date', this.form.increase_date);
                data_form.append('percentage_increase', this.form.percentage_increase);
                data_form.append('obligation', JSON.stringify(this.form.obligation));
                data_form.append('rent_increase', JSON.stringify(this.form.rent_increase));


                data_form.append('subtotal', this.form.subtotal);
                data_form.append('iva', this.form.iva);
                data_form.append('ieps', this.form.ieps);
                data_form.append('isr_ret', this.form.isr_ret);
                data_form.append('iva_ret', this.form.iva_ret);
                data_form.append('discount', this.form.discount);
                data_form.append('type_increase_id', this.form.type_increase_id);
                data_form.append('signed_contract', this.form.signed_contract);

                const servicesArray = this.form.services.map(id => ({
                    id,
                    subtotal: this.form.subtotals['id_' + id] || 0,
                    iva: this.form.ivas['id_' + id] || 0,
                    ieps: this.form.iepss['id_' + id] || 0,
                    isr_ret: this.form.isr_rets['id_' + id] || 0,
                    iva_ret: this.form.iva_rets['id_' + id] || 0,
                    discount: this.form.discounts['id_' + id] || 0,
                    amount: this.form.amounts['id_' + id] || 0
                }));

                data_form.append('services', JSON.stringify(servicesArray));

                this.form.files.forEach(function (valor, index) {
                    data_form.append('files[' + index + ']', valor);
                });


                let _endpoint = '/tenant';
                if (this.form.id != "") {
                    data_form.append('_method', 'PUT');
                    _endpoint = `/tenant/${this.form.id}`;
                }

                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-debts');
                }



            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        async delImgUpload(pos) {
            this.$swal({
                title: 'Eliminar archivo',
                text: 'Se eliminara el archivo',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let img = this.files[pos];
                    let id = img.id;
                    let _endpoint = '/tenant/deleted-file-extras';
                    let form = {
                        id: id
                    };
                    let { data } = await request.postMedia(_endpoint, form)
                    if (data) {
                        this.successNotification(data.message);
                        this.files.splice(pos, 1);
                    }
                }
            });
        },
        delImg(pos) {
            this.form.files.splice(pos, 1);
            this.successNotification('El archivo se elimino correctamente.');
        },
        createURL(file) {
            let link = URL.createObjectURL(file);
            return link;
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    mounted() {
        this.catalogue.get_buildings();
        this.catalogue.get_users_profile(7);
        this.catalogue.get_type_payments();
        this.catalogue.get_services();
        this.catalogue.getCurrencies();
        this.catalogue.get_payment_periods();
        this.catalogue.get_type_increase();
    },
    created() {
        Event.$on("event-show-addDepartamentUser", (pay) => {
            console.log(pay);
            let vm = this;

            // this.form.building_id = departament.building_id;

            // departament.id =
            //     this.form.department_id = departament.id;

            if (pay.id) {
                this.form.id = pay.id;
                this.form.building_id = pay.building_id;
                if (pay.building_id) {
                    this.get_department();
                }
                if (pay.currency_id) {
                    this.form.currency_id = pay.currency_id;
                }
                this.form.signed_contract = pay.signed_contract == 1 ? true : false;
                this.form.type_increase_id = pay.type_increase_id;
                this.form.department_id = pay.department_id;
                this.form.user_id = pay.user_id;
                this.form.company_id = pay.company_id;
                this.form.billing_data_id = pay.billing_data_id;

                if (pay.star_date) {
                    let dt = this.moment(pay.star_date).format('YYYY-MM-DD');
                    this.form.star_date = dt;
                }

                if (pay.end_date) {
                    let dt = this.moment(pay.end_date).format("YYYY-MM-DD");
                    this.form.end_date = dt;
                }

                this.form.payment_period_id = pay.payment_period_id;
                this.form.description = pay.description;
                let services = [];
                pay.services.forEach(function (valor) {
                    services.push(valor.id);
                    console.log(valor.pivot);
                    vm.form.subtotals['id_' + valor.id] = valor.pivot.subtotal;
                    vm.form.ivas['id_' + valor.id] = valor.pivot.iva;
                    vm.form.iepss['id_' + valor.id] = valor.pivot.ieps;
                    vm.form.isr_rets['id_' + valor.id] = valor.pivot.isr_ret;
                    vm.form.iva_rets['id_' + valor.id] = valor.pivot.iva_ret;
                    vm.form.discounts['id_' + valor.id] = valor.pivot.discount;
                    vm.form.amounts['id_' + valor.id] = valor.pivot.amount;

                });
                this.form.services = services;

                this.form.type_payment_id = pay.type_payment_id;
                this.form.bank_account_id = pay.bank_account_id;
                if (pay.file) {
                    this.file = pay.file;
                }

                this.form.deposit = pay.deposit;
                this.form.forced_deadline = pay.forced_deadline;
                this.form.increase_date = pay.increase_date;
                this.form.percentage_increase = pay.percentage_increase;
                this.form.obligation = pay.obligation;
                this.form.rent_increase = pay.rent_increase;

                this.form.subtotal = pay.subtotal;
                this.form.iva = pay.iva;
                this.form.ieps = pay.ieps;
                this.form.isr_ret = pay.isr_ret;
                this.form.iva_ret = pay.iva_ret;
                this.form.discount = pay.discount;
                this.form.amount = pay.amount;

                if (pay.files_extras) {
                    this.files = pay.files_extras;
                }

                this.getBankCompany();
                this.get_billing_data_user();
            }


            this.$modal.show('addDepartamentUser');
        });
    },
    computed: {
        updatedAmounts() {
            return this.form.services.reduce((amounts, serviceId) => {
                const subtotal = parseFloat(this.form.subtotals['id_' + serviceId]) || 0;
                const iva = parseFloat(this.form.ivas['id_' + serviceId]) || 0;
                const ieps = parseFloat(this.form.iepss['id_' + serviceId]) || 0;
                const isr_ret = parseFloat(this.form.isr_rets['id_' + serviceId]) || 0;
                const iva_ret = parseFloat(this.form.iva_rets['id_' + serviceId]) || 0;
                const discount = parseFloat(this.form.discounts['id_' + serviceId]) || 0;

                amounts['id_' + serviceId] = subtotal + iva + ieps - isr_ret - iva_ret - discount;
                return amounts;
            }, {});
        },
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
    watch: {
        'form.end_date'(val){
            if(this.form.id == ''){
                this.form.forced_deadline = val;
            }
        },
        'form.subtotal'() {
            this.calcTotal();
        },
        'form.discount'() {
            this.calcTotal();
        },
        'form.iva'() {
            this.calcTotal();
        },
        'form.isr_ret'() {
            this.calcTotal();
        },
        'form.iva_ret'() {
            this.calcTotal();
        },
        'form.ieps'() {
            this.calcTotal();
        },
        updatedAmounts: {
            handler(newAmounts) {
                this.form.amounts = newAmounts;
            },
            deep: true,
            immediate: true
        }
        // formulario array
        // 'form.subtotals': {
        //     handler: 'updateAmounts',
        //     deep: true
        // },
        // 'form.ivas'() {
        //     this.updateAmounts();
        // },
        // 'form.iepss'() {
        //     this.updateAmounts();
        // },
        // 'form.isr_rets'() {
        //     this.updateAmounts();
        // },
        // 'form.iva_rets'() {
        //     this.updateAmounts();
        // },
        // 'form.discounts'() {
        //     this.updateAmounts();
        // }

    }
}
</script>
<template>
    <modal v-bind:click-to-close="false" name="addDepartamentUser" :width="'80%'" :scrollable="true" :maxHeight="300"
        :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información del contrato</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 text-right">
                        <label v-for="(currency, index) in catalogue.currencies" :key="index" class="ml-1">
                            {{ currency.name }} <input :disabled="!prop_edit" v-model="form.currency_id"
                                :value="currency.id" type="radio" name="currency">
                        </label>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Propiedad</label><br>
                        <select :disabled="form.id != ''" class="form-control" v-model="form.building_id"
                            @change="get_department()">
                            <option value="">Sin propiedad</option>
                            <option :value="building.id" v-for="(building, building_index) in catalogue.buildings"
                                :key="building_index">
                                {{ building.name }}
                            </option>
                        </select>
                        <div v-if="errors && errors.building_id" class="text-danger">{{ errors.building_id[0] }}</div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <label>Indiviso</label><br>
                        <select :disabled="form.id != ''" class="form-control" v-model="form.department_id">
                            <option value="">Sin departamento</option>
                            <option :value="department.id"
                                v-for="(department, department_index) in catalogue.departamens_buildings"
                                :key="department_index">
                                {{ department.level }} / {{ department.number }}
                            </option>
                        </select>
                        <div v-if="errors && errors.department_id" class="text-danger">{{ errors.department_id[0] }}
                        </div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <label>Usuario</label><br>
                        <select :disabled="form.id != ''" class="form-control" v-model="form.user_id"
                            @change="get_billing_data_user()">
                            <option :value="user.id" v-for="(user, user_index) in catalogue.users_profile"
                                :key="user_index">
                                {{ user.name }} {{ user.last_name }}
                            </option>
                        </select>
                        <div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Datos de Facturación</label>
                        <select :disabled="form.id != ''" type="text" class="form-control" v-model="form.billing_data_id">
                            <option value="">Sin datos de facturación</option>
                            <option v-for="(billD, index) in catalogue.billing_data_user" :value="billD.id"
                                :key="index">{{
        billD.business_name }} - {{ billD.rfc }}</option>
                        </select>
                        <div v-if="errors && errors.billing_data_id" class="text-danger">{{ errors.billing_data_id[0] }}
                        </div>
                    </div>

                    <div class="col-md-3 mt-3">
                        <label>Subtotal</label>
                        <money :disabled="form.id != ''" class="form-control" v-model="form.subtotal"></money>
                        <div v-if="errors && errors.subtotal" class="text-danger">{{ errors.subtotal[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>IVA</label>
                        <money :disabled="form.id != ''" class="form-control" v-model="form.iva"></money>
                        <div v-if="errors && errors.iva" class="text-danger">{{ errors.iva[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>IEPS</label>
                        <money :disabled="form.id != ''" class="form-control" v-model="form.ieps"></money>
                        <div v-if="errors && errors.ieps" class="text-danger">{{ errors.ieps[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>ISR RET</label>
                        <money class="form-control" :disabled="form.id != ''" v-model="form.isr_ret"></money>
                        <div v-if="errors && errors.isr_ret" class="text-danger">{{ errors.isr_ret[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>IVA RET</label>
                        <money class="form-control" :disabled="form.id != ''" v-model="form.iva_ret"></money>
                        <div v-if="errors && errors.iva_ret" class="text-danger">{{ errors.iva_ret[0] }}</div>
                    </div>
                    <div class="col-md-3 mt-3">
                        <label>Descuento</label>
                        <money class="form-control" :disabled="form.id != ''" v-model="form.discount"></money>
                        <div v-if="errors && errors.discount" class="text-danger">{{ errors.discount[0] }}</div>
                    </div>


                    <div class="col-md-6 mt-3">
                        <label>Monto</label>
                        <money readonly :disabled="form.id != ''" class="form-control" v-model="form.amount"></money>
                        <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <label>Recurrencia</label>
                        <select :disabled="form.id != ''" class="form-control" v-model="form.payment_period_id">
                            <option v-for="(payment_period, index) in catalogue.payment_periods"
                                :value="payment_period.id" :key="index">
                                {{ payment_period.name }}</option>
                        </select>
                        <div v-if="errors && errors.payment_period_id" class="text-danger">{{
        errors.payment_period_id[0] }}</div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Fecha inicio</label>
                        <date-picker :disabled="form.id != ''" valueType="format" v-model="form.star_date"></date-picker>
                        <div v-if="errors && errors.star_date" class="text-danger">{{ errors.star_date[0] }}</div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Fecha fin</label>
                        <date-picker :disabled="form.id != ''" valueType="format" v-model="form.end_date"></date-picker>
                        <div v-if="errors && errors.end_date" class="text-danger">{{ errors.end_date[0] }}</div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Tipo de saldo</label>
                        <select type="text" :disabled="form.id != ''" class="form-control" v-model="form.type_payment_id">
                            <option value="">Sin tipo de saldo</option>
                            <option v-for="(type_payment, index_type_payment) in catalogue.type_payments"
                                :value="type_payment.id" :key="index_type_payment">
                                {{ type_payment.name }}
                            </option>
                        </select>
                        <div v-if="errors && errors.type_payment_id" class="text-danger">{{ errors.type_payment_id[0] }}
                        </div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Empresa</label>
                        <select type="text" :disabled="form.id != ''" class="form-control" v-model="form.company_id"
                            @change="getBankCompany">
                            <option value="">Sin Empresa</option>
                            <option v-for="(company, index_company) in catalogue.companies_buildings"
                                :value="company.id" :key="index_company">
                                {{ company.name }}
                            </option>
                        </select>
                        <div v-if="errors && errors.company_id" class="text-danger">{{ errors.company_id[0] }}
                        </div>
                    </div>


                    <div class="col-md-4 mt-3">
                        <label>Cuenta de Banco</label>
                        <select type="text" :disabled="form.id != ''" class="form-control" v-model="form.bank_account_id">
                            <option value="">Sin cuenta de banco</option>
                            <option v-for="(bank, index_bank) in catalogue.bank_account_company" :value="bank.id"
                                :key="index_bank">
                                {{ bank.name }} / {{ bank.number }} <span v-if="bank.bank">- {{ bank.bank.name }}</span>
                            </option>
                        </select>
                        <div v-if="errors && errors.bank_account_id" class="text-danger">{{ errors.bank_account_id[0] }}
                        </div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Deposito de garantía</label>
                        <money :disabled="form.id != ''" class="form-control" v-model="form.deposit"></money>
                        <div v-if="errors && errors.deposit" class="text-danger">{{ errors.deposit[0] }}
                        </div>

                    </div>

                    <div class="col-md-4 mt-3 text-center">
                        <label>Contrato firmado</label>
                        <input type="checkbox" :disabled="!prop_edit" class="form-control form-control-sm"
                            v-model="form.signed_contract">
                        <div v-if="errors && errors.signed_contract" class="text-danger">{{ errors.signed_contract[0] }}
                        </div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Fecha fin de plazo forzoso</label>
                        <date-picker :disabled="form.id != ''" valueType="format"
                            v-model="form.forced_deadline"></date-picker>
                        <div v-if="errors && errors.forced_deadline" class="text-danger">{{ errors.forced_deadline[0] }}
                        </div>
                    </div>

                    <!-- Aumento -->
                    <div class="col-md-12">
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <label>Incremento de renta</label>
                                <div class="row justify-content-center mb-3" v-if="prop_edit ">
                                    <div class="col-md-6">
                                        <button type="button" class="btn btn-block btn-success"
                                            @click="addRentIncrease()">
                                            <i class="fa fa-plus"></i> incremento de renta
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 " v-for="(value, index_value) in form.rent_increase"
                                :value="index_value" :key="index_value">
                                <button class="btn btn-sm btn-danger btnDel" type="button" v-if="prop_edit "
                                    @click="deleteRentIncrease(index_value)"><i class="fa fa-trash"></i> </button>
                                <div class="row divCard m-1">
                                    <div class="col-md-4">
                                        <label>Fecha:</label>
                                        <date-picker :disabled="!prop_edit" valueType="format"
                                            v-model="value.date"></date-picker>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Tipo:</label>
                                        <select type="text" :disabled="!prop_edit" class="form-control"
                                            v-model="value.type">
                                            <option value="">Sin tipo de aumento</option>
                                            <option
                                                v-for="(type_payment, index_type_payment) in catalogue.type_increase"
                                                :value="type_payment.id" :key="index_type_payment">
                                                {{ type_payment.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <label>Porcentaje:</label>
                                        <input class="form-control" :disabled="!prop_edit" v-model="value.value"
                                            type="number" step="any">
                                    </div>
                                    <div class="col-md-2">
                                        <label>Pendiente:</label>
                                        <input class="form-control" :disabled="!prop_edit" v-model="value.check"
                                            type="checkbox" step="any">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="col-md-12 mt-3">
                        <div class="row">
                            <div class="col-12">
                                <hr>
                            </div>
                            <div class="col-12 table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Servicio</th>
                                            <th>Subtotal</th>
                                            <th>IVA</th>
                                            <th>IEPS</th>
                                            <th>ISR RET</th>
                                            <th>IVA RET</th>
                                            <th>Descuento</th>
                                            <th>Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(service, index_service) in catalogue.services" :key="index_service">
                                            <td>
                                                <input :disabled="form.id != ''" type="checkbox" :id="'id-' + service.id"
                                                    class="form-control form-control-sm" v-model="form.services"
                                                    :value="service.id">
                                            </td>
                                            <td>
                                                {{ service.name }}
                                            </td>
                                            <td>
                                                <money v-if="form.services.includes(service.id)" :disabled="form.id != ''"
                                                    class="form-control" v-model="form.subtotals['id_' + service.id]">
                                                </money>
                                            </td>
                                            <td>
                                                <money v-if="form.services.includes(service.id)" :disabled="form.id != ''"
                                                    class="form-control" v-model="form.ivas['id_' + service.id]">
                                                </money>
                                            </td>
                                            <td>
                                                <money v-if="form.services.includes(service.id)" :disabled="form.id != ''"
                                                    class="form-control" v-model="form.iepss['id_' + service.id]">
                                                </money>
                                            </td>
                                            <td>
                                                <money v-if="form.services.includes(service.id)" :disabled="form.id != ''"
                                                    class="form-control" v-model="form.isr_rets['id_' + service.id]">
                                                </money>
                                            </td>
                                            <td>
                                                <money v-if="form.services.includes(service.id)" :disabled="form.id != ''"
                                                    class="form-control" v-model="form.iva_rets['id_' + service.id]">
                                                </money>
                                            </td>
                                            <td>
                                                <money v-if="form.services.includes(service.id)" :disabled="form.id != ''"
                                                    class="form-control" v-model="form.discounts['id_' + service.id]">
                                                </money>
                                            </td>
                                            <td>
                                                <money v-if="form.services.includes(service.id)" :disabled="form.id != ''"
                                                    readonly class="form-control"
                                                    v-model="form.amounts['id_' + service.id]"></money>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-12 text-center">
                                <div v-if="errors && errors.services" class="text-danger">{{ errors.services[0] }}
                                </div>
                            </div>
                            <div class="col-12">
                                <hr>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-12">
                                <label>Título de obligación</label>
                                <div class="row justify-content-center mb-3" v-if="prop_edit ">
                                    <div class="col-md-6">
                                        <button type="button" class="btn btn-block btn-success"
                                            @click="addObligation()">
                                            <i class="fa fa-plus"></i> Título de obligación
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 " v-for="(value, index_value) in form.obligation" :value="index_value"
                                :key="index_value">
                                <button class="btn btn-sm btn-danger btnDel" type="button" v-if="prop_edit"
                                    @click="deleteObligation(index_value)"><i class="fa fa-trash"></i> </button>
                                <div class="row divCard m-1">
                                    <div class="col-12">
                                        <label>Título:</label>
                                        <input class="form-control" :disabled="!prop_edit" v-model="value.title">
                                    </div>
                                    <div class="col-12">
                                        <label>Descripción:</label>
                                        <textarea class="form-control" :disabled="!prop_edit"
                                            v-model="value.rules"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <hr>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 mt-3">
                        <label>Descripción</label>
                        <textarea type="text" :disabled="!prop_edit" class="form-control"
                            v-model="form.description"></textarea>
                        <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}
                        </div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Archivo</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" :disabled="!prop_edit" class="custom-file-input"
                                :class="file != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>

                    <div class="col-12">
                        <hr>
                        <div class="row">
                            <div class="col-12 text-center">
                                <h3>Archivo adjuntos.</h3>
                                <p>Máximo 5 archivos.</p>
                                <div class="row mt-4 mb-4">
                                    <div class="col-12" v-if="(form.files.length + files.length) == 0">
                                        <i class="fa-solid fa-file fa-4x"></i>
                                        <p>Sin archivos adjuntos</p>
                                    </div>
                                    <div class="col-md-4 col-12 p-2" v-for="(fAdd, index_fAdd) in files"
                                        :key="index_fAdd" v-show="files.length > 0">
                                        <div class="card shadow">
                                            <button v-if="prop_edit" class="btn btn-sm btn-danger del" type="button"
                                                @click="delImgUpload(index_fAdd)">
                                                <i class="fa fa-trash"></i></button>
                                            <div class="card-body text-center">
                                                <a :href="`${globalUrlFiles}${fAdd.file}`" target="_blank">
                                                    <i class="fa fa-file fa-4x"></i>
                                                    <p>{{ fAdd.name }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 col-12 p-2" v-for="(img, index_img) in form.files"
                                        :key="index_img" v-show="form.files.length > 0">
                                        <div class="card shadow">
                                            <button v-if="prop_edit" class="btn btn-sm btn-danger del" type="button"
                                                @click="delImg(index_img)"><i class="fa fa-trash"></i></button>
                                            <div class="card-body text-center">
                                                <a :href="createURL(img)" target="_blank">
                                                    <i class="fa fa-file fa-4x"></i>
                                                    <p>{{ img.name }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="input-group mb-3"
                                    v-if="(form.files.length + files.length) <= 5 && prop_edit">
                                    <div class="custom-file" @change="uploadFilesBill">
                                        <input type="file" class="custom-file-input" id="inputGroupFile03"
                                            aria-describedby="inputGroupFileAddon03">
                                        <label class="custom-file-label" for="inputGroupFile03">
                                            Subir Archivo
                                        </label>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>


                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<style>
.divCard {
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 10px;
}

.btnDel {
    position: absolute;
    right: 0px;
    top: -5px;
}

.image-upload>input {
    display: none;
}

.del {
    position: absolute;
    right: 0px;
    margin: 10px;
}
</style>