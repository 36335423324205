<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';


export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            id: '',
            pay: {},
            load: false,
        }
    },
    methods: {
        closedModal() {
            this.$modal.hide('tablePays');
        },
        getServiceAmounts(services = [], sumArray = []) {
            let sum = 0;

            if (services) {
                services.forEach((element) => {
                    if (element.negative == 1) {
                        sum -= element.amount;
                    } else {
                        sum += element.amount;
                    }
                });
            }

            if (sumArray) {
                sumArray.forEach((element) => {
                    sum += parseFloat(element);
                });
            }

            return sum;

        },
        async formDataPaysFast(pay) {
            try {
                this.$swal({
                    title: 'Se realizo el pago',
                    text: '¿ Se aprobara el pago ?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Pagado',
                    cancelButtonText: 'Cancelar'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        let form = {
                            tenant_id: pay.id,
                            bank_id: pay.bank_account_id,
                            date: this.moment().format("YYYY-MM-DD"),
                            amount: pay.amount,
                            type_payment_id: pay.type_payment_id,
                            description: pay.description
                        };
                        let _endpoint = '/tenant_pay';

                        let { data } = await request.postMedia(_endpoint, form)
                        if (data) {
                            this.successNotification(data.message);
                            this.list();
                        }
                    }
                });
            } catch (error) {
                this.$modal.hide('LoadPay');
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },

        async list() {
            this.load = true;
            let service = request;
            let _endpoint = `/tenant/${this.id}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.pay = data;
                    this.load = false;
                }
            } catch (error) {
                console.log(error);
                this.load = false;
            }
        },
        getPays(checks) {
            let result = 0;
            if (checks) {
                checks.forEach((check) => {
                    check.pay.forEach((pay) => {
                        result += parseFloat(pay.amount);
                    });
                });
            }
            return result;
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-tablePays", (pay) => {
            this.id = pay.id;
            this.list();
            this.$modal.show('tablePays');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }
}
</script>
<template>
    <modal v-bind:click-to-close="false" name="tablePays" :width="'80%'" :scrollable="true" :maxHeight="300"
        :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información pago de arrendatario</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 table-responsive" v-if="pay">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Fecha</th>
                                    <th>Monto</th>
                                    <th>Servicios y extras</th>
                                    <th>A pagar</th>
                                    <th>Pagado</th>
                                    <th>Por pagar</th>
                                    <!-- <th></th> -->
                                </tr>
                            </thead>
                            <tbody v-if="load">
                                <tr>
                                    <td align="center" colspan="6">
                                        <i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
                                        <h5>Cargando</h5>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td> {{ pay.id }}</td>
                                    <td> {{ pay.star_date | toDateTime }}</td>
                                    <td> {{ pay.amount | toCurrency }}</td>
                                    <td> {{ getServiceAmounts(pay.all_services) | toCurrency }}</td>
                                    <td> {{ getServiceAmounts(pay.all_services, [pay.amount]) | toCurrency }}</td>
                                    <td> {{ getPays(pay.checks) | toCurrency }}</td>
                                    <td> {{ getServiceAmounts(pay.all_services, [pay.amount]) - getPays(pay.checks) | toCurrency }}</td>
                                    <!-- <td>
                                        <div v-if="pay.checks.length > 0">
                                            <p class="m-0">{{ pay.checks[0].date | toDateTime }}</p>
                                            <p class="m-0">{{ pay.checks[0].description }}</p>
                                        </div>

                                    </td> -->
                                </tr>
                                <tr v-for="(pay_child, index) in pay.tenants" :key="index">
                                    <td> {{ pay_child.id }}</td>
                                    <td> {{ pay_child.star_date | toDateTime }}</td>
                                    <td> {{ pay_child.amount | toCurrency }}</td>

                                    <td> {{ getServiceAmounts(pay_child.all_services) | toCurrency }}</td>
                                    <td> {{ getServiceAmounts(pay_child.all_services, [pay_child.amount]) | toCurrency }}</td>
                                    <td> {{ getPays(pay_child.checks) | toCurrency }}</td>
                                    <td> {{ getServiceAmounts(pay_child.all_services, [pay.amount]) - getPays(pay_child.checks) | toCurrency }}</td>
                                    
                                    <!-- <td class="text-center">

                                        <div v-if="pay_child.checks.length > 0">
                                            <p class="m-0">{{ pay_child.checks[0].date | toDateTime }}</p>
                                            <p class="m-0">{{ pay_child.checks[0].description }}</p>
                                        </div>

                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>