<template>
	<div class="col-12 ">
		<form @submit.prevent="triggerSave">
			<div class="row">
				<div class="col-md-4">
					<div class="card card-outline card-navy" style="height:95%;">
						<div class="card-body">
							<div class="row">
								<div class="col-12 text-center">
									<h3 class="mb-4">Imagen de perfil</h3>
									<img class="img-fluid rounded" style="width: 200px;" :src="img_perfil" alt="">
									<br>
									<br>
									<input type="file" id="file" class="inputfile" @change="csv_file">
									<label for="file"><i class="fas fa-upload"></i> Subir imagen </label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-8">
					<div class="card card-outline card-navy">
						<div class="card-body">
							<div class="row">
								<div class="col-md-4">
									<div class="form-group">
										<label> Nombre </label>
										<input type="text" v-model="form.name" class="form-control">
										<div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label> Apellidos </label>
										<input type="text" v-model="form.last_name" class="form-control">
										<div v-if="errors && errors.last_name" class="text-danger">{{ errors.last_name[0] }}
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label> Perfil </label>
										<select disabled v-model="form.organization_charts_id" class="form-control">
											<option value="null"> Sin Perfil </option>
											<option :value="profile.id" v-for="(profile, index) in profiles" :key="index">
												<span v-if="profile.profil">{{ profile.profil.name }}</span>
												<span v-if="profile.area">- {{ profile.area.name }}</span>
											</option>
										</select>
										<div v-if="errors && errors.organization_charts_id" class="text-danger">{{
											errors.organization_charts_id[0] }}</div>
									</div>
								</div>

								<div class="col-md-4">
									<div class="form-group">
										<label> Email </label>
										<input type="mail" v-model="form.email" class="form-control">
										<div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label> Teléfono </label>
										<input type="text" v-model="form.phone" class="form-control">
										<div v-if="errors && errors.phone" class="text-danger">{{ errors.phone[0] }}</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label> WhatsApp </label>
										<input type="text" v-model="form.whathsapp" class="form-control">
										<div v-if="errors && errors.whathsapp" class="text-danger">{{ errors.whathsapp[0] }}
										</div>
									</div>
								</div>

								<div class="col-md-12" v-if="form.organization_charts_id == 2">
									<div class="form-group">
										<label> Web </label>
										<input type="text" v-model="form.web" class="form-control">
										<div v-if="errors && errors.web" class="text-danger">{{ errors.web[0] }}</div>
									</div>
								</div>

								<div class="col-12">
									<hr>
								</div>

								<div class="col-md-12 mt-2">
									<p class="text-info">Dejar en blanco si no quiere cambiar la contraseña</p>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label> Contraseña </label>
										<div class="input-group">
											<input :type="type1" v-model="form.password" class="form-control">
											<div class="input-group-prepend">
												<div class="btn btn-primary"
													@click="type1 = type1 == 'password' ? 'text' : 'password'"
													style="border-radius: 0px 10px 10px 0px !important;">
													<i v-if="type1 == 'password'" class="fa-solid fa-eye"></i>
													<i v-else class="fa-solid fa-eye-slash"></i>
												</div>
											</div>
										</div>
										<div v-if="errors && errors.password" class="text-danger">{{
											errors.password[0] }}</div>
									</div>
								</div>

								<div class="col-md-6">
									<div class="form-group">
										<label> Confirmar contraseña </label>
										<div class="input-group">
											<input :type="type2" v-model="form.password_confirmation" class="form-control">
											<div class="input-group-prepend">
												<div class="btn btn-primary"
													@click="type2 = type2 == 'password' ? 'text' : 'password'"
													style="border-radius: 0px 10px 10px 0px !important;">
													<i v-if="type2 == 'password'" class="fa-solid fa-eye"></i>
													<i v-else class="fa-solid fa-eye-slash"></i>
												</div>
											</div>
										</div>
										<div v-if="errors && errors.password_confirmation" class="text-danger">{{
											errors.password_confirmation[0] }}</div>
									</div>
								</div>


							</div>

						</div>
					</div>
				</div>
				<div class="col-md-12">
					<button type="submit" class="btn float-right btn-success">
						<i class="fas fa-save"></i> Guardar
					</button>
					<router-link to="/users" class="btn float-left btn-warning">
						<i class="fas fa-undo-alt"></i>
						Regresar
					</router-link>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import request from '@/services/request'
import { mapActions, mapGetters } from 'vuex';

import Catalogue from '@/services/catalogue';


export default {
	name: "users-table",
	data() {
		return {
			form: {
				id: '',
				name: '',
				last_name: '',
				img_perfil: '',
				phone: '',
				whathsapp: '',
				organization_charts_id: '',
				email: '',
				birthday: '',
				admission_date: '',
				user_id: '',
				cedula: '',
				business_name: '',
				rfc: '',
				compliance_opinion: '',
				fiscal_address: '',
				password: '',
				password_confirmation: '',
				companies: []
			},
			companies: {},
			cedularName: null,
			file_cedula: '',
			complianceName: null,
			file_compliance: '',
			showUsers: false,
			area: '',
			profiles: [],
			img_perfil: require('@/assets/img/profile.png'),
			errors: {},
			action: '/admin/user',
			catalogue: new Catalogue(),
			option: {
				penColor: "rgb(0, 0, 0)",
				backgroundColor: "rgb(255,255,255)"
			},
			type1: 'password',
			type2: 'password'
		}
	},
	mounted() {
		this.getProfiles();
		this.listCompanies();
		let id = this.authUser.id;
		if (id) {
			this.get_id(id);
		}
	},
	methods: {
		async listCompanies(page = 1) {
			let search = {};
			let _endpoint = '/companies';
			try {
				let { data } = await request.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.companies = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		csv_file(event) {
			console.log(event.target.files[0].type);
			if (event.target.files[0].type == "image/png" ||
				event.target.files[0].type == "image/jpeg") {
				this.form.img_perfil = event.target.files[0];
				this.img_perfil = URL.createObjectURL(event.target.files[0]);
			} else {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos PNG , JPGE',
				});
			}
		},
		cedula_file(event) {
			console.log(event.target.files[0].type);
			console.log(event.target.files[0].name);
			if (event.target.files[0].type == "application/pdf") {
				this.form.cedula = event.target.files[0];
				this.cedularName = event.target.files[0].name;
			} else {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos PDF',
				});
			}
		},
		compliance_file(event) {
			if (event.target.files[0].type == "application/pdf") {
				this.form.compliance_opinion = event.target.files[0];
				this.complianceName = event.target.files[0].name;
			} else {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos PDF',
				});
			}
		},
		async triggerSave() {

			const data_form = new FormData();
			data_form.append('id', this.form.id);
			data_form.append('name', this.form.name);
			data_form.append('last_name', this.form.last_name);
			data_form.append('email', this.form.email);
			data_form.append('phone', this.form.phone);
			data_form.append('organization_charts_id', this.form.organization_charts_id);
			data_form.append('img_perfil', this.form.img_perfil);
			data_form.append('whathsapp', this.form.whathsapp);
			data_form.append('birthday', this.form.birthday);
			data_form.append('admission_date', this.form.admission_date);
			data_form.append('user_id', this.form.user_id);
			data_form.append('cedula', this.form.cedula);
			data_form.append('web', this.form.web);
			data_form.append('companies', this.form.companies);
			data_form.append('business_name', this.form.business_name);
			data_form.append('rfc', this.form.rfc);
			data_form.append('compliance_opinion', this.form.compliance_opinion);
			data_form.append('fiscal_address', this.form.fiscal_address);
			data_form.append('password', this.form.password);
			data_form.append('password_confirmation', this.form.password_confirmation);


			let _endpoint = '/users';
			if (this.form.id != "") {
				data_form.append('_method', 'PUT');
				_endpoint = `/users/${this.form.id}`;
			}

			try {
				let { data } = await request.postMedia(_endpoint, data_form)
				if (data) {
					console.log(data);
					// this.users = data.data;
					this.successNotification(data.message);
					this.$router.push('/');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					let info_error = await this.errorNotification(error);
					if (info_error == 'Logout') {
						this.$nextTick(() => {
							location.reload()
						})
					} else {
						this.errors = info_error;
					}
				}
			}
		},
		async get_id(id) {
			let _endpoint = `/users/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.form.id = id;
					this.form.name = data.name;
					this.form.last_name = data.last_name;
					this.form.img_perfil = data.img_perfil;
					this.form.phone = data.phone;
					this.form.whathsapp = data.whathsapp;
					this.form.email = data.email;
					this.form.organization_charts_id = data.organization_charts_id;
					this.form.birthday = data.birthday;
					this.form.admission_date = data.admission_date;
					this.form.user_id = data.user_id;
					this.form.cedula = data.cedula;
					this.form.web = data.web;
					this.form.business_name = data.business_name;
					this.form.rfc = data.rfc;
					this.form.fiscal_address = data.fiscal_address;

					if (data.profile_picture) {
						this.img_perfil = this.globalUrlFiles + data.profile_picture;
					}
					if (data.cedula) {
						this.file_cedula = this.globalUrlFiles + data.cedula;
					}
					if (data.compliance_opinion) {
						this.file_compliance = this.globalUrlFiles + data.compliance_opinion;
					}

					let companies = [];
					data.companies.forEach(function (valor) {
						companies.push(valor.id);
					});

					this.form.companies = companies;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async getProfiles() {
			let service = request;
			let _endpoint = '/catalogue/profiles';
			try {
				let { data } = await service.get(_endpoint)
				if (data) {
					this.profiles = data;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.mx-datepicker {
	width: 100%;
}

.fs-6 {
	font-size: 12px;
}

.custom-file-label::after {
	content: "Archivo";
}
</style>
