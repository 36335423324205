<template>
	<div class="row bg align-items-center justify-content-center">
		<div class="login-box">
			<div class="card card-outline" style="background-color: #efefef;">
				<div class="card-body mb-4">
					<div class="col-12 text-center mb-4">
						<img src="@/assets/img/logo.png" class="mt-3 mb-4" alt="Logo" width="150px;"><br>
						<h5 class="login-box-msg mt-n2 text-secondary">Modificar contraseña</h5>
						<p class="mt-n3">Ingresa los datos para recuperar <br>tu contraseña</p>
					</div>				
					<form @submit.prevent="triggerChangePassword">
						<div class="col-12 mb-3">
							<div class="input-group ">								
								<input type="text" v-model="form.email" name="email" class="form-control"
									placeholder="Correo" :class="{ 'is-invalid': submitted && !form.email }" />
							</div>
							<div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
						</div>
						<div class="col-12">
							<div class="input-group" style="margin-bottom:6px !important;">	
								<input type="password" v-model="form.password" name="password" class="form-control"
									placeholder="Contraseña" :class="{ 'is-invalid': submitted && !errors.password }" />
							</div>
							<div v-if="errors && errors.password" class="text-danger">{{ errors.password[0] }}</div>
						</div>
						<div class="form-group text-center mt-3">
							<button class="btn btn-secondary" style="width: 200px;" :disabled="submitted">
								Modificar <i class="fa fa-lock float-right mt-1" aria-hidden="true"></i>
							</button>
						</div>
						<div class="col-md-12 text-center mt-3">
							<router-link to="/login" href="">Iniciar sesión</router-link>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
	},
	data() {
		return {
			form: {
				passwordToken: '',
				email: '',
				password: '',
			},
			errors: {},
			submitted: false
		}
	},
	methods: {
		async triggerChangePassword() {
			try {
				let reset = await this.changePassword(this.form)
				if (reset) {
					this.successNotification(reset.message);
					this.$router.push('/login')
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			changePassword: 'auth/changepass',
			errorNotification: 'alert/error',
			errorNotificationMessage: 'alert/errorMessage',
			successNotification: 'alert/success',
		})
	},
	created() {
		let tkn =this.$route.query.token;
		console.log(tkn);
		if (tkn) {
			this.form.passwordToken = tkn;
		} else {
			this.errorNotificationMessage('Comprueba la URL para modificar la contraseña.');
			// this.$router.push('/login')
		}
	},
};
</script>
