<script>
    import { mapActions, mapGetters } from 'vuex';
    import request from '@/services/request'
    import Catalogue from '@/services/catalogue';
    
    export default {
        props: {
            prop_edit: {
                default: true,
            }
        },   
        data() {
            return {
                catalogue: new Catalogue(),
                errors: {},
                form: {
                    id: '',     
                    pdf: '',
                    xml: '',         
                },
                pdf: '',
                filePdf: '',
                xml: '',
                xmlName: '',
                send: false,
                load: false,
            }
        },
        methods: {
            closedModal() {
                this.form.id = '';
                this.form.pdf = '';          
                this.pdf = '';
                this.filePdf = '';
                this.form.xml = '';          
                this.xml = '';
                this.xmlName = '';
                this.send = false;
                this.load = false;
    
                this.$modal.hide('addUploadFile');
    
            },
            catch_file(event) {
                if (event.target.files[0].type == "application/pdf") {
                    this.form.pdf = event.target.files[0];
                    this.filePdf = event.target.files[0].name;
                } else {
                    this.$swal({
                        icon: 'error',
                        title: 'Error tipo de archivo',
                        text: 'Solo se puede cargar archivos PDF',
                    });
                }
            },
            catch_xml(event) {
                console.log(event.target.files[0].type);
                if (event.target.files[0].type == "text/xml") {
                    this.form.xml = event.target.files[0];
                    this.xmlName = event.target.files[0].name;
                    this.catchDataXML(event.target.files[0]);
    
    
                } else {
                    this.$swal({
                        icon: 'error',
                        title: 'Error tipo de archivo',
                        text: 'Solo se puede cargar archivos XML',
                    });
                }
            },
    
            async savePayTaxes() {
                const data_form = new FormData();
                data_form.append('id', this.form.id)           
                data_form.append('pdf', this.form.pdf)
                data_form.append('xml', this.form.xml)
    
                let _endpoint = `/tenant_pay_files`;
                    
                try {
                    let { data } = await request.postMedia(_endpoint, data_form)
                    if (data) {
                        this.successNotification(data.message);
                        this.closedModal();
                        Event.$emit('event-show-ReloadPayContracts');
                    }
                } catch (error) {
                    this.errors = {};
                    if (error.response) {
                        this.errors = await this.errorNotification(error);
                    }
                }
    
            },
            ...mapActions({
                errorNotification: 'alert/error',
                successNotification: 'alert/success',
            }),
        },
        created() {
            Event.$on("event-show-addUploadFiles", async (tax) => {
                
                console.log('tax')
                console.log(tax)
    
                if (tax.id) {
                    this.form.id = tax.id;
                    if (tax.pdf) {
                        this.pdf = tax.pdf;
                    }
                    if (tax.xml) {                    
                        this.xml = tax.xml;
                    }
                }
    
                
                this.$modal.show('addUploadFile');
    
            })
        },
        computed: {
            ...mapGetters({
                'authUser': 'auth/user'
            })
        },
    }
</script>
<template>
    <modal name="addUploadFile" :width="'80%'" :maxHeight="300" :height="'auto'" v-bind:click-to-close="false">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="savePayTaxes" method="post" id="formTax" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información de pago</h5>
            </div>
            <div class="modal-body">
                <div class="row">



                    <div class="col-md-12 mt-3">
                        <label>PDF</label>
                        <br>
                        <div class="custom-file" :style="pdf != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" :disabled="!prop_edit" class="custom-file-input" :class="pdf != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ filePdf }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${pdf}`" v-if="pdf != ''" target="_blank" style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.pdf" class="text-danger">{{ errors.pdf[0] }}</div>

                    </div>

                    <div class="col-md-12 mt-3">
                        <label>XML</label>
                        <br>
                        <div class="custom-file" :style="xml != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" :disabled="!prop_edit" class="custom-file-input" :class="xml != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_xml">
                            <label class="custom-file-label text-left" for="customFile">{{ xmlName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${xml}`" v-if="xml != ''" target="_blank" style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.xml" class="text-danger">{{ errors.xml[0] }}</div>

                    </div>



                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i> Regresar
                        </button>
                    </div>

                </div>
            </div>
        </form>
    </modal>
</template>