<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                comment_approved: '',
                photo: [],
            },
            edit : false,
            photo: [],
            send: false,
            load: false,
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.comment_approved = '';
            this.form.photo = [];
            this.photo = [];
            this.send = false;
            this.errors = {};
            this.$modal.hide('modalBillApprove');
        },
        uploadFiles(event) {
            console.log(event.target.files[0]);
            this.form.photo.push(event.target.files[0]);
        },
        createURL(file) {
            let link = URL.createObjectURL(file);
            return link;
        },
        async delImgUpload(pos) {
            let img = this.photo[pos];
            let id = img.id;
            let _endpoint = '/bills/deleted-approve-file';
            let form = {
                id: id
            };
            let { data } = await request.postMedia(_endpoint, form)
            if (data) {
                this.successNotification(data.message);
                this.photo.splice(pos, 1);
            }

        },
        delImg(pos) {
            this.form.photo.splice(pos, 1);
            this.successNotification('El archivo se elimino correctamente.');
        },
        async saveBuildings() {
            this.send = true;
            const data_form = new FormData();
            data_form.append('id', this.form.id);
            if (this.form.comment_approved) {
                data_form.append('comment_approved', this.form.comment_approved);
            }

            if (this.form.photo) {
                this.form.photo.forEach(function (valor, index) {
                    data_form.append('photo[' + index + ']', valor);
                });
            }

            let _endpoint = '/bills_approve';

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-bills');
                }
            } catch (error) {
                this.send = false;
                this.errors = {};
                if (error.response) {

                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    watch: {

    },
    created() {
        Event.$on("event-show-addBillApprove", (bill, edit) => {
            console.log('bill');
            console.log(bill);
            console.log(edit);
            if (bill.id) {
                this.load = true;
                this.form.id = bill.id;
                this.form.comment_approved = bill.comment_approved;
                if(bill.files_approve){
                    this.photo = bill.files_approve;
                }
            }
            this.edit = edit;
            
            this.$modal.show('modalBillApprove');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>
<template>
    <modal name="modalBillApprove" :width="'80%'" :maxHeight="300" :height="'auto'" v-bind:click-to-close="false">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información para aprobar factura</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 mt-3">
                        <label>Comentario</label>
                        <textarea :disabled="!prop_edit || !edit" type="text" class="form-control"
                            v-model="form.comment_approved"></textarea>
                        <div v-if="errors && errors.comment_approved" class="text-danger">{{ errors.comment_approved[0] }}
                        </div>
                    </div>

                    <div class="col-12">
                        <hr>
                        <div class="row">
                            <div class="col-12 text-center">
                                <h3>Evidencia adjunta.</h3>
                                <p>Maximo 5 archivos.</p>
                                <div class="row mt-5 mb-5">
                                    <div class="col-12" v-if="(form.photo.length + photo.length) == 0">
                                        <i class="fa-solid fa-file fa-4x"></i>
                                        <p>Sin archivos adjuntos</p>
                                    </div>
                                    <div class="col-md-4 col-12 p-2" v-for="(fAdd, index_fAdd) in photo" :key="index_fAdd"
                                        v-show="photo.length > 0">
                                        <div class="card shadow">
                                            <button v-if="prop_edit && edit " class="btn btn-sm btn-danger del" type="button"
                                                @click="delImgUpload(index_fAdd)">
                                                <i class="fa fa-trash"></i></button>
                                            <div class="card-body text-center">
                                                <a :href="`${globalUrlFiles}${fAdd.file}`" target="_blank">
                                                    <i class="fa fa-file fa-4x"></i>
                                                    <p>{{ fAdd.name }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-12 p-2" v-for="(img, index_img) in form.photo" :key="index_img"
                                        v-show="form.photo.length > 0">
                                        <div class="card shadow">
                                            <button v-if="prop_edit  && edit" class="btn btn-sm btn-danger del" type="button"
                                                @click="delImg(index_img)"><i class="fa fa-trash"></i></button>
                                            <div class="card-body text-center">
                                                <a :href="createURL(img)" target="_blank">
                                                    <i class="fa fa-file fa-4x"></i>
                                                    <p>{{ img.name }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="image-upload btn btn-outline-secondary btn-lg"
                                    v-if="(form.photo.length + photo.length) <= 4 && (prop_edit  && edit)">
                                    <label for="file-input" class="m-0">
                                        <h4 class="m-0"> <i class="fa-solid fa-file fa-1x mr-2"></i>
                                            Subir
                                            Archivo</h4>
                                    </label>
                                    <input id="file-input" type="file" @change="uploadFiles" />
                                </div>
                                <div v-if="errors && errors.photo" class="text-danger mt-2">{{ errors.photo[0] }}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit  && edit" :disabled="send" type="submit" class="btn float-right btn-outline-success">
                            <span v-if="send">
                                <i class="fa-solid fa-circle-notch fa-spin"></i> Guardando
                            </span>
                            <span v-else>
                                <i class="fas fa-save"></i> Guardar
                            </span>
                        </button>
                        <button type="button" :disabled="send" @click="closedModal()"
                            class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<style scoped>
.image-upload>input {
    display: none;
}

.del {
    position: absolute;
    right: 0px;
    margin: 10px;
}
</style>