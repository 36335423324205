<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Presupuestos</h3>
				<div class="card-tools">
					<button class="btn btn-outline-success btn-sm mr-2" @click="exportData()">
						<i class="fa fa-file-excel"></i> Exportar
					</button>
					<router-link :to="`/budgets/form`" v-can="'add_budgets'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Presupuesto
					</router-link>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<label>Buscar :</label>
						<input class="form-control" v-debounce:1s="list" v-model="name">
					</div>
					<div class="col-md-6">
						<label>Propiedades :</label>
						<select type="text" class="form-control" v-model="search.building_id">
							<option value="">Todos</option>
							<option v-for="(building, index) in catalogue.buildings" :value="building.id" :key="index">
								{{ building.name }}</option>
						</select>
					</div>
					<div class="col-md-6">
						<label>Empresa :</label>
						<select v-model="search.company_id" class="form-control">
							<option value="">Todos</option>
							<option v-for="(company, index) in catalogue.companies_buildings" :key="index"
								:value="company.id"> {{ company.name }}
							</option>
						</select>
					</div>
					<div class="col-md-6" v-if="authUser.organization_charts_id != 2" >
						<label>Usuario :</label>
						<select type="text" class="form-control" v-model="search.user_id">
							<option value="">Todos</option>
							<option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">
								{{ user.name }} {{ user.last_name }}</option>
						</select>
					</div>
					<div class="col-md-6">
						<label>Categoría :</label>
						<select type="text" class="form-control" v-model="search.concept_id">
							<option value="">Todos</option>
							<option v-for="(concept, index) in catalogue.concepts" :value="concept.id" :key="index">
								{{ concept.name }}</option>
						</select>
					</div>
					<div class="col-md-6">
						<button class="btn mt-4 btn-block btn-outline-secondary" :class="search.requires_contract ? 'active' : '' " @click="search.requires_contract = search.requires_contract ? '' : 1">
							<i class="fa-solid fa-triangle-exclamation text-warning fa-fade"></i> - Requiere Contrato
						</button>
					</div>

				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th style="width: 40%;">Datos del presupuesto</th>
								<th style="width: 140px;">Monto</th>
								<th style="width: 140px;">Pagado</th>
								<th style="width: 140px;">Resta</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="6">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="budgets.total > 0">
							<tr :class="buy.authorization_user_id ? 'bg-info' : ''" v-for="(buy, index) in budgets.data"
								:key="index">
								<td>
									<i v-if="buy.require_contract != 1 && !buy.contract " class="fa-solid fa-triangle-exclamation text-warning fa-fade"></i>
									<br>
									{{ buy.id }}
								</td>
								<td class="text-left">
									<!-- Propiedad -->
									<strong>Propiedad:</strong>
									<span v-if="buy.building"> {{ buy.building.name }}</span>
									<span v-else>N/A</span>
									<br>
									<!-- Indiviso -->
									<strong>Indiviso:</strong>
									<span v-if="buy.departament"> Número {{ buy.departament.number }} / Piso {{
						buy.departament.level }}</span>
									<span v-else>N/A</span>
									<br>
									<!-- Empresa -->
									<strong>Empresa:</strong>
									<span v-if="buy.company"> {{ buy.company.name }} </span>
									<span v-else>N/A</span>
									<br>
									<!-- Proveedor -->
									<strong>Usuario:</strong>
									<span v-if="buy.user"> {{ buy.user.name }} {{ buy.user.last_name }}</span>
									<span v-else>Sin usuario</span>
									<br>
									<!-- Requiere contrato -->
									<strong>Requiere Contrato:</strong>
									<span v-if="buy.require_contract == 1"> No</span>
									<span v-else>Si</span>
									<br>

									<button @click="showBudget(buy.id)" type="button"
										class="btn btn-sm  m-1 btn-outline-secondary ">
										<i class="fa-solid fa-eye"></i> Ver
									</button>
									<router-link :to="`/budgets/form/${buy.id}`" v-can="'edit_budgets'"
										class="btn btn-outline-primary m-1 btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</router-link>
									<button type="button" @click="deleted(buy.id)" v-can="'deleted_budgets'"
										class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>
								</td>
								<td class="text-right">
									{{ buy.amount | toCurrency }}
								</td>
								<td class="text-right">
									{{ paysCheks(buy.checks) + buy.prepaid | toCurrency }}
								</td>
								<td class="text-right">
									{{ buy.amount - (paysCheks(buy.checks) + buy.prepaid) | toCurrency }}
								</td>
								<td>

									<button type="button" @click="approve(buy.id)" v-if="!buy.authorization_user_id"
										v-can="'approve_budgets'" class="btn-outline-warning btn m-1 btn-sm ">
										<i class="fa fa-check"></i> Aprobar
									</button>
									<button type="button" v-if="buy.checks.length > 0" @click="showCheck(buy.checks)"
										class="btn m-1 btn-outline-secondary btn btn-sm ">
										<i class="fa-solid fa-hand-holding-dollar"></i> Pagos
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="6">Sin resultados.</td>
							</tr>
						</tbody>
						<tfoot v-if="budgets.total > 0 && load == false">
							<tr class="table-secondary">
								<td colspan="2">
									Total :
								</td>
								<td class="text-right">
									{{ sumdata()[0] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[1] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[2] | toCurrency }}
								</td>
								<td></td>
							</tr>
						</tfoot>
					</table>
					<pagination v-model="page" :records="budgets.total" :per-page="budgets.per_page" @paginate="list" />
				</div>
			</div>
		</div>

		<ModalTableComponent />
		<ModalFormComponentBudgets :prop_edit="false" />

	</div>
</template>

<script>
import ModalTableComponent from '@/components/checks/ModalTableComponent.vue';

import { mapMutations, mapActions, mapGetters } from 'vuex';
import Pagination from 'vue-pagination-2';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';
import ModalFormComponentBudgets from '@/components/budgets/ModalTableComponent.vue'

export default {
	name: "companies-table",
	components: {
		ModalTableComponent,
		ModalFormComponentBudgets,
		Pagination
	},
	data() {
		return {
			name: '',
			budgets: {},
			search: {
				search: '',
				building_id: '',
				company_id: '',
				user_id: '',
				concept_id: '',
				requires_contract: '',
			},
			page: 1,
			catalogue: new Catalogue(),
			load: false,
		}
	},
	mounted() {
		this.list();
		this.catalogue.get_buildings();
		this.catalogue.get_companies_buildings();
		this.catalogue.get_users_profile(2);
		this.catalogue.get_concepts();
		this.setHeaderTitle('Presupuestos');
	},
	created() {
		Event.$on("event-load-companies", () => {
			this.list();
		});
	},
	methods: {
		sumdata() {
			let vm = this;
			let sum1 = 0;
			let sum2 = 0;
			let sum3 = 0;
			if (this.budgets) {
				this.budgets.data.forEach(function (buy) {

					sum1 += parseFloat(buy.amount);

					sum2 += vm.paysCheks(buy.checks) + buy.prepaid;

					sum3 += parseFloat(buy.amount) - (vm.paysCheks(buy.checks) + buy.prepaid);


				});
			}

			return [sum1, sum2, sum3];
		},
		showBudget(id) {
			Event.$emit('event-load-budget', id);
		},
		exportData() {
			let filter = this.globalUrlFiles + '/budgets/export?' + new URLSearchParams(this.search).toString();
			filter += '&user_login=' + this.authUser.id;
			window.open(filter, '_blank');
		},
		showCheck(checks) {
			Event.$emit('event-show-listChecks', checks);
		},
		paysCheks(checks) {
			console.log('aaaaaaaaaaaa');

			let sum = 0;
			checks.forEach((s) => {
				console.log(s);
				console.log(s.pay);
				if (s.pay.length > 0) {
					s.pay.forEach((b) => {
						console.log(b);
						console.log(b.amount);
						if (b.amount) {
							sum += parseFloat(b.amount);
						}
					});
				}
			});
			return sum;
		},
		deleted: function (id) {
			let tit = 'Eliminar Presupuestos';
			let txt = 'Seguro que quiere eliminar el presupuestos';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		approve: function (id) {
			let tit = 'Aprobar Presupuestos';
			let txt = 'Seguro que quiere aprobar el presupuestos';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let _endpoint = '/budgets_approve';
					let form = {
						id: id
					};
					try {
						let response = await request.post(_endpoint, form).then(function (response) { return response; });
						this.list();
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/budgets/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list(page = 1) {
			this.load = true;
			let service = request;
			let search = this.search;
			search.search = this.name;
			let _endpoint = '/budgets';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.budgets = data;
					this.load = false;
				}
			} catch (error) {
				console.log(error);
				this.load = false;
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	watch: {
		'search': {
			handler: function () {
				this.list();
			},
			deep: true
		}
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.bg-success {
	background-color: #28a745 !important;
}
</style>
