<script>
import { mapActions } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            form: {
                id: '',
                bill_id: '',
                user_id: '',
                amount: '',
                description: '',
                pdf: '',
                xml: '',
            },
            pdf: '',
            pdfName: '',
            xml: '',
            xmlName: '',
            catalogue: new Catalogue(),
            errors: {},
            send: false
        }
    },
    methods: {
        async saveBuildings() {
            const data_form = new FormData();
            data_form.append('id', this.form.id);
            data_form.append('bill_id', this.form.bill_id);
            data_form.append('user_id', this.form.user_id);
            data_form.append('amount', this.form.amount);
            data_form.append('description', this.form.description);
            data_form.append('pdf', this.form.pdf);
            data_form.append('xml', this.form.xml);
            data_form.append('_method', 'POST');

            let _endpoint = '/credit_bill';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/credit_bill/${this.form.id}`;
            }
            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-bills');
                }
            } catch (error) {
                this.send = false;
                this.errors = {};
                if (error.response) {

                    this.errors = await this.errorNotification(error);
                }
            }
        },
        catch_pdf(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.pdf = event.target.files[0];
                this.pdfName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        catch_xml(event) {
            console.log(event.target.files[0].type);
            if (event.target.files[0].type == "text/xml") {
                this.form.xml = event.target.files[0];
                this.xmlName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos XML',
                });
            }
        },
        closedModal() {
            this.form.id = '';
            this.form.bill_id = '';
            this.form.user_id = '';
            this.form.amount = '';
            this.form.description = '';

            this.form.pdf = '';
            this.form.xml = '';
            this.pdfName = '';
            this.xmlName = '';
            this.pdf = '';
            this.xml = '';
            this.form._method = 'POST';
            this.$modal.hide('addBillCredit');
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    created() {
        Event.$on("event-show-addBillCredit", (bill, credit = {}) => {
            if (bill) {
                this.form.bill_id = bill.id;
                this.form.user_id = bill.user_id;
            }
            if (credit) {
                this.form.id = credit.id;
                this.form.bill_id = credit.bill_id;
                this.form.user_id = credit.user_id;
                this.form.amount = credit.amount;
                this.form.description = credit.description;

                if (credit.xml) {
                    this.xml = credit.xml;
                }
                if (credit.pdf) {
                    this.pdf = credit.pdf;
                }
            }
            this.$modal.show('addBillCredit');

        });
    }
}
</script>
<template>
    <modal name="addBillCredit" :width="'80%'" :maxHeight="300" :height="'auto'" v-bind:click-to-close="false">
        <div class="row">
            <div class="col-12">
                <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                        class="fa-solid fa-xmark"></i> </button>
                <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
                    <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()">
                        <i class="fa-solid fa-xmark"></i> </button>
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Nota de crédito</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6 mt-3">
                                <label>Factura</label><br>
                                Factura # {{ form.bill_id }}
                                <div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
                            </div>

                            <div class="col-md-6 mt-3">
                                <label>Monto</label>
                                <money :disabled="!prop_edit" class="form-control" v-model="form.amount"></money>
                                <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <label>PDF</label><br>
                                <div class="custom-file" :style="pdf != '' ? 'width: 87%' : 'width: 100%'">
                                    <input :disabled="!prop_edit" type="file" class="custom-file-input"
                                        :class="pdf != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_pdf">
                                    <label class="custom-file-label text-left" for="customFile">{{ pdfName }}</label>
                                </div>
                                <a :href="`${globalUrlFiles}${pdf}`" v-if="pdf != ''" target="_blank"
                                    style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                        class="fa-regular fa-file-pdf"></i>
                                </a>
                                <br>
                                <div v-if="errors && errors.pdf" class="text-danger">{{ errors.pdf[0] }}</div>

                            </div>
                            <div class="col-md-6 mt-3">
                                <label>XML</label><br>
                                <div class="custom-file" :style="xml != '' ? 'width: 87%' : 'width: 100%'">
                                    <input :disabled="!prop_edit" type="file" class="custom-file-input"
                                        :class="xml != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_xml">
                                    <label class="custom-file-label text-left" for="customFile">{{ xmlName }}</label>
                                </div>
                                <a :href="`${globalUrlFiles}${xml}`" v-if="xml != ''" target="_blank"
                                    style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                        class="fa-regular fa-file"></i>
                                </a>
                                <br>
                                <div v-if="errors && errors.xml" class="text-danger">{{ errors.xml[0] }}</div>

                            </div>
                            <div class="col-md-12 mt-3">
                                <label>Descripción</label>
                                <textarea :disabled="!prop_edit" type="text" class="form-control"
                                    v-model="form.description"></textarea>
                                <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}
                                </div>
                            </div>
                            <div class="col-md-12">
                                <hr>
                                <button v-if="prop_edit" :disabled="send" type="submit"
                                    class="btn float-right btn-outline-success">
                                    <spam v-if="send">
                                        <i class="fa-solid fa-circle-notch fa-spin"></i> Guardando
                                    </spam>
                                    <spam v-else>
                                        <i class="fas fa-save"></i> Guardar
                                    </spam>
                                </button>
                                <button type="button" :disabled="send" @click="closedModal()"
                                    class="btn float-left btn-outline-warning">
                                    <i class="fas fa-undo-alt"></i>
                                    Regresar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </modal>
</template>