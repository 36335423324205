import Vue from 'vue'

import store from './store';
import router from './router';
import App from './App.vue';

require('bootstrap')
import './assets/custom.css';

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)


Vue.filter('toCurrency', function (value) {
  if(value){
    return '$ '+Number(parseFloat(value).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2});    
  }else if(Math.sign(value)){
    alert('aqui');
    return '$ '+Number(parseFloat(value).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2});    
  }else{
    return '$ 0.00';
  }
});

Vue.filter('toDateTime', function (value) {
  if(value){
    return moment(value).format("DD/MM/YYYY");  
  }else{
    return 'Sin Fecha';
  }
});


import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 6000
  }
}
Vue.use(Snotify, options)


import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCNh1zGzQyZNuVHCc2uFwdKOLqDKDgiI4g',
    libraries: 'places',
    region: 'MX',
    language: 'es-419',
  },
  autobindAllEvents: false,
  installComponents: true
})


import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import Money from 'v-money'
Vue.use(Money, {
  decimal: '.',
  thousands: ',',
  prefix: '$ ',
  suffix: '',
  precision: 2,
  masked: true
})


import vmodal from 'vue-js-modal'
import 'vue-js-modal/dist/styles.css'
Vue.use(vmodal)

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

import VueUploadComponent from 'vue-upload-component'
Vue.component('file-upload', VueUploadComponent)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

// setup fake backend
import { authHeader } from './helpers';
authHeader();

// ACL permisos usuario
require('./helpers/acl');

import moment from 'moment'
Vue.prototype.moment = moment
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')
window.Event = new Vue();



import money from 'v-money'
Vue.use(money, {
  precision: 2,
  decimal: '.',
  thousands: ',',
  prefix: '$ ',
})


/**
 * =========
 * Filer money
 * =========
 */
import numeral from 'numeral';
Vue.filter('money', function (value) {
  if (value) {
    return numeral(value).format('$0,0.00');
  } else {
    return parseFloat(0);
  }
});

Vue.config.productionTip = false

Vue.mixin({
  data: function () {
    return {
      get globalUrlFiles() {
        return window.location.hostname == 'localhost' ? 'http://127.0.0.1:8000' : 'https://api.grupomcloud.com';
        // return 'https://api.grupomcloud.com/';
      }
    }
  }
})


window.Event = new Vue();

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});
