<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';


export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                concepts: [''],
                contract: '',
                _method: 'POST',
            },
            contract: '',
            contractName: ''
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.concepts = [''];
            this.form.contract = '';
            this.contract = '';
            this.contractName = '';
            this.$modal.hide('addContract');
        },
        catch_file(event) {
            this.form.contract = event.target.files[0];
            this.contractName = event.target.files[0].name;
        },
        addConcept(){
            this.form.concepts.push(''); 
        },
        delConcept(ind){
            this.form.concepts.splice(ind, 1);
        },
        async saveBuildings() {
            try {
                const data_form = new FormData();
                data_form.append('id', this.form.id);
                data_form.append('concepts', this.form.concepts);
                data_form.append('contract', this.form.contract);

                let _endpoint = '/tenant';
                if (this.form.id != "") {
                    _endpoint = `/departments/contract`;
                }

                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-pays');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },

    created() {
        Event.$on("event-show-addContract", (department) => {
            if (department.id) {
                this.form.id = department.id;
                if (department.concepts) {
                    this.form.concepts = department.concepts;
                }
                if (department.contract) {
                    this.contract = department.contract;
                }
            }
            this.$modal.show('addContract');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }
}
</script>
<template>
    <modal v-bind:click-to-close="false" name="addContract" :width="'80%'" :scrollable="true" :maxHeight="300"
        :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Contrato</h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-md-12 mt-3">
                        <label>Conceptos</label>
                        <div class="row">
                            <div class="col-md-4" v-for="(concept, index) in form.concepts" :key="index">
                                <div class="input-group mb-3">                                  
                                    <input class="form-control" v-model="form.concepts[index]">
                                    <div class="input-group-prepend" v-if="index != 0">
                                        <span class="btn btn-danger" id="basic-addon1" @click="delConcept(index)">
                                            <i class="fa-solid fa-trash"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <button type="button" @click="addConcept" class="btn btn-sm btn-success">
                                    <i class="fa-solid fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div v-if="errors && errors.type_payment_id" class="text-danger">{{ errors.type_payment_id[0] }}
                        </div>
                    </div>


                    <div class="col-md-12 mt-3">
                        <label>Contrato</label><br>
                        <div class="custom-file" :style="contract != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" :disabled="!prop_edit" class="custom-file-input"
                                :class="contract != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ contractName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${contract}`" v-if="contract != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>


                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>