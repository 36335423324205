<script>

export default {
    data() {
        return {
            bill: {},
            checks: {},
        }
    },
    methods: {
        closedModal() {
            this.checks = {};
            this.$modal.hide('listChecksBills');
        },
        sumPays(pays) {
            let sum = 0;
            pays.forEach(function (valor) {
                sum += valor.amount;
            });
            return sum;
        },
        showCheck(edit = {}) {
            Event.$emit('event-show-addChecks', edit);
        },
        downloadRecibe(id) {
            window.open(this.globalUrlFiles + '/bills/pdf/' + id, '_blank');
        },
    },
    created() {
        Event.$on("event-show-listChecksBillsSummary", (bill) => {
            this.bill = bill;
            if (bill.checks) {
                this.checks = bill.checks;
            }
            this.$modal.show('listChecksBills');
        });
    },
    computed: {
        totalAmount() {
            let sum = 0;
            sum += this.checks.reduce((total, buy) => {             
                if(this.$route.name == 'pays'){
                    return total + buy.pay * 1;
                }else{                   
                    return total + buy.amount * 1;
                }
            }, 0);
            return sum;
        }
    }
}
</script>
<template>
    <modal name="listChecksBills" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Cheques relacionados</h5>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table table-bordered text-center text-sm">
                            <thead>
                                <tr>
                                    <th>ID Cheque</th>
                                    <th>Fecha</th>
                                    <th>Monto</th>
                                    <th>Por Pagar</th>
                                    <th>Pagado</th>
                                </tr>
                            </thead>
                            <tbody v-if="checks">
                                <tr v-for="(buy, index) in checks" :key="index">
                                    <td>{{ buy.id }}</td>
                                    <td>
                                        {{ buy.date }}
                                    </td>
                                    <td>
                                        {{ buy.amount_payable | toCurrency }}
                                    </td>

                                    <td>
                                        {{ buy.amount_payable - sumPays(buy.pay) | toCurrency }}
                                    </td>
                                    <td>
                                        {{ sumPays(buy.pay) | toCurrency }}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-left"> Total Factura : </td>
                                    <td> {{ bill.amount | toCurrency }} </td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-left"> Resto para pagar la factura :
                                    </td>
                                    <td v-if="( bill.amount - totalAmount) < 0"> {{ bill.amount - totalAmount | toCurrency }} </td>
                                    <td v-else> {{ totalAmount - bill.amount  | toCurrency }} </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td align="center" colspan="5">Sin resultados.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-md-12">
                    <hr>
                    <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                        <i class="fas fa-undo-alt"></i>
                        Regresar
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>