<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    components: {
        DatePicker
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                date: '',
                files: [],
            },
            files: [],
            send: false,
            load: false,
            status: 0,
            tit: '',
            txt: '',
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.date = '';
            this.form.files = [];
            this.files = [];
            this.xmlName = '';
            this.send = false;
            this.load = false;

            this.$modal.hide('changeStatusContract');

        },

        async delImgUpload(pos) {
            let img = this.files[pos];
            let id = img.id;
            let _endpoint = '/tenant/deleted-file';
            let form = {
                id: id
            };
            let { data } = await request.postMedia(_endpoint, form)
            if (data) {
                this.successNotification(data.message);
                this.files.splice(pos, 1);
            }

        },
        delImg(pos) {
            this.form.files.splice(pos, 1);
            this.successNotification('El archivo se elimino correctamente.');
        },
        uploadFilesBill(event) {
            let vm = this;

            let file = event.target.files[0];

            vm.form.files.push(file);
            console.log(vm.form.files);
            this.$forceUpdate();

        },
        createURL(file) {
            let link = URL.createObjectURL(file);
            return link;
        },
        async savePayTaxes() {
            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('date', this.form.date)
            this.form.files.forEach(function (valor, index) {
                data_form.append('files[' + index + ']', valor);
            });

            let _endpoint = `/tenant_status`;

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-debts');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }

        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-changeStatusContracts", async (tax) => {

            console.log('tax')
            console.log(tax)
            this.status = tax.status;
            this.tit = tax.status == 1 ? 'Termina contrato' : 'Activar contrato';
            this.txt = tax.status == 1 ? 'Seguro que quiere termina el contrato' : 'Seguro que quiere activar el contrato';


            if (tax.id) {
                this.form.id = tax.id;
                if (tax.files) {
                    this.files = tax.files;
                }
            }


            this.$modal.show('changeStatusContract');

        })
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>
<template>
    <modal name="changeStatusContract" :width="'80%'" :maxHeight="300" :height="'auto'" v-bind:click-to-close="false">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="savePayTaxes" method="post" id="formTax" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ tit }}</h5>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center">

                    <div class="col-md-12 mt-3 text-center">
                        <h3> {{ txt }} </h3>

                    </div>
                    <div class="col-md-6 col-12 mt-3 text-center" v-if="status == 1">
                        <h4 for="">Fecha de fin</h4>
                        <date-picker v-model="form.date" name="date" required="required"
                            valueType="format"></date-picker>
                        <div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}</div>

                    </div>


                    <div class="col-12">
                        <hr>
                        <div class="row">
                            <div class="col-12 text-center">
                                <h3>Archivo adjuntos.</h3>
                                <p>Máximo 3 archivos.</p>
                                <div class="row mt-4 mb-4">
                                    <div class="col-12" v-if="(form.files.length + files.length) == 0">
                                        <i class="fa-solid fa-file fa-4x"></i>
                                        <p>Sin archivos adjuntos</p>
                                    </div>
                                    <div class="col-md-4 col-12 p-2" v-for="(fAdd, index_fAdd) in files"
                                        :key="index_fAdd" v-show="files.length > 0">
                                        <div class="card shadow">
                                            <button v-if="prop_edit" class="btn btn-sm btn-danger del" type="button"
                                                @click="delImgUpload(index_fAdd)">
                                                <i class="fa fa-trash"></i></button>
                                            <div class="card-body text-center">
                                                <a :href="`${globalUrlFiles}${fAdd.file}`" target="_blank">
                                                    <i class="fa fa-file fa-4x"></i>
                                                    <p>{{ fAdd.name }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 col-12 p-2" v-for="(img, index_img) in form.files"
                                        :key="index_img" v-show="form.files.length > 0">
                                        <div class="card shadow">
                                            <button v-if="prop_edit" class="btn btn-sm btn-danger del" type="button"
                                                @click="delImg(index_img)"><i class="fa fa-trash"></i></button>
                                            <div class="card-body text-center">
                                                <a :href="createURL(img)" target="_blank">
                                                    <i class="fa fa-file fa-4x"></i>
                                                    <p>{{ img.name }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="input-group mb-3"
                                    v-if="(form.files.length + files.length) <= 14 && prop_edit">
                                    <div class="custom-file" @change="uploadFilesBill">
                                        <input type="file" class="custom-file-input" id="inputGroupFile03"
                                            aria-describedby="inputGroupFileAddon03">
                                        <label class="custom-file-label" for="inputGroupFile03">
                                            Subir Archivo
                                        </label>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>


                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i> Regresar
                        </button>
                    </div>

                </div>
            </div>
        </form>
    </modal>
</template>