<template>
	<div class="col-12 ">
		<div class="card card-outline card-navy">
			<div class="card-body">
				<form @submit.prevent="triggerSave">
					<div class="row">
						<div class="col-md-4">
							<div class="form-group">
								<label> Propiedad </label>
								<select :disabled="form.id != '' && Isdisabled()" type="text" class="form-control"
									v-model="form.building_id">
									<option v-for="(building, index) in catalogue.buildings" :value="building.id"
										:key="index">
										{{ building.name }}</option>
								</select>
								<div v-if="errors && errors.building_id" class="text-danger">{{ errors.building_id[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Departamento </label>
								<select v-model="form.indiviso_id" class="form-control" :disabled="Isdisabled()">
									<option value="">Sin departamento</option>
									<option v-for="(departament, index) in catalogue.departamens_buildings" :key="index"
										:value="departament.id"> Número {{ departament.number }} / Piso {{
					departament.level
				}}</option>
								</select>
								<div v-if="errors && errors.indiviso_id" class="text-danger">{{ errors.indiviso_id[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Empresa </label>
								<select v-model="form.company_id" class="form-control" :disabled="Isdisabled()">
									<option value="">Sin empresa</option>
									<option v-for="(company, index) in catalogue.companies_buildings" :key="index"
										:value="company.id"> {{ company.name }}
									</option>
								</select>
								<div v-if="errors && errors.company_id" class="text-danger">{{ errors.company_id[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label> Folio </label>
								<input :disabled="form.id != '' && Isdisabled()" type="text" v-model="folio"
									class="form-control">
								<div v-if="errors && errors.folio" class="text-danger">{{ errors.folio[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label> Usuario </label>
								<select type="text" class="form-control" v-model="form.user_id"
									:disabled="Isdisabled()">
									<option v-for="(user, index) in catalogue.users_profile" :value="user.id"
										:key="index">
										{{ user.name }} {{ user.last_name }}</option>
								</select>
								<div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label> Fecha</label>
								<date-picker v-model="form.date" valueType="format"
									:disabled="Isdisabled()"></date-picker>
								<div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label> Categoría </label>
								<select type="text" class="form-control" v-model="form.concept_id"
									:disabled="Isdisabled()">
									<option v-for="(concept, index) in catalogue.concepts" :value="concept.id"
										:key="index">
										{{ concept.name }}</option>
								</select>
								<div v-if="errors && errors.concept_id" class="text-danger">{{ errors.concept_id[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-2">
							<label>Subtotal</label>
							<money class="form-control" v-model="form.subtotal" :disabled="Isdisabled()"></money>
							<div v-if="errors && errors.subtotal" class="text-danger">{{ errors.subtotal[0] }}</div>
						</div>
						<div class="col-md-2">
							<label>IVA</label>
							<money class="form-control" v-model="form.iva" :disabled="Isdisabled()"></money>
							<div v-if="errors && errors.iva" class="text-danger">{{ errors.iva[0] }}</div>
						</div>
						<div class="col-md-2">
							<label>ISR RET</label>
							<money class="form-control" v-model="form.isr_ret" :disabled="Isdisabled()"></money>
							<div v-if="errors && errors.isr_ret" class="text-danger">{{ errors.isr_ret[0] }}</div>
						</div>
						<div class="col-md-2">
							<label>IVA RET</label>
							<money class="form-control" v-model="form.iva_ret" :disabled="Isdisabled()"></money>
							<div v-if="errors && errors.iva_ret" class="text-danger">{{ errors.iva_ret[0] }}</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label> Monto </label>
								<money readonly class="form-control" v-model="form.amount"></money>
								<div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label> Pagado </label>
								<money class="form-control" v-model="form.prepaid" :disabled="Isdisabled()"></money>
								<div v-if="errors && errors.prepaid" class="text-danger">{{ errors.amount[0] }}
								</div>
							</div>
						</div>



						<div class="col-md-12">
							<div class="form-group">
								<label> Descripción </label>
								<textarea type="text" v-model="form.description" :disabled="Isdisabled()"
									class="form-control"></textarea>
								<div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}
								</div>
							</div>
						</div>

						<div class="col-md-12 ">
							<label>Archivo</label><br>
							<div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
								<input type="file" class="custom-file-input" :class="file != '' ? 'pr-5' : ''"
									id="customFile" ref="file" @change="catch_file">
								<label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
							</div>
							<a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
								style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
									class="fa-regular fa-file-pdf"></i>
							</a>
							<br>
							<div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

						</div>

						<div class="col-md-3 text-center mt-3">
							<div class="form-group">
								<label> No requiere Contrato </label>
								<input type="checkbox" class="form-control" v-model="form.require_contract">
								<div v-if="errors && errors.require_contract" class="text-danger">{{
					errors.require_contract[0] }}
								</div>
							</div>
						</div>

						<div class="col-md-9 mt-3 ">
							<label>Contrato</label><br>
							<div class="custom-file" :style="contract != '' ? 'width: 87%' : 'width: 100%'">
								<input type="file" class="custom-file-input" :class="contract != '' ? 'pr-5' : ''"
									id="customFile" ref="file" @change="catch_contract">
								<label class="custom-file-label text-left" for="customFile">{{ contractName }}</label>
							</div>
							<a :href="`${globalUrlFiles}${contract}`" v-if="contract != ''" target="_blank"
								style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
									class="fa-regular fa-file-pdf"></i>
							</a>
							<br>
							<div v-if="errors && errors.contract" class="text-danger">{{ errors.contract[0] }}</div>

						</div>


						<div class="col-12">
							<hr>
						</div>
						<div class="col-md-12">
							<button type="submit" class="btn float-right btn-outline-success">
								<i class="fas fa-save"></i> Guardar
							</button>
							<router-link to="/budgets" class="btn float-left btn-outline-warning">
								<i class="fas fa-undo-alt"></i>
								Regresar
							</router-link>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import request from '@/services/request'
import { mapActions, mapGetters } from 'vuex';
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
	components: {
		DatePicker
	},
	name: "buy-form",
	data() {
		return {
			form: {
				id: '',
				building_id: '',
				indiviso_id: '',
				folio: '',
				user_id: '',
				amount: '',
				prepaid: '',
				date: '',
				concept_id: '',
				description: '',
				file: '',
				bill: '',
				company_id: '',
				subtotal: '',
				iva: '',
				isr_ret: '',
				iva_ret: '',
				require_contract: '',
				contract: ''
			},
			bank_accounts: [],
			errors: {},
			folio: '',
			// 
			file_cedula: '',
			cedularName: '',
			catalogue: new Catalogue(),
			file: '',
			fileName: '',
			contract: '',
			contractName: ''
		}
	},
	async mounted() {
		await this.catalogue.get_buildings();
		await this.catalogue.get_concepts();
		await this.catalogue.get_users_profile(2);
		await this.catchParameters();
		let id = this.$route.params.id;
		if (id) {
			await this.get_id(id);
		}
	},
	methods: {
		Isdisabled() {
			if (this.authUser.organization_charts_id == 11) {
				return true;
			}
			return false;
		},
		async catchParameters() {
			let params = this.$route.query;
			if (params.bill) {
				this.form.bill = params.bill;
			}
			if (params.supplier) {
				this.form.user_id = params.supplier;
			}
		},
		catch_file(event) {
			if (event.target.files[0].type == "application/pdf") {
				this.form.file = event.target.files[0];
				this.fileName = event.target.files[0].name;
			} else {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos PDF',
				});
			}
		},
		catch_contract(event) {
			if (event.target.files[0].type == "application/pdf") {
				this.form.contract = event.target.files[0];
				this.contractName = event.target.files[0].name;
			} else {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos PDF',
				});
			}
		},
		async triggerSave() {
			const data_form = new FormData();
			data_form.append('id', this.form.id);
			data_form.append('building_id', this.form.building_id);
			data_form.append('indiviso_id', this.form.indiviso_id);
			data_form.append('folio', this.folio);
			data_form.append('user_id', this.form.user_id);
			data_form.append('amount', this.form.amount);
			data_form.append('prepaid', this.form.prepaid);
			data_form.append('concept_id', this.form.concept_id);
			data_form.append('date', this.form.date);
			data_form.append('description', this.form.description);
			data_form.append('file', this.form.file);
			data_form.append('bill', this.form.bill);
			data_form.append('company_id', this.form.company_id);
			data_form.append('subtotal', this.form.subtotal);
			data_form.append('iva', this.form.iva);
			data_form.append('isr_ret', this.form.isr_ret);
			data_form.append('iva_ret', this.form.iva_ret);
			data_form.append('require_contract', this.form.require_contract);
			data_form.append('contract', this.form.contract);

			let _endpoint = '/budgets';
			if (this.form.id != "") {
				data_form.append('_method', 'PUT');
				_endpoint = `/budgets/${this.form.id}`;
			}

			try {
				let { data } = await request.postMedia(_endpoint, data_form)
				if (data) {
					console.log(data);
					this.successNotification(data.message);
					this.$router.push('/budgets');
				}
			} catch (error) {
				this.errors = await this.errorNotification(error);
			}
		},
		calcTotal() {
			this.form.amount = this.form.subtotal + this.form.iva - this.form.isr_ret - this.form.iva_ret;
		},
		async get_id(id) {
			let _endpoint = `/budgets/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.form.id = id;
					this.form.building_id = data.building_id;
					this.form.indiviso_id = data.indiviso_id;
					this.folio = data.folio;
					this.form.user_id = data.user_id;
					this.form.amount = data.amount;
					this.form.prepaid = data.prepaid;
					this.form.concept_id = data.concept_id;
					this.form.date = data.date;
					this.form.description = data.description;
					this.form.company_id = data.company_id;
					this.form.subtotal = data.subtotal;
					this.form.iva = data.iva;
					this.form.iva_ret = data.iva_ret;
					this.form.isr_ret = data.isr_ret;
					this.form.require_contract = data.require_contract == 1 ? true : false;

					if (data.file) {
						this.file = data.file;
					}

					if (data.contract	) {
						this.contract	 = data.contract	;
					}

					this.catalogue.get_departamens_buildings(data.building_id);
					this.catalogue.get_companies_buildings(data.building_id);
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	created() {
		Event.$on("event-load-bank_accounts", () => {
			this.accountBanks();
		});
	},
	watch: {
		'form.building_id'(value) {
			if (this.form.id == '') {
				console.log(value);
				this.catalogue.get_folio_budgets(value);
				this.catalogue.get_departamens_buildings(value);
				this.catalogue.get_companies_buildings(value);
			}
		},
		'catalogue.folio_budgets'() {
			this.folio = this.catalogue.folio_budgets.folio;
		},
		'form.subtotal'() {
			this.calcTotal();
		},
		'form.iva'() {
			this.calcTotal();
		},
		'form.isr_ret'() {
			this.calcTotal();
		},
		'form.iva_ret'() {
			this.calcTotal();
		}
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.mx-datepicker {
	width: 100%;
}

.fs-6 {
	font-size: 12px;
}

.custom-file-label::after {
	content: "Archivo";
}
</style>
