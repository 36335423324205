<script>
import request from '@/services/request'
import { mapActions, mapGetters } from 'vuex';


export default {
    name: "buy-form",
    data() {
        return {
            form: {
                id: '',       
                amount: '',       
                subtotal: '',
                iva: '',
                isr_ret: '',
                iva_ret: '',
                buy_id:''
            },
            errors: {},
            //            
            load: false,
            send: false,
        }
    },  
    methods: {
        Isdisabled() {
            if (this.authUser.organization_charts_id == 11) {
                return true;
            }
            return false;
        },
        closedModal() {
            this.form.id = '';         
            this.form.subtotal = '';
            this.form.iva = '';
            this.form.iva_ret = '';
            this.form.isr_ret = '';         
            this.form.amount = '';         
            this.send = false,
            this.$modal.hide('editAmountBuy');
        },             
        calcTotal() {
            this.form.amount = this.form.subtotal + this.form.iva - this.form.isr_ret - this.form.iva_ret;
        },     
        async triggerSave() {
            this.send = true;
            const data_form = new FormData();
            data_form.append('id', this.form.id);          
            data_form.append('subtotal', this.form.subtotal);
            data_form.append('iva', this.form.iva);
            data_form.append('isr_ret', this.form.isr_ret);
            data_form.append('iva_ret', this.form.iva_ret);
            data_form.append('amount', this.form.amount);
            
            let _endpoint = `/buys_amount`;
            
            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    this.successNotification(data.message);
                    Event.$emit('event-show-BuysRelationID', this.form.buy_id);
                    this.closedModal();
                }
            } catch (error) {
                this.send = false;
                this.errors = await this.errorNotification(error);
            }
        },
      
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    created() {
        Event.$on("event-show-editAmountBuys", (buy) => {
            this.form.id = buy.id;
            this.form.subtotal = buy.subtotal;
            this.form.iva = buy.iva;
            this.form.iva_ret = buy.iva_ret;
            this.form.isr_ret = buy.isr_ret;
            this.form.amount = buy.amount;
            this.form.buy_id = buy.buy_id;
            this.$modal.show('editAmountBuy');

        });
    },
    watch: {
        'form.subtotal'() {
            this.calcTotal();
        },
        'form.iva'() {
            this.calcTotal();
        },
        'form.isr_ret'() {
            this.calcTotal();
        },
        'form.iva_ret'() {
            this.calcTotal();
        }
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>

<template>
    <modal name="editAmountBuy" v-bind:click-to-close="false" :width="'70%'" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="triggerSave">
            <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLabel">Departamento... {{department ? department.number:''}}</h5> -->
                <h5 class="modal-title" id="exampleModalLabel">Gastos recurrentes</h5>
            </div>
            <div class="modal-body">

                <div class="row">

                    <div class="col-md-2">
                        <label>Subtotal</label>
                        <money class="form-control" v-model="form.subtotal" :disabled="Isdisabled()"></money>
                        <div v-if="errors && errors.subtotal" class="text-danger">{{ errors.subtotal[0] }}</div>
                    </div>
                    <div class="col-md-2">
                        <label>IVA</label>
                        <money class="form-control" v-model="form.iva" :disabled="Isdisabled()"></money>
                        <div v-if="errors && errors.iva" class="text-danger">{{ errors.iva[0] }}</div>
                    </div>
                    <div class="col-md-2">
                        <label>ISR RET</label>
                        <money class="form-control" v-model="form.isr_ret" :disabled="Isdisabled()"></money>
                        <div v-if="errors && errors.isr_ret" class="text-danger">{{ errors.isr_ret[0] }}</div>
                    </div>
                    <div class="col-md-2">
                        <label>IVA RET</label>
                        <money class="form-control" v-model="form.iva_ret" :disabled="Isdisabled()"></money>
                        <div v-if="errors && errors.iva_ret" class="text-danger">{{ errors.iva_ret[0] }}</div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Monto </label>
                            <money readonly class="form-control" v-model="form.amount" :disabled="Isdisabled()"></money>
                            <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col-md-12">
                        <button :disabled="send" type="submit" class="btn float-right btn-outline-success">
                            <span v-if="send">
                                <i class="fa-solid fa-circle-notch fa-spin"></i> Guardando
                            </span>
                            <span v-else>
                                <i class="fas fa-save"></i> Guardar
                            </span>
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>

<style scoped>
.pagination {
    margin-bottom: 0;
}

.mx-datepicker {
    width: 100%;
}

.fs-6 {
    font-size: 12px;
}

.custom-file-label::after {
    content: "Archivo";
}

.image-upload>input {
    display: none;
}

.del {
    position: absolute;
    right: 0px;
    margin: 10px;
}
</style>