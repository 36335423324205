import request from './request'

export default class Catalogos {

    constructor() {
        this.all_users = [];
        this.services = [];
        this.type_documents = [];
        this.payment_periods = [];
        this.banks = [];
        this.type_account = [];
        this.users_profile = [];
        this.buildings = [];
        this.concepts = [];
        this.companies_all = [];
        this.payments_recurrings = [];
        this.business_type = [];
        this.provider_types = [];
        this.type_payments = [];
        this.budgets_user = [];
        this.budgets_user_load = false;
        this.buys_user = [];
        this.buys_user_load = false;
        this.bills_user = [];
        this.departamens_buildings = [];
        this.folio_budgets = '';
        this.bills_types = [];
        this.companies_users = [];
        this.companies_buildings = [];
        this.profiles = [];
        this.messages_user = [];
        this.billing_data_user = [];
        this.all_billing_data = [];
        this.bill_data_types = [];
        this.bank_account_provider = [];
        this.bank_account_company = [];
        this.bank_bill_account_provider = [];
        this.currencies = [];
        this.validatePaymentPlugin = [];
        this.checksCancel = [];
        this.taxes_types = [];
        this.type_increase = [];
        this.types_bill_number = [];
        this.count_bill_check = 0;
        this.count_checks_authorize = 0;
        this.count_paid_checks = 0;
        this.count_paid_checks_tax = 0;
        this.buildings_resident = [];
        this.petty_cash_user = [];
        this.petty_cash_user_load = true;

    }

    async get_payment_periods() {
        let _endpoint = '/catalogue/payment_period';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.payment_periods = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_services() {
        let _endpoint = '/catalogue/services';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.services = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_type_documents() {
        let _endpoint = '/catalogue/type_documents';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.type_documents = data;
            }
        } catch (error) {
            console.log(error);
        }
    }



    async get_all_users(query = '') {
        let search = { _search: query };
        let _endpoint = '/catalogue/users';
        try {
            let { data } = await request.get(_endpoint, search)
            if (data) {
                this.all_users = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_banks() {
        let _endpoint = '/catalogue/banks';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.banks = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_type_account() {
        let _endpoint = '/catalogue/type_account';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.type_account = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_users_profile(id) {
        let _endpoint = '/catalogue/users_profile/' + id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.users_profile = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_buildings() {
        let _endpoint = '/catalogue/buildings';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.buildings = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_concepts() {
        let _endpoint = '/concept';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.concepts = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_payments_recurrings() {
        let _endpoint = '/catalogue/payments_recurrings';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.payments_recurrings = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_folio_budgets(id) {
        let _endpoint = '/catalogue/folio_budgets/' + id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.folio_budgets = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_business_type() {
        let _endpoint = '/business_type';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.business_type = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_provider_types() {
        let _endpoint = '/catalogue/provider_types';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.provider_types = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_type_payments() {
        let _endpoint = '/catalogue/type_payments';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.type_payments = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_budgets_user(user = '', concept_id = '', company_id = '') {
        this.budgets_user_load = true;
        let _endpoint = '/catalogue/budgets_user?user_id=' + user + '&concept_id=' + concept_id + '&company_id=' + company_id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.budgets_user_load = false;
                this.budgets_user = data;
            }
        } catch (error) {
            console.log(error);
            this.budgets_user_load = false;
        }
    }

    async get_buys_user(user = '', concept_id = '', company_id = '') {
        this.buys_user_load = true;
        let _endpoint = '/catalogue/buys_user?user_id=' + user + '&concept_id=' + concept_id + '&company_id=' + company_id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.buys_user = data;
                this.buys_user_load = false;
            }
        } catch (error) {
            console.log(error);
            this.buys_user_load = false;
        }
    }

    async get_bills_user(user = '', id = '') {
        let _endpoint = '/catalogue/bills_user?id=' + id + '&user_id=' + user;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.bills_user = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_departamens_buildings(building = '') {
        let _endpoint = '/departments/list?building_id=' + building;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.departamens_buildings = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_bills_types() {
        let _endpoint = '/catalogue/bills_types';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.bills_types = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_companies_users(user_id = '') {
        let _endpoint = '/catalogue/companies_users/' + user_id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.companies_users = data.companies;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_companies_buildings(building_id = '') {
        let _endpoint = '/catalogue/companies_buildings';
        if (building_id) {
            _endpoint = '/catalogue/companies_buildings/' + building_id;
        }
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                if (building_id) {
                    this.companies_buildings = data.companies;
                } else {
                    this.companies_buildings = data;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    async getProfiles() {
        let _endpoint = '/catalogue/profiles';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.profiles = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async getMessagesuser(user_id) {
        let _endpoint = '/get_message_users?id=' + user_id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.messages_user = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async getBillingDataUser(user_id) {
        let _endpoint = user_id + '/billing_data';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.billing_data_user = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async getBillingDataType() {
        let _endpoint = '/catalogue/bill_data_type';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.bill_data_types = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async getBankAccountProvider(user_id) {
        let _endpoint = `/${user_id}/bank_account_provider`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.bank_account_provider = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async getBankBillAccountProvider(bill_id = false) {
        if (bill_id) {
            let _endpoint = '/catalogue/bill_bank/' + bill_id;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    console.log(data.banks);
                    this.bank_bill_account_provider = data.banks;
                } else {
                    this.bank_bill_account_provider = [];
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            this.bank_bill_account_provider = [];
        }
    }

    async getBankAccountCompany(company_id = false) {
        if (company_id) {
            let _endpoint = '/companies/' + company_id;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    this.bank_account_company = data.bank_accounts;
                } else {
                    this.bank_account_company = [];
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            this.bank_account_company = [];
        }
    }

    async getCurrencies() {
        let _endpoint = '/catalogue/currencies';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.currencies = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_validate_payment_plugin() {
        let search = {
            company_id: '',
            paid: 1,
            search: '',
            type_id: '',
            user_id: '',
            concept_id: '',
            biill_type_id: '',
            type_payment_id: '',
            billing_data_id: '',
            date: ['2024-07-01', '2030-12-31'],
            complement: 1
        };

        let _endpoint = '/pays';
        try {
            let { data } = await request.list(_endpoint, 1, search)
            if (data) {
                this.validatePaymentPlugin = data.data;

            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_check_cancel() {
        let search = {
            date: '',
            company_id: '',
            search: '',
            type_id: '',
            user_id: '',
            concept_id: '',
            type_payment_id: '',
            biill_type_id: '',
            billing_data_id: '',
            status: 2,
            authorized: 1,
        };

        let _endpoint = '/checks';
        try {
            let { data } = await request.list(_endpoint, 1, search)
            if (data) {
                this.checksCancel = data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_taxes_types() {
        let _endpoint = '/catalogue/taxes_types';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.taxes_types = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_all_billing_data() {
        let _endpoint = '/catalogue/all_billing_data';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.all_billing_data = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_companies_all() {
        let _endpoint = '/catalogue/companies';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.companies_all = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_type_increase() {
        let _endpoint = '/catalogue/type_increase';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.type_increase = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_count_bill_check() {
        let _endpoint = '/catalogue/get_count_bill_check';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.count_bill_check = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_checks_authorize() {
        let _endpoint = '/catalogue/get_checks_authorize';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.count_checks_authorize = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_paid_checks() {
        let _endpoint = '/catalogue/get_paid_checks';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.count_paid_checks = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_paid_checks_tax() {
        let _endpoint = '/catalogue/get_paid_checks_tax';
        this.count_paid_checks_tax = 0;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.count_paid_checks_tax = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_types_bill_number() {
        let _endpoint = '/catalogue/types_bill_number';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.types_bill_number = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_buildings_resident(user_id) {
        let _endpoint = '/catalogue/building_resident/' + user_id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.buildings_resident = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_petty_cash_user(filter) {
        this.petty_cash_user_load = true;
                
        let search = {
            building_id: '',
            user_id: ''
        };
        if(filter.building_id){
            search.building_id = filter.building_id;
        }
        if(filter.user_id){
            search.user_id = filter.user_id;
        }
        let _endpoint = '/petty_cash_user';
        try {
            let { data } = await request.post(_endpoint, search)
            if (data) {
                this.petty_cash_user_load = false;
                this.petty_cash_user = data;
            }
        } catch (error) {
            console.log(error);
        }
    }



}