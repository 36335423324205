<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Pagos de impuestos</h3>
				<div class="card-tools">
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-7 col-lg-8">
						<label>Buscar :</label>
						<input class="form-control" v-debounce:1s="list" type="search" v-model="search.search">
					</div>
					<div class="col-md-5 col-lg-4">
						<label>Rango de fechas :</label>
						<date-picker format="YYYY-MM-DD" :range="true" v-model="search.date"></date-picker>
					</div>
					<div class="col-md-4 mt-2">
						<label>Empresa :</label>
						<select v-model="search.company_id" class="form-control">
							<option value="">Todos</option>
							<option v-for="(company, index) in catalogue.companies_buildings" :key="index"
								:value="company.id"> {{ company.name }}
							</option>
						</select>
					</div>

					<div class="col-md-4 mt-2">
						<label>Tipo de saldo</label>
						<select type="text" class="form-control" v-model="search.type_payment_id">
							<option value="">Todos</option>
							<option v-for="(type_payment, index_type_payment) in catalogue.type_payments"
								:value="type_payment.id" :key="index_type_payment">{{
							type_payment.name }}</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Tipo :</label>
						<select class="form-control" v-model="search.taxes_types_id">
							<option value="">Todos</option>
							<option v-for="(type, index) in catalogue.taxes_types" :value="type.id" :key="index">{{
							type.name }}</option>
						</select>
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-sm text-center">
						<thead>
							<tr>
								<th>ID</th>
								<th style="width: 45%;">Datos</th>
								<th style="width: 140px;">Monto</th>
								<th style="width: 140px;">Pagado</th>
								<th class="text-center">Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="9">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="pays.total > 0">
							<tr :class="buy.id == selected ? 'selected' : ''" v-for="(buy, index) in pays.data"
								:key="index">
								<td>
									{{ buy.id }}

								</td>
								<td class="text-left">


									<!-- Empresa -->
									<strong>Empresa: </strong><br>
									<span v-if="buy.tax">
										<span v-if="buy.tax.company">
											{{ buy.tax.company.name }}
										</span>
									</span>
									<span v-else>Sin Empresa</span>
									<br>
									<br>

									<!-- Tipo -->
									<strong>Tipo de movimiento : </strong><br>
									<span v-if="buy.type_payment"> {{ buy.type_payment.name }}</span>
									<span v-else>Sin tipo</span>
									<br>



								</td>

								<td class="text-right">
									{{ buy.amount_payable | toCurrency }}
								</td>
								<td class="text-right">
									{{ buy.sum_pays | toCurrency }}
								</td>


								<td class="text-center">

									<button @click="AddTaxes(buy.tax, false)" class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-eye "> </i> Ver más
									</button>

									<button @click="formListPays(buy)" class="btn m-1 btn-sm btn-outline-warning">
										<i class="fa-solid fa-hand-holding-dollar mr-1"> </i>
										<span>Ver pago</span>
									</button>

									<button @click="formDataPaysFast(buy)" class="btn btn-outline-info m-1 btn-sm"
										v-if="buy.pay.length == 0 && buy.tax">
										<i class="fa-regular fa-handshake mr-1"></i>
										<span>Pago rápido</span>
									</button>

									<button type="button" v-can="'receipt_tax'" v-if="getPays(buy) == buy.tax.amount"
										@click="uploadVoucher(buy.tax)" class=" btn m-1 btn-sm" :class="buy.tax.voucher ? 'btn-outline-info': 'btn-outline-success' ">
										<span v-if="buy.tax.voucher">
										<i class="fa-solid fa-receipt"></i> Ver acuse
										</span>
										<span v-else>
										<i class="fa-solid fa-receipt"></i> Subir acuse
										</span>
									</button>

								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="9">Sin resultados.</td>
							</tr>
						</tbody>
						<tfoot v-if="pays.total > 0 && load == false">
							<tr class="table-secondary">
								<td colspan="2">
									Total :
								</td>

								<td class="text-right">
									{{ sumdata()[1] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[2] | toCurrency }}
								</td>
								<td colspan="4"></td>
							</tr>
						</tfoot>
					</table>
					<pagination v-if="pays" v-model="page" :records="pays.total" :per-page="pays.per_page"
						@paginate="list" />
				</div>
			</div>
		</div>

		<ModalFormPay :prop_edit="true" />
		<ModalFormComponent :prop_edit="false" />
		<ModalTableComponentPays :prop_edit="false" />
		<ModalFormComponentBill :prop_edit="editBill" />
		<ModalDownloadFiles />
		<ModalFormReceiptComponent />
		<ModalBuysTable />
		<ModalBudgetsTable />
		<ModalBuysRelationComponent />
		<ModalSignatureComponentVue />
		<ModalCredit :prop_edit="false" />
		<ModalFormTax :prop_edit="false" />
		<ModalFormVoucher />

	</div>
</template>



<script>

import ModalFormPay from '@/components/tax/ModaFormPayComponent.vue';

import ModalCredit from '@/components/bill/ModalCreditComponent.vue';
import ModalDownloadFiles from '@/components/bill/ModalDownloadFilesComponent.vue';
import ModalFormReceiptComponent from '@/components/pays/ModalFormReceiptComponent.vue';

import ModalFormComponent from '@/components/checks/ModalFormComponent.vue';
import ModalTableComponentPays from '@/components/pays/ModalTableComponent.vue';
import ModalFormComponentBill from '@/components/bill/ModalFormComponent.vue';
import ModalSignatureComponentVue from '@/components/pays/ModalSignatureComponent.vue';

import ModalBuysTable from '@/components/buy/ModalTableComponent.vue'
import ModalBudgetsTable from '@/components/budgets/ModalTableComponent.vue'
import ModalFormTax from '@/components/tax/ModaFormComponent.vue'
import ModalFormVoucher from '@/components/tax/ModalFormVoucherComponent.vue'

import ModalBuysRelationComponent from '@/components/buy/ModalBuysRelationComponent.vue';

import { mapMutations, mapActions, mapGetters } from 'vuex';
import Pagination from 'vue-pagination-2';
import request from '@/services/request';
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


export default {
	name: "companies-table",
	components: {
		ModalFormPay,
		ModalFormComponent,
		ModalTableComponentPays,
		ModalFormComponentBill,
		ModalDownloadFiles,
		ModalFormReceiptComponent,
		Pagination,
		DatePicker,
		ModalBuysTable,
		ModalBudgetsTable,
		ModalBuysRelationComponent,
		ModalSignatureComponentVue,
		ModalCredit,
		ModalFormVoucher,
		ModalFormTax,
	},
	data() {
		return {
			name: '',
			editBill: '',
			pays: {},
			selected: '',
			search: {
				date: '',
				company_id: '',
				paid: 1,
				search: '',
				type_id: '',
				user_id: '',
				concept_id: '',
				biill_type_id: '',
				type_payment_id: '',
				complement: '',
				taxes_types_id: ''
			},
			page: 1,
			catalogue: new Catalogue(),
			load: true,
		}
	},
	async mounted() {
		let id = this.$route.query.id;
		if (id) {
			this.search.complement = 1;
		} else {
			await this.list();
		}

		await this.catalogue.get_companies_buildings();
		await this.catalogue.get_users_profile(2);
		await this.catalogue.get_concepts();
		await this.catalogue.get_type_payments();
		await this.catalogue.get_bills_types();
		await this.catalogue.get_taxes_types();

		this.setHeaderTitle('Gasto recurrente');
		await this.showSelected(id);

	},
	created() {
		Event.$on("event-load-checks", () => {
			this.list();
		});
		Event.$on("event-load-bills", () => {
			this.list();
		});
	},
	methods: {
		getPays(check) {
			let sum = 0;
			if (check) {
				if (check.pay.length > 0) {
					check.pay.forEach(function (valor) {
						sum += parseFloat(valor.amount);
					});
				}
			}
			return sum;
		},
		AddTaxes(tax = {}) {			
			Event.$emit('event-show-addTaxes', tax);
		},
		uploadVoucher(check) {
			Event.$emit('event-show-addVoucher', check);
		},
		async formDataPaysFastGroup() {
			let company_id = this.search.company_id;
			let Arrauthorized = this.Arrauthorized;
			await this.catalogue.getBankAccountCompany(company_id);
			let bancks = this.catalogue.bank_account_company;
			let banco = [];
			bancks.forEach(function (bank) {
				let name = '';
				if (bank.bank) {
					name += bank.bank.name + ' - ';
				}
				name += bank.number;
				banco['_' + bank.id] = name;
			});

			if (bancks.length == 0) {
				this.$swal({
					title: "No se cuenta con cuenta",
					text: "No se puede pagar es requerida una cuenta",
					icon: "info"
				});
			}

			if (bancks.length > 1) {
				const { value: bank_id } = await this.$swal({
					title: "Cuenta de banco",
					input: "select",
					inputOptions: banco,
					inputPlaceholder: "Seleccione una cuenta de banco",
					showCancelButton: true,
					inputValidator: (value) => {
						console.log(value);
						return new Promise((resolve) => {
							if (value !== "") {
								resolve();
							} else {
								resolve("Es requerida la cuenta de banco");
							}
						});
					}
				});

				if (bank_id) {
					this.sendGroup = true;
					let form = {
						checks_id: Arrauthorized,
						bank_id: bank_id.substring(1),
						date: this.moment().format("YYYY-MM-DD"),
						description: 'Pago aprobado'
					};
					let _endpoint = '/pays_group';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							console.log(data);
							this.successNotification(data.message);
							this.list();
							Event.$emit('event-load-bills-tax');
							this.sendGroup = false;

						}
					} catch (error) {
						this.errors = {};
						if (error.response) {
							this.errors = await this.errorNotification(error);
							this.sendGroup = false;
						}
					}
				}
			} else {

				console.log(bancks[0].id);

				this.$swal({
					title: 'Pago rápido',
					text: 'Se aprobara el pago rápido',
					html: '<input type="date" id="pago2" class="form-control">',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Pagar',
					cancelButtonText: 'Cancelar'
				}).then(async (result) => {
					if (result.isConfirmed) {
						this.sendGroup = true;
						let form = {
							checks_id: Arrauthorized,
							bank_id: bancks[0].id,
							date: document.getElementById('pago2').value,
							description: 'Pago aprobado'
						};
						let _endpoint = '/pays_group';
						try {
							let { data } = await request.postMedia(_endpoint, form)
							if (data) {
								console.log(data);
								this.successNotification(data.message);
								this.list();
								Event.$emit('event-load-bills-tax');
								this.sendGroup = false;
							}
						} catch (error) {
							this.errors = {};
							if (error.response) {
								this.errors = await this.errorNotification(error);
								this.sendGroup = false;

							}
						}
					}
				});
			}
		},
		newCredit(bill, credit) {
			Event.$emit('event-show-addBillCredit', bill, credit);
		},
		addSignature(bill = {}) {
			console.log(bill);
			Event.$emit('event-show-addSiganturePay', bill);
		},
		viewPay(id) {
			Event.$emit('event-show-BuysRelationID', id);
			// Event.$emit('event-load-buy', id);
		},
		showSelected(id = false) {
			if (id) {
				this.selected = id;
			} else {
				this.selected = '';
			}

		},
		newPay(bill = {}) {
			console.log(bill);
			Event.$emit('event-show-addPays', bill);
		},
		downloadFiles() {
			let filter = this.search;
			filter.type = 'pays';
			Event.$emit('event-show-downloadFiles', filter);
		},
		downloadFile(type, id) {
			let url = this.globalUrlFiles + '/bills/download_file?id=' + id + '&type=' + type;
			window.open(url, '_blank');
		},
		showBudget(id) {
			Event.$emit('event-load-budget', id);
		},
		downloadRecibe(id) {
			window.open(this.globalUrlFiles + '/bills/pdf/' + id, '_blank');
		},
		exportData() {
			let filter = this.globalUrlFiles + '/pays/export?' + new URLSearchParams(this.search).toString();
			filter += '&user_login=' + this.authUser.id;
			window.open(filter, '_blank');
		},
		cancelPays(id) {
			let vm = this;
			console.log(id);
			this.$swal({
				title: 'Motivos de Cancelación',
				input: 'text',
				icon: 'warning',
				inputAttributes: {
					autocapitalize: 'off'
				},
				showCancelButton: true,
				confirmButtonText: 'Guardar',
				showLoaderOnConfirm: true,
				preConfirm: async (message) => {
					console.log(message);
					let form = {
						id: id,
						message: message,
					};
					let _endpoint = '/cancel_pays';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							console.log(data);
							await vm.successNotification(data.message);
							await vm.list();
						}
					} catch (error) {
						vm.errors = {};
						if (error.response) {
							vm.errors = vm.errorNotification(error);
						}
					}
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					console.log(result);
				}
			})

		},
		formData(edit = {}) {
			console.log(edit);
			Event.$emit('event-show-addChecks', edit);
		},
		formDataBill(dta = {}, edit = false) {
			console.log(dta);
			this.editBill = edit;
			Event.$emit('event-show-addBill', dta);
		},
		formDataPays(check, edit = {}) {
			Event.$emit('event-show-addPays', check, edit);
		},
		formListPays(check) {
			Event.$emit('event-show-listPays', check);
		},
		formDataPaysFast(check) {
			Event.$emit('event-show-addPayTaxes', check);

		},
		deleted: function (id) {
			let tit = 'Eliminar cheque';
			let txt = 'Seguro que quiere eliminar la cheque';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		approve: function (id) {
			let tit = 'Aprobar Presupuestos';
			let txt = 'Seguro que quiere aprobar el presupuestos';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let _endpoint = '/pays_approve';
					let form = {
						id: id
					};
					try {
						let response = await request.post(_endpoint, form).then(function (response) { return response; });
						this.list();
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/pays/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		sumdata() {
			let sum1 = 0;
			let sum2 = 0;
			let sum3 = 0;
			if (this.pays) {
				if (this.pays.data) {
					this.pays.data.forEach(function (buy) {
						console.log(buy.amount + buy.credit);
						if (buy.amount) {
							sum1 += parseFloat(buy.amount);
						}
						if (buy.credit) {
							sum1 += parseFloat(buy.credit);
						}
						sum2 += parseFloat(buy.amount_payable);
						sum3 += parseFloat(buy.sum_pays);

					});
				}
			}

			return [sum1, sum2, sum3];
		},
		async list(page = this.page) {
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/taxes_pays';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.pays = data;
					this.load = false;
					this.selected = '';
					this.sumdata();
				}
			} catch (error) {
				console.log(error);
				this.load = false;
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	computed: {		
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	watch: {
		'$route.query': {
			handler: function (params) {
				if (params) {
					this.showSelected(params.id);
				}
			},
			deep: true,
			immediate: true
		},
		'name': {
			handler: function (val) {
				if (val == '') {
					this.list();
				}
			},
			deep: true
		},
		"search": {
			handler: function () {
				this.list();
			},
			deep: true
		}
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.bg-info {
	background-color: #061f3f;
}

.selected {
	color: #000;
	font-weight: bold;
	animation: blinkingText 4s infinite;
}

@keyframes blinkingText {
	0% {
		border: 3px solid #36a590;
	}

	25% {
		border: 3px solid #dee2e6;
	}

	50% {
		border: 3px solid #36a590;
	}

	75% {
		border: 3px solid #dee2e6;
	}

	100% {
		border: 3px solid #36a590;
	}
}
</style>
