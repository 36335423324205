<template>
	<div class="col-12">
		<div class="row justify-content-center">


			<div class="col-md-12 text-right mb-3" v-if="authUser.organization_charts_id == 2  && !load">
				<button @click="newDepartment()" v-if="catalogue.validatePaymentPlugin.length > 0"
					class="btn btn-info btn-lg"><i class="fa-solid fa-file-invoice-dollar mr-2"></i> Agregar
					Factura</button>

				<button @click="newDepartment()" class="btn btn-info btn-lg" v-else><i
						class="fa-solid fa-file-invoice-dollar mr-2"></i> Agregar Factura</button>

			</div>
			<ModalFormComponent />

			<!-- Moodulos Proveedor -->
			<!-- <div class="col-md-12" v-if="authUser.organization_charts_id == 2">
				<div class="card">
					<div class="card-body">
						<ListProvider/>
					</div>
				</div>
			</div> -->
			<div class="col-md-12" v-if="authUser.organization_charts_id == 2">
				<div class="card">
					<div class="card-body">
						<ListBillingProvider />
					</div>
				</div>
			</div>
			<div class="col-md-12" v-if="authUser.organization_charts_id == 2">
				<div class="card">
					<div class="card-body">
						<ListBankAccount />
					</div>
				</div>
			</div>
			<div class="col-md-12" v-if="authUser.organization_charts_id == 2">
				<div class="card">
					<div class="card-body">
						<ListContact />
					</div>
				</div>
			</div>
			<!-- Fin Modulos proveedor-->

			<!-- Cuentas por cobrar -->
			<div class="col-md-12" v-if="authUser.organization_charts_id == 3">
				<ListBill />
			</div>
			<!-- <div class="col-md-12" v-if="authUser.organization_charts_id == 3">
				<ListChecks />
			</div> -->
			<!-- Fin Cuentas por cobrar-->

			<!-- Tesoreria -->
			<div class="col-md-12" v-if="authUser.organization_charts_id == 4">
				<ListPay />
			</div>
			<!-- Fin Tesoreria-->


			<!-- Usuarios -->
			<div class="col-md-6 col-lg-4 col-sm-6 col-12" v-can="'users'">
				<router-link to="/users" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Usuarios</span>
							<span class="info-box-number">{{ users }}</span>
						</div>
						<span class="info-box-icon bg-navy rounded-circle"><i class="far fa-user"></i></span>
					</div>
				</router-link>
			</div>
			<div class="col-md-6 col-lg-4 col-sm-6 col-12" v-can="'buildings'">
				<router-link to="/buildings" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Propiedades</span>
							<span class="info-box-number">{{ buildings }}</span>
						</div>
						<span class="info-box-icon bg-navy rounded-circle"><i class="fas fa-map-marker-alt"></i></span>
					</div>
				</router-link>
			</div>

			<!-- Empresas -->
			<div class="col-md-6 col-lg-4 col-sm-6 col-12" v-can="'companies'">
				<router-link to="/concepts" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Empresas</span>
							<span class="info-box-number">{{ companies }}</span>
						</div>
						<span class="info-box-icon bg-navy rounded-circle"><i class="fa-solid fa-id-card-clip"></i></span>
					</div>
				</router-link>
			</div>

			<!-- Rules -->
			<div class="col-md-6 col-lg-4 col-sm-6 col-12" v-can="'rules'">
				<router-link to="/rules" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Reglas</span>
							<span class="info-box-number">Ver más</span>
						</div>
						<span class="info-box-icon bg-navy rounded-circle"><i
								class="nav-icon fa-solid fa-pen-ruler"></i></span>
					</div>
				</router-link>
			</div>

			<!-- Conceptos -->
			<div class="col-md-6 col-lg-4 col-sm-6 col-12" v-can="'concepts'">
				<router-link to="/concepts" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Conceptos</span>
							<span class="info-box-number">Ver más</span>
						</div>
						<span class="info-box-icon bg-navy rounded-circle"><i class="fa-solid fa-clipboard-list"></i></span>
					</div>
				</router-link>
			</div>



			<!-- Permisos -->
			<div class="col-md-6 col-lg-4 col-sm-6 col-12" v-can="'permissions'">
				<router-link to="/permissions" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Permisos</span>
							<span class="info-box-number">Ver más</span>
						</div>
						<span class="info-box-icon bg-navy rounded-circle"><i class="fas fa-lock"></i></span>
					</div>
				</router-link>
			</div>



		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Catalogue from '@/services/catalogue'
import request from '@/services/request'
import ListBillingProvider from '@/components/billing_data_provider/ListBillingProviderComponent.vue'
import ListBankAccount from '@/components/bank_account_provider/ListBankAccoutProviderComponent.vue';
import ListContact from '@/components/contanct_provider/ListContanctProviderComponent.vue';
import ModalFormComponent from '@/components/bill/ModalFormComponent.vue';
// import ListChecks from '@/pages/checks/index.vue';
import ListBill from '@/pages/bill/index.vue';
//import ListProvider from '@/components/checks/ListProviderComponent.vue'
import ListPay from '@/pages/pays/index.vue'
export default {
	components: {
		ListBankAccount,
		ListContact,
		ModalFormComponent,
		// ListChecks,
		// ListProvider,
		ListBill,
		ListPay,
		ListBillingProvider
	},
	data() {
		return {
			load : false,
			id_provider: '',
			provider: '',
			users: 0,
			buildings: 0,
			projects: 0,
			companies: 0,
			catalogue: new Catalogue(),
		}
	},
	methods: {
		newDepartment(account = {}) {
			if (this.authUser.organization_charts_id == 2) {
				let result = this.catalogue.validatePaymentPlugin;
				if (result.length > 0) {
					this.$swal({
                    icon: 'error',
                    title: 'No puedes subir facturas',
                    text: 'Es necesario subir el complemento de pago',
                }).then(() => {
                    this.$router.replace({ name: "pays_paid", query: { id: result[0].id } });
                });
				} else {
					Event.$emit('event-show-addBill', account);
				}
			} else {
				Event.$emit('event-show-addBill', account);

			}

		},
		handler() {
			var args = arguments;
			for (var arg of args) {
				if (arg instanceof Function) {
					arg();
				}
			}
		},
		async fecthAssements(type) {
			let service = request;
			let _endpoint;
			if (type == 'users') {
				_endpoint = '/users';
			}
			if (type == 'buildings') {
				_endpoint = '/buildings';
			}

			if (type == 'companies') {
				_endpoint = '/companies';
			}


			try {
				let { data } = await service.get(_endpoint)
				if (data) {
					if (type == 'users') {
						this.users = data.total;
					}
					if (type == 'buildings') {
						this.buildings = data.total;
					}
					if (type == 'companies') {
						this.companies = data.total;
					}

				}
			} catch (error) {
				console.log(error);
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async get_id(id) {
			let _endpoint = `/users/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.provider = data;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			errorNotification: 'alert/error',
		})
	},
	async mounted() {
		this.load = true;

		await this.fecthAssements('users');
		await this.fecthAssements('buildings');
		await this.fecthAssements('companies');
		await this.setHeaderTitle('Panel principal');
		// Proveedor
		if (this.authUser.organization_charts_id == 2) {
		await this.catalogue.get_validate_payment_plugin();
			this.id_provider = this.authUser.id;
			await this.get_id(this.id_provider);
		}
		this.load = false;

	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	}
};
</script>
