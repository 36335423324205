<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Caja chica</h3>
				<div class="card-tools" v-if="authUser.organization_charts_id == 5">
					<button @click="showPettyCash()" class="btn btn-sm btn-outline-success">
						<i class="fa fa-plus"></i> Crear reembolso
					</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-12 p-2" v-if="authUser.organization_charts_id == 1">
						<div class="row">
							<div class="col-6 p-2">
								<label>Propiedad :</label>
								<select class="form-control" v-model="searchAll.building_id">
									<option value="">Todos</option>
									<option :value="building.id" v-for="(building, index) in catalogue.buildings"
										:key="index">
										{{ building.name }}
									</option>
								</select>
							</div>
							<div class="col-6 p-2">
								<label>Residente :</label>
								<select class="form-control" v-model="searchAll.user_id">
									<option value="">Todos</option>
									<option :value="user.id" v-for="(user, index) in catalogue.users_profile"
										:key="index">
										{{ user.name }} {{ user.last_name }}
									</option>
								</select>
							</div>
							<div class="col-12">
								<hr>
							</div>
						</div>
					</div>
					<div class="col-12 text-center p-2" v-if="catalogue.petty_cash_user_load">
						<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
						<h5>Cargando</h5>
					</div>
					<div class="col-lg-4 col-12" v-show="!catalogue.petty_cash_user_load" :key="index"
						v-for="(resident, index) in catalogue.petty_cash_user">
						<div class="small-box"
							:class="(resident.sum_petty_cashes * 1) + (resident.sum_petty_cashes_contri * 1) - (resident.sum_pays * 1) >= 0 ? 'bg-info' : 'bg-danger'">
							<div class="inner">
								<h3>{{ (resident.sum_petty_cashes * 1) + (resident.sum_petty_cashes_contri * 1) - (resident.sum_pays * 1) | toCurrency }}</h3>
								<p class="mb-1" v-if="resident.building">{{ resident.building.name }}</p>
								<p class="mb-1" v-else>S/P</p>
								<span v-if="resident.user">{{ resident.user.name }} {{ resident.user.last_name }}</span>
							</div>
							<div class="icon">
								<i class="fa-solid fa-cash-register"></i>
							</div>
							<a href="#" @click="setFilter(resident.building_id, resident.user_id, resident)"
								class="small-box-footer"> Ver movimientos <i class="fas fa-arrow-circle-right"></i></a>
						</div>
					</div>
					<div class="col-12 text-center p-2"
						v-if="!catalogue.petty_cash_user_load && catalogue.petty_cash_user.length == 0">
						<h3>Sin resultados</h3>
					</div>
				</div>
				<!-- <div class="row">
					<div class="col-md-6">
						<label>Propiedades :</label>
						<select type="text" class="form-control" v-model="search.building_id">
							<option value="">Todos</option>
							<option v-for="(building, index) in catalogue.buildings_resident" :value="building.id"
								:key="index">
								{{ building.name }}</option>
						</select>
					</div>
				</div>
				<br> -->
				<modal name="listPettyCash" :width="'90%'" :maxHeight="300" :height="'auto'"
					v-bind:click-to-close="false">
					<button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
							class="fa-solid fa-xmark"></i> </button>
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">Lista de movimientos</h5>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-6">
								<h4 v-if="residentSelected.building">{{ residentSelected.building.name }}</h4>
								<h6 v-if="residentSelected.user">{{ residentSelected.user.name }}
									{{ residentSelected.user.last_name }}</h6>
							</div>
							<div class="col-6 text-right">
								<div class="card-tools" v-if="authUser.organization_charts_id == 1">
									<button @click="showPettyCont()" class="btn btn-lg btn-sm btn-outline-success">
										<i class="fa fa-plus"></i> Crear aportación
									</button>
								</div>
							</div>

							<div class="col-md-12 mt-3 table-responsive" v-if="search.building_id">
								<table class="table table-bordered text-center text-sm">
									<thead>
										<tr>
											<th>ID</th>
											<th v-if="authUser.organization_charts_id == 1">Residente</th>
											<th>Propiedad</th>
											<th>Empresa</th>
											<th>Tipo</th>
											<th>Monto</th>
											<th>Opciones</th>
										</tr>
									</thead>
									<tbody v-if="load">
										<tr>
											<td align="center" colspan="7">
												<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
												<h5>Cargando</h5>
											</td>
										</tr>
									</tbody>
									<tbody v-else-if="budgets.total > 0">
										<tr :class="buy.amount == buy.sum_pays ? 'bg-info' : ''"
											v-for="(buy, index) in budgets.data" :key="index">
											<td class="text-center" style="width:100px">
												{{ buy.id }}
												<h6>
													<span v-if="buy.amount == buy.sum_pays"
														class="badge badge-success">Pagado</span>
												</h6>
											</td>
											<td class="text-left" v-if="authUser.organization_charts_id == 1">
												<span v-if="buy.user"> {{ buy.user.name }} {{ buy.user.last_name
													}}</span>
												<span v-else>S/N</span>
											</td>
											<td class="text-left">
												<!-- Propiedad -->
												<span v-if="buy.building"> {{ buy.building.name }}</span>
												<span v-else>
													S/N
												</span>
											</td>
											<td class="text-left">
												<span v-if="buy.company"> {{ buy.company.name }}</span>
												<span v-else>
													S/N
												</span>
											</td>
											<td class="text-left">
												<span v-if="buy.type"> {{ buy.type.name }}</span>
												<span v-else>
													S/N
												</span>
											</td>
											<td class="text-right">
												{{ buy.amount | toCurrency }}
											</td>
											<td>
												<button v-if="buy.type_id == 8" @click="showPettyCash(buy, false)" type="button"
													class="btn btn-sm  m-1 btn-outline-secondary ">
													<i class="fa-solid fa-eye"></i> Ver
												</button>

												<button v-if="buy.type_id == 10" @click="showPettyCont(buy, false)" type="button"
													class="btn btn-sm  m-1 btn-outline-secondary ">
													<i class="fa-solid fa-eye"></i> Ver
												</button>

												<button @click="newDepartmentShow(buy.bill, false)" v-if="buy.bill"
													type="button" class="btn btn-sm  m-1 btn-outline-primary ">
													<i class="fa-solid fa-file-invoice-dollar"></i> Ver factura
												</button>

												<button @click="newDepartment(buy, true)" v-if="!buy.bill" type="button"
													class="btn btn-sm  m-1 btn-outline-success ">
													<i class="fa-solid fa-file-invoice-dollar"></i> Crear factura
												</button>


												<button @click="showPettyCash(buy)" v-if="!buy.bill"
													class="btn btn-outline-primary m-1 btn-sm">
													<i class="fas fa-edit "> </i> Editar
												</button>
												<button type="button" @click="deleted(buy.id)" v-if="!buy.bill"
													class="btn-outline-danger btn m-1 btn-sm ">
													<i class="fa fa-trash"></i> Eliminar
												</button>

											</td>
										</tr>
									</tbody>
									<tbody v-else>
										<tr>
											<td align="center" colspan="7">Sin resultados.</td>
										</tr>
									</tbody>
								</table>
								<pagination v-model="page" :records="budgets.total" :per-page="budgets.per_page"
									@paginate="list" />
							</div>

							<div class="col-md-12">
								<hr>
								<button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
									<i class="fas fa-undo-alt"></i>
									Regresar
								</button>
							</div>
						</div>

					</div>
				</modal>

			</div>

		</div>

		<ModalFormComponentBudgets :prop_edit="edit" />
		<ModalFormComponentBill :prop_edit="editBill" />
		<ModalFormComponentBudgetsContributions :prop_edit="editContribution" />
	</div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import Pagination from 'vue-pagination-2';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';
import ModalFormComponentBudgets from '@/components/petty_cash/ModalFormPettyCashComponent.vue'
import ModalFormComponentBudgetsContributions from '@/components/petty_cash/ModalFormPettyCashContributionsComponent.vue'
import ModalFormComponentBill from '@/components/bill/ModalFormComponent.vue';
export default {
	name: "companies-table",
	components: {
		ModalFormComponentBudgetsContributions,
		ModalFormComponentBudgets,
		ModalFormComponentBill,
		Pagination
	},
	data() {
		return {
			name: '',
			budgets: {},
			search: {
				building_id: '',
				user_id: '',
			},
			searchAll: {
				building_id: '',
				user_id: '',
			},
			residentSelected: {},
			edit: true,
			editBill: true,
			editContribution: true,
			page: 1,
			catalogue: new Catalogue(),
			load: false,
			showModal: false,
		}
	},
	mounted() {
		this.catalogue.get_buildings();
		this.catalogue.get_users_profile(5);
		this.list();
		this.catalogue.get_buildings_resident(this.authUser.id);
		this.getSum();
		this.setHeaderTitle('Presupuestos');
	},
	created() {
		Event.$on("event-load-petty-cash", () => {
			this.catalogue.get_petty_cash_user(this.searchAll);
			this.list();
		});
		Event.$on("event-load-petty-cash-bill", async (petty_cash_id, bill_id) => {
			let _endpoint = '/petty_cash_bill';

			let form = {
				id: petty_cash_id,
				bill_id: bill_id
			};

			let service = request;
			await service.post(_endpoint, form)
			await this.list();
		});
	},
	methods: {
		closedModal() {
			this.$modal.hide('listPettyCash');
		},
		setFilter(building_id, user_id, resident) {
			this.residentSelected = resident;
			this.$modal.show('listPettyCash');
			this.search.building_id = building_id;
			this.search.user_id = user_id;
		},

		getSum() {
			this.catalogue.get_petty_cash_user(this.searchAll);
		},
		async newDepartment(account = {}, edit = true) {
			this.editBill = edit;
			Event.$emit('event-show-addBillPettyCash', account);
		},
		async newDepartmentShow(account = {}, edit = true) {
			this.editBill = edit;
			Event.$emit('event-show-addBill', account);
		},
		showPettyCont(movement = false, edit = true) {	
			this.editContribution = edit;
			Event.$emit('event-show-addBillPettyCashCont', this.residentSelected, movement);
		},
		sumdata() {
			let vm = this;
			let sum1 = 0;
			let sum2 = 0;
			let sum3 = 0;
			if (this.budgets) {
				this.budgets.data.forEach(function (buy) {

					sum1 += parseFloat(buy.amount);

					sum2 += vm.paysCheks(buy.checks) + buy.prepaid;

					sum3 += parseFloat(buy.amount) - (vm.paysCheks(buy.checks) + buy.prepaid);


				});
			}

			return [sum1, sum2, sum3];
		},
		showPettyCash(petty = false, edit = true) {
			this.edit = edit;
			Event.$emit('event-show-addPettyCash', petty);
		},
		deleted: function (id) {
			let tit = 'Eliminar reembolso';
			let txt = 'Seguro que quiere eliminar el reembolso';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/petty_cash/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		async list(page = 1) {
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/petty_cash';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.budgets = data;
					this.load = false;
				}
			} catch (error) {
				console.log(error);
				this.load = false;
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	watch: {
		'search': {
			handler: function () {
				this.list();
			},
			deep: true
		},
		'searchAll': {
			handler: function () {
				this.getSum();
			},
			deep: true
		}
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.bg-success {
	background-color: #28a745 !important;
}

.bg-info {
	background-color: #7a8eb7 !important;
}
</style>
