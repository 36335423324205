<template>
	<div class="col-12">
		<div class="card card-outline card-info">
			<div class="card-header">
				<h3 class="card-title">Lista de Conceptos</h3>
				<div class="card-tools">	
					<button @click="downloadExcel()" v-can="'add_concepts'" class="btn btn-sm btn-outline-success mr-1">
						<i class="fa-solid fa-file-excel"></i> Excel
					</button>				
					<router-link :to="`/concepts/form`" v-can="'add_concepts'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Concepto
					</router-link>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search.name" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Nombre</th>
								<th>Categoria</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="concepts">
							<tr v-for="(concept, index) in concepts" :key="index">
								<td>{{ concept.id }}</td>
								<td>{{ concept.name }}</td>								
								<td v-if="concept.category">{{ concept.category.name }}</td>								
								<td v-else>Sin categoria</td>								
								<td>
									<router-link :to="`/concepts/form/${concept.id}`" v-can="'edit_concepts'" class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</router-link>
									<button type="button" @click="deleted(concept.id)" v-can="'deleted_concepts'" class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
					<pagination v-model="page" :records="concepts.total" :per-page="concepts.per_page" @paginate="list" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import Pagination from 'vue-pagination-2';
import request from '@/services/request'
export default {
	name: "concepts-table",
	components: {
		Pagination
	},
	data() {
		return {
			concepts: [],
			search:{
				name:''
			},
			page: 1
		}
	},
	mounted() {
		this.list();
		this.setHeaderTitle('Conceptos');
	},
	created() {
			Event.$on("event-load-concepts", () => {
				this.list();
			});
	},
	methods: {
		downloadExcel() {
			let data = new URLSearchParams(this.search).toString();			
			let url = this.globalUrlFiles + '/concepts/export?'+data;
			window.open(url, '_blank');
		},
		deleted: function (id) {
			let tit = 'Eliminar Concepto';
			let txt = 'Seguro que quiere eliminar al concepto';	

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/concept/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response;});
				this.list();
				this.successNotification(response.data.message);			
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport(){
			this.$modal.show('example');
		},
		async list(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/concept';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.concepts = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
