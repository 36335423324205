<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
    components: {
        DatePicker
    },
    data() {
        return {
            form: {
                date: [null, null],
            },
            valid: false,
            send: false,
        }
    },
    methods: {      
        saveBuildings() {
            this.form.user_logged = this.authUser.id;
            let data = new URLSearchParams(this.form).toString();
            let _url = this.globalUrlFiles + '/bills/download_files?'+data;            
            window.open(_url, '_blank');            
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    created() {
        Event.$on("event-show-downloadFiles", (filter) => {
            this.form = filter;
            console.log(this.form);
            // this.saveBuildings();
            this.$modal.show('downloadFiles');

        });
    },
    computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}
</script>
<template>
    <modal name="downloadFiles" :width="'30%'" :maxHeight="200" :height="'auto'" v-bind:click-to-close="false">
        <div class="row">
            <div class="col-12">
                <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                        class="fa-solid fa-xmark"></i> </button>
                <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
                    <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()">
                        <i class="fa-solid fa-xmark"></i> </button>
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Descargar de archivos</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 mt-3">
                                <label>Fecha</label><br>
                                <date-picker v-model="form.date" required="required" :range="true"
                                    valueType="format"></date-picker>
                                <div v-if="valid" class="text-danger">Es requerida la fecha</div>
                            </div>



                            <div class="col-md-12">
                                <hr>
                                <button type="submit" class="btn float-right btn-outline-success">
                                    <spam v-if="send">
                                        <i class="fa-solid fa-circle-notch fa-spin"></i> Descargando
                                    </spam>
                                    <spam v-else>
                                        <i class="fa-solid fa-download"></i> Descargar
                                    </spam>
                                </button>
                                <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                                    <i class="fas fa-undo-alt"></i>
                                    Regresar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </modal>
</template>