<script>
import { mapGetters } from 'vuex';
import request from '@/services/request';
import Catalogue from '@/services/catalogue';
import ModalForm from '@/components/departments/ModalUserDepartamentComponent.vue';
import ModalTablePays from '@/components/departments/ModalTablePaysComponent.vue';
import ModalFormPayComponent from '@/components/contracts/ModaFormPayComponent.vue';
import ModarServicesExtrasComponent from '@/components/debts/ModarServicesExtrasComponent.vue'
import ModarServicesExtrasUnavailableComponent from '@/components/debts/ModarServicesExtrasUnavailableComponent.vue'
import ModalTablePayComponent from '@/components/contracts/ModalTablePayComponent.vue';
import ModalIncreaseIncome from '@/components/debts/ModalIncreaseIncomeComponent.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ModalNumBillComponent from '@/components/debts/ModaNumBillComponent.vue'
import { mapActions } from 'vuex';

export default {
	name: "debts-table",
	components: {
		ModalForm,
		ModalTablePays,
		ModalFormPayComponent,
		DatePicker,
		ModarServicesExtrasComponent,
		ModarServicesExtrasUnavailableComponent,
		ModalTablePayComponent,
		ModalNumBillComponent,
		ModalIncreaseIncome
	},
	data() {
		return {
			editForm: false,
			editPay: false,
			editIncrease: false,
			load: false,
			catalogue: new Catalogue(),
			page: 1,
			range: false,
			form: {
				mail: '',
				content: '',
			},
			send:false,
			search: {
				date: '',
				department_id: '',
				building_id: '',
				company_id: '',
				user_id: '',
				payment_period_id: [1, 2, 3, 4, 6, 7, 8, 9]
			},
		}
	},
	methods: {
		setNumberBill(data, type, numberBill) {
			Event.$emit('event-show-addNumberServiceTenants', data, type, numberBill);
		},
		toggleExtras(buy) {
			buy.showExtras = !buy.showExtras;
			this.$forceUpdate();
		},
		downloadExcel() {
			let data = new URLSearchParams(this.search).toString();
			let url = this.globalUrlFiles + '/debts/export/excel?number=' + this.authUser.id + '&' + data;
			window.open(url, '_blank');
		},
		downloadDebt() {
			let url = this.globalUrlFiles + '/past_debts/export';
			window.open(url, '_blank');
		},
		callModalMail() {
			this.$modal.show('sendExcel');
		},
		closedModal() {
			this.form.mail = '';
			this.form.content = '';
			this.$modal.hide('sendExcel');
		},
		async sendFormMail() {
			let search = this.search;
			this.send = true;
			search.mail = this.form.mail;
			search.content = this.form.content;
			let data = new URLSearchParams(this.search).toString();
			let url = '/debts/export/email?number=' + this.authUser.id + '&' + data;
			try {
				let service = request;
				let { data } = await service.get(url)
				if (data) {
					this.send = false;
					this.successNotification(data.message);
					this.closedModal();
				}
			} catch (error) {
				console.log(error);
				this.send = false;
			}
		},
		showPays(id) {
			Event.$emit('event-show-PayContracts', id);
		},
		calculateTotalAmount(pay) {
			// Calcular la suma de los montos de pagos
			return pay.checks.reduce((total, pay) => total + parseFloat(pay.amount), 0);
		},
		async formDataPaysFast(pay, check = {}, edit = true) {
			let vm = this;
			vm.editPay = edit;
			Event.$emit('event-show-addPayContracts', pay, check);

			// try {
			//     this.$swal({
			// 		title: 'Se realizo el pago',
			// 		text: '¿ Se aprobara el pago ?',
			// 		icon: 'warning',
			// 		showCancelButton: true,
			// 		confirmButtonColor: '#3085d6',
			// 		cancelButtonColor: '#d33',
			// 		confirmButtonText: 'Pagado',
			// 		cancelButtonText: 'Cancelar'
			// 	}).then(async (result) => {
			// 		if (result.isConfirmed) {
			//             let form = {
			//                 tenant_id: pay.id,
			//                 bank_id: pay.bank_account_id,
			//                 date: this.moment().format("YYYY-MM-DD"),
			//                 amount: pay.amount,
			//                 type_payment_id: pay.type_payment_id,
			//                 description: pay.description
			//             };
			//             let _endpoint = '/tenant_pay';

			//             let { data } = await request.postMedia(_endpoint, form)
			//             if (data) {
			//                 this.successNotification(data.message);
			//                 this.list();
			//             }
			//         }
			//     });
			// } catch (error) {
			//     this.$modal.hide('LoadPay');
			//     this.errors = {};
			//     if (error.response) {
			//         this.errors = await this.errorNotification(error);
			//     }
			// }
		},
		async listExtrasServices(tenant) {
			Event.$emit('event-list-extra-services', tenant);
		},
		async IncreaseIncomes(tenant, increase) {
			Event.$emit('event-show-addIncreaseIncomes', tenant, increase);
		},
		async listExtrasServicesUnavailable(tenant) {
			Event.$emit('event-list-extra-services-unavailable', tenant);
		},
		async list(page = 1) {
			this.load = true;
			let service = request;
			let search = this.search;
			search.search = this.name;
			let _endpoint = '/debt';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.buys = data;
					this.load = false;
				}
			} catch (error) {
				console.log(error);
				this.load = false;
			}
		},
		deleted: function (id) {
			let tit = 'Eliminar contrato';
			let txt = 'Seguro que quiere eliminar el contrato';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Eliminar',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/tenant/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		editBuy(buy = {}, edit = true) {
			this.editForm = edit;
			Event.$emit('event-show-addDepartamentUser', buy);
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	mounted() {
		let DateBill = this.moment().format('YYYY-MM');
		this.search.date = DateBill;
		this.list();
		this.catalogue.get_buildings();
		this.catalogue.get_companies_all();
		this.catalogue.get_users_profile(7);
	},
	created() {
		Event.$on("event-load-debts", () => {
			this.list();
		});
	},
	watch: {
		'search': {
			handler: function () {
				this.list();
			},
			deep: true
		},
		'range': {
			handler: function (value) {
				if (value) {

					let monthIni = this.moment().format('YYYY-MM')
					let monthEnd = this.moment().add(1, 'M').format('YYYY-MM')
					this.search.date = [monthIni, monthEnd];

				} else {
					let month = this.moment().format('YYYY-MM')
					this.search.date = month;
				}

			},
			deep: true
		}
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}

</script>
<template>
	<div class="col-12">
		<ModalFormPayComponent :prop_edit="editPay" />
		<ModalForm :prop_edit="editForm" />
		<ModalTablePayComponent :prop_edit="true" />
		<ModalTablePays />
		<ModalNumBillComponent />
		<ModarServicesExtrasComponent :prop_edit="true" />
		<ModarServicesExtrasUnavailableComponent :prop_edit="true" />
		<ModalIncreaseIncome :prop_edit="editIncrease" />
		<modal name="sendExcel" :width="'80%'" :maxWidth="500" :maxHeight="300" :height="'auto'"
			v-bind:click-to-close="false">
			<form @submit.prevent="sendFormMail" method="post" enctype="multipart/form-data">
				<button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()">
					<i class="fa-solid fa-xmark"></i> </button>
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">Información reporte seleccionado</h5>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-12">
							<label>Correos a enviar</label><br>
							<input type="text" class="form-control" required v-model="form.mail">
							<div class="text-info">Se pueden enviar corro separado por comas (ejemplo, ejemplo)</div>

						</div>
						<div class="col-md-12 mt-2">
							<label>Cuerpo del correo</label><br>
							<textarea type="text" class="form-control" v-model="form.content">
							</textarea>
						</div>
						<div class="col-md-12">
							<hr>
							<button type="submit" :disabled="send" class="btn float-right btn-outline-success">
								<spam>
									<i class="fa-regular fa-paper-plane"></i> Enviar
								</spam>
							</button>
							<button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
								<i class="fas fa-undo-alt"></i>
								Regresar
							</button>
						</div>
					</div>
				</div>
			</form>
		</modal>
		<div class="card card-outline card-info">
			<div class="card-header">
				<h3 class="card-title">Lista de adeudos</h3>
				<div class="card-tools">
					<button @click="downloadDebt()" class="btn btn-sm btn-outline-success mr-1">
						<i class="fa-regular fa-file-excel ml-1 mr-1"></i> Deudas pasadas
					</button>
					<button @click="callModalMail()" class="btn btn-sm btn-outline-success mr-1">
						<i class="fa-regular fa-paper-plane"></i> PDF
					</button>
					<button @click="downloadExcel()" class="btn btn-sm btn-outline-success mr-1">
						<i class="fa-solid fa-file-excel"></i> Excel
					</button>
					<!-- <button @click="editBuy()" v-can="'add_contracts'" class="btn btn-sm btn-outline-info">
                        <i class="fa fa-plus"></i> Contrato
                    </button> -->
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-2">
						<label>Rango :</label>
						<input type="checkbox" v-model="range" class="form-control from-control-sm">
					</div>
					<div class="col-md-5">
						<label>Mes de reporte :</label>
						<date-picker format="YYYY-MM" :range="range" valueType="format" type="month" :clearable="false"
							v-model="search.date"></date-picker>
					</div>
					<div class="col-md-5">
						<label>Propiedad :</label>
						<select class="form-control" v-model="search.building_id" @change="list()">
							<option value="">Todas</option>
							<option :value="building.id" v-for="(building, index) in catalogue.buildings" :key="index">
								{{ building.name }}</option>
						</select>
					</div>
					<div class="col-md-6">
						<label>Empresa :</label>
						<select class="form-control" v-model="search.company_id" @change="list()">
							<option value="">Todas</option>
							<option :value="company.id" v-for="(company, index) in catalogue.companies_all"
								:key="index">{{ company.name }}</option>
						</select>
					</div>
					<div class="col-md-6">
						<label>Cliente :</label>
						<select class="form-control" v-model="search.user_id" @change="list()">
							<option value="">Todas</option>
							<option :value="user.id" v-for="(user, user_index) in catalogue.users_profile"
								:key="user_index">{{ user.name }} {{ user.last_name }}</option>
						</select>
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table tbl-pays table-bordered text-center text-sm">
						<thead>
							<tr>
								<th style="width: 70px;">ID</th>
								<th>Cliente</th>
								<th>No. Contrato</th>
								<th>Propiedad</th>
								<th>Indiviso</th>
								<th>Servicio</th>
								<th>Monto</th>
								<th>Pagado</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="8">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="buys.length > 0">
							<tr :class="buy.increase ? 'bg-info' : ''" v-for="(buy, index) in buys" :key="index">
								<td colspan="9" class="p-0">
									<table class="table  tbl-pays table-bordered text-center text-sm m-0">
										<tr>
											<td style="width: 70px;">
												{{ buy.id }}
												<div class="mt-3" v-if="buy.increase">
												<i class="fa-solid fa-money-bill-trend-up text-warning fa-xl fa-fade" v-if="buy.increase.check ==  true">
												</i>
											</div>
												<br>
												<button @click="toggleExtras(buy)"
													v-if="buy.services_extras_unavailable.length > 0"
													class="btn btn-outline-primary btn-sm">
													{{ buy.showExtras ? '-' : '+' }}
												</button>


											</td>
											<td>
												<span v-if="buy.user">
													{{ buy.user.name }} {{ buy.user.last_name }}
												</span>
												<span v-else>Sin usuario</span>
											</td>
											<td>
												<span v-if="buy.tenant_id">
													{{ buy.tenant_id }}
												</span>
												<span v-else>{{ buy.id }}</span>
											</td>
											<td>
												<span v-if="buy.building">
													{{ buy.building.name }}
												</span>
												<span v-else>Sin propiedad</span>
											</td>
											<td>
												<span v-if="buy.department">
													{{ buy.department.level }} / {{ buy.department.number }}
												</span>
												<span v-else>Sin departamento</span>
											</td>
											<td>
												<span v-if="buy.service">
													{{ buy.service.name }}
												</span>
												<span v-else>S/N</span>
												<br>
												<span v-if="buy.star_date"> {{ buy.star_date | toDateTime }} </span>

											</td>

											<td class="text-right">
												{{ buy.rent | toCurrency }} <i class="fa-solid fa-link"
													@click="setNumberBill(buy, 'tenant', buy.number_bill)"
													:class="buy.number_bill ? 'text-info' : 'text-danger'"></i>
											</td>

											<td class="text-right">
												{{ buy.pay_rent | toCurrency }}
											</td>

											<td>

												<button v-if="buy.checks.length == 0"
													@click="listExtrasServicesUnavailable(buy)"
													class="btn btn-outline-secondary m-1 btn-sm">
													<i class="fa-solid fa-wallet mr-1"></i>
													<span>Extras</span>
												</button>

												<button v-if="buy.checks.length == 0" @click="listExtrasServices(buy)"
													class="btn btn-outline-secondary m-1 btn-sm">
													<i class="fa-solid fa-wallet mr-1"></i>
													<span>Ajuste a monto de renta</span>
												</button>


												<button @click="showPays(buy.id)"
													class="btn btn-outline-info m-1 btn-sm">
													<i class="fa-solid fa-hand-holding-dollar"></i>
													Pagos
												</button>

												<button v-if="buy.increase" @click="IncreaseIncomes(buy, buy.increase_rent)"
													class="btn m-1 btn-sm" :class="buy.increase_rent ? 'btn-outline-success' : 'btn-outline-warning' ">
													<i class="fa-solid fa-money-bill-trend-up"></i>
													<span v-if="buy.increase_rent">Ver</span> Aumentar Renta
												</button>

												<!-- <button v-if="buy.checks.length == 0" @click="formDataPaysFast(buy)"
										class="btn btn-outline-info m-1 btn-sm">
										<i class="fa-regular fa-handshake mr-1"></i>
										<span>Pagar </span>
									</button>

									<button v-if="buy.checks.length > 0"
										@click="formDataPaysFast(buy, buy.checks, false)"
										class="btn btn-outline-success m-1 btn-sm">
										<i class="fa-solid fa-receipt mr-1"></i>
										<span>Pagado</span>
									</button> -->
											</td>
										</tr>
										<tr class="table-secondary" v-show="buy.showExtras"
											v-for="(buyR, indexR) in buy.services_extras_unavailable" :key="indexR">
											<td colspan="6">
												{{ buyR.name }}
											</td>
											<td>
												{{ buyR.amount | toCurrency }}
												<i class="fa-solid fa-link"
													@click="setNumberBill(buyR, 'service', buyR.number_bill)"
													:class="buyR.number_bill ? 'text-info' : 'text-danger'"></i>
											</td>
											<td>
												{{ buyR.pay | toCurrency }}
											</td>
											<td></td>
										</tr>
										<tr>
											<td colspan="6">
												Total a pagar
											</td>
											<td>
												{{ buy.amount | toCurrency }}
											</td>
											<td rowspan="2" colspan="2">
												<p class="">Resta</p>
												<h3 class="mt-0">
													{{ buy.amount - calculateTotalAmount(buy) | toCurrency }}
												</h3>
											</td>
										</tr>
										<tr>
											<td colspan="6">
												Pagado
											</td>
											<td>
												{{ calculateTotalAmount(buy) | toCurrency }}
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="8">Sin resultados.</td>
							</tr>
						</tbody>

					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<style type="text/css">
.tbl-pays {
	table-layout: fixed;
	min-width: 1000px;
	width: 100%;
}
</style>