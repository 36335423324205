<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import vueSignature from 'vue-signature'
export default {
    components: {
        vueSignature
    },
    data() {
        return {
            errors: {},
            check: {},
            date: new Date(),
            submitted: false,
            form: {
                id: '',
                signature: '',
            },
            option: {
                penColor: "rgb(0,0,0)",
                backgroundColor: "rgba(255,255,255, 0)"
            }
        }
    },
    methods: {
        closedModal() {
            this.form.id = ''
            this.form.signature = '';
            this.$modal.hide('addSiganturePay');
        },
        clear() {
            var _this = this;
            _this.$refs.signature1.clear();
        },
        async saveBuildings() {

            this.submitted = true;
            let signature = "";
           
            if (this.$refs.signature1) {
                const _empty = await this.$refs.signature1.isEmpty();
                if(!_empty){
                    signature = this.$refs.signature1.save();
                }
            }

            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('signature', signature)
            data_form.append('_method', 'POST');

            let _endpoint = '/bill_signature';

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    // Event.$emit('event-load-checks');
                    Event.$emit('event-load-checks');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-addSiganturePay", async (bill) => {
            this.check = bill.check;
            this.form.id = bill.id;
            this.$modal.show('addSiganturePay');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }
}
</script>
<template>
    <modal name="addSiganturePay" :width="'80%'" :maxHeight="380" :adaptive="true" :maxWidth="800" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Firmar de pago</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-6">
                                <p style="font-size:16px;">
                                    Monto a pagar : <br>
                                    <strong>
                                        {{ check.amount_payable | toCurrency }}
                                    </strong>
                                </p>
                            </div>
                            <div class="col-6 text-right">
                                <p style="font-size:16px;">
                                    Fecha : <br>
                                    <strong>
                                        {{ date | toDateTime }}
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 text-center">
                        <h3>Firma</h3>
                        <div class="row">
                        <div class="col-12" style="border:1px solid #000; border-radius: 10px;">
                        <vueSignature ref="signature1" :h="'70%'" :sigOption="option"></vueSignature>
                        <hr>

                    </div>
                </div>
                        <div v-if="errors && errors.signature" class="text-danger text-center">{{
                            errors.signature[0] }}</div>
                        <button class="btn btn-sm btn-outline-info mt-3" type="button" @click="clear">Limpiar</button>

                    </div>
                    <div class="col-md-12">
                        <button type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>