<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
	name: "users-table",
	components: {
		DatePicker
	},
	data() {
		return {
			dataGeneral: {},
			dataGeneralR: {},
			search: {
				date: '',
				user_id: '',
				type: 1
			},
			page: 1,
			catalogue: new Catalogue(),
		}
	},
	mounted() {
		this.catalogue.get_users_profile(2);
		this.setHeaderTitle('Usuarios');
	},

	methods: {
		downloadExcel() {
			let data = new URLSearchParams(this.search).toString();			
			let url = this.globalUrlFiles + '/report/export?number=' + this.authUser.id + '&' + data;
			window.open(url, '_blank');
		},

		async list(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/report_result';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					this.dataGeneral = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		async listR(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/report_summary';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					this.dataGeneral = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	watch: {
		'search': {
			handler: function () {
				this.list();
				this.listR();
			},
			deep: true
		}
	},
}
</script>

<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de proveedores</h3>
				<div class="card-tools">
					<button @click="downloadExcel()" class="btn btn-sm btn-outline-success mr-1">
						<i class="fa-solid fa-file-excel"></i> Excel
					</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Fecha de emisión</label>
						<date-picker v-model="search.date" range valueType="format"></date-picker>
					</div>
					<div class="col-md-6">
						<label>Proveedor</label>
						<select type="text" class="form-control" v-model="search.user_id">
							<option value="">Seleccione un proveedor</option>
							<option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">{{
						user.name }} {{ user.last_name }}</option>
						</select>
					</div>
				</div>
				<br>
				<div v-if="search.date == '' || search.user_id == ''" class="row">
					<div class="col-12">
						<div class="card">
							<div class="card-body text-center p-3">

								<i class="fa-solid fa-circle-exclamation fa-5x mb-3"></i>
								<h5 v-if="search.date == ''">Se requiere fecha para filtrar</h5>
								<h5 v-else-if="search.user_id == ''">Se requiere un proveedor para filtrar</h5>
							</div>
						</div>
					</div>
				</div>

				<div v-else class="table-responsive">
					<div class="row">

						<div class="col-md-12">
							<ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" @click="search.type = 1" data-toggle="pill" role="tab"
										aria-selected="true">Estado de resultados</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" @click="search.type = 2" data-toggle="pill" role="tab"
										aria-selected="false">Resumen</a>
								</li>
							</ul>
						</div>
					</div>
					<table class="table table-bordered text-sm" v-if="search.type == 1">
						<thead class="thead-light">
							<tr>
								<th colspan="3">EGRESOS GENERALES</th>
							</tr>
						</thead>
						<tbody v-if="dataGeneral">
							<tr class="table-primary">
								<td><strong>{{ dataGeneral[0].name }}</strong> </td>
								<td></td>
								<td class="text-right">{{ dataGeneral[0].sum | toCurrency }}</td>
							</tr>
							<tr v-for="(catFather, index) in dataGeneral[0].categories" :key="index">
								<td>{{ catFather.name }}</td>
								<td class="text-right">{{ catFather.sum | toCurrency }}</td>
								<td></td>
							</tr>
							<tr class="table-primary">
								<td><strong>{{ dataGeneral[1].name }}</strong> </td>
								<td></td>
								<td class="text-right">{{ dataGeneral[1].sum | toCurrency }}</td>
							</tr>
							<tr v-for="(catFather, index) in dataGeneral[1].categories" :key="index">
								<td>{{ catFather.name }}</td>
								<td class="text-right">{{ catFather.sum | toCurrency }}</td>
								<td></td>
							</tr>
							<tr class="table-dark">
								<td><strong>SUB TOTAL DE EGRESOS</strong> </td>
								<td></td>
								<td class="text-right">{{ (dataGeneral[0].sum + dataGeneral[1].sum) | toCurrency }}</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
					<table class="table table-bordered text-sm" v-if="search.type == 2">
						<thead class="thead-light">
							<tr>
								<th colspan="5">EGRESOS GENERALES</th>
							</tr>
						</thead>
						<tbody v-if="dataGeneral">
							<tr class="table-primary">
								<td colspan="4"><strong>{{ dataGeneral[0].name }}</strong> </td>
								<td class="text-right">{{ dataGeneral[0].sum | toCurrency }}</td>
							</tr>
							<template v-for="(catFather, index) in dataGeneral[0].categories">
								<tr :key="'head-'+index">
									<td colspan="5">{{ catFather.name }}</td>
								</tr>
								<tr :key="'body-'+index" v-for="(mov, index) in catFather.movements">
									<td>{{ mov.date | toDateTime }}</td>
									<td>
										<span v-if="mov.type_payment">
											{{ mov.type_payment.name }}
										</span>
										<span v-else>
											S/N
										</span>
									</td>
									<td>
										<span v-if="mov.bill.user_bill">
											{{ mov.bill.user_bill.business_name }}
										</span>
										<span v-else>
											S/N
										</span>
									</td>
									<td>
										{{ mov.description }}
									</td>
									<td class="text-right">{{ mov.amount_payable | toCurrency }}</td>
								</tr>
								<tr :key="'foot-'+index" v-if="catFather.movements.length > 0">
									<td colspan="4"></td>
									<td class="text-right">{{ catFather.sum | toCurrency }}</td>
								</tr>
							</template>

							<tr class="table-primary">
								<td colspan="4"><strong>{{ dataGeneral[1].name }}</strong> </td>
								<td class="text-right">{{ dataGeneral[1].sum | toCurrency }}</td>
							</tr>
							<template v-for="(catFather, index) in dataGeneral[1].categories">
								<tr :key="'head-'+index">
									<td colspan="5">{{ catFather.name }}</td>
								</tr>
								<tr :key="'body-'+index" v-for="(mov, index) in catFather.movements">
									<td>{{ mov.date | toDateTime }}</td>
									<td>
										<span v-if="mov.type_payment">
											{{ mov.type_payment.name }}
										</span>
										<span v-else>
											S/N
										</span>
									</td>
									<td>
										<span v-if="mov.bill.user_bill">
											{{ mov.bill.user_bill.business_name }}
										</span>
										<span v-else>
											S/N
										</span>
									</td>
									<td>
										{{ mov.description }}
									</td>
									<td class="text-right">{{ mov.amount_payable | toCurrency }}</td>
								</tr>
								<tr :key="'foot-'+index" v-if="catFather.movements.length > 0">
									<td colspan="4"></td>
									<td class="text-right">{{ catFather.sum | toCurrency }}</td>
								</tr>
							</template>
							
							<tr class="table-dark">
								<td colspan="4"><strong>SUBTOTAL DE EGRESOS</strong> </td>								
								<td class="text-right">{{ (dataGeneral[0].sum + dataGeneral[1].sum) | toCurrency }}</td>
							</tr> 
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>


<style scoped>
.pagination {
	margin-bottom: 0;
}

td {
	vertical-align: middle;
}

.table {
	margin-bottom: 0px;
}
</style>
