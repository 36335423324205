<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Usuarios</h3>
				<div class="card-tools">
					<button @click="downloadExcel()" v-can="'add_users'" class="btn btn-sm btn-outline-success mr-1">
						<i class="fa-solid fa-file-excel"></i> Excel
					</button>
					<router-link :to="`/users/form`" v-can="'add_users'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Usuario
					</router-link>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6" v-if="this.authUser.organization_charts_id == 1">
						<label>Perfil :</label>
						<select type="text" class="form-control" v-model="search.organization_charts_id">
							<option value="">Todos</option>
							<option :value="profile.id" v-for="(profile, index) in catalogue.profiles" :key="index">
								<span v-if="profile.profil">{{ profile.profil.name }}</span>
								<span v-if="profile.area">- {{ profile.area.name }}</span>
							</option>
						</select>
					</div>
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search.name" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm table-striped">
						<thead>
							<tr>
								<th>ID</th>
								<th>Nombre / RFC </th>
								<th>Correo / Teléfono</th>
								<th style="width:150px;">Perfil</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="5">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="users.total > 0">
							<tr v-for="(user, index) in users.data" :key="index">
								<td>
									{{ user.id }}
								</td>
								<td>
									{{ user.name }}
									<div class="col-12" v-if="user.bill_data.length > 0">
										<hr>
										<p v-for="(bill, index_bill) in user.bill_data" :key="index_bill">
											<strong>{{ bill.business_name }}</strong> <br> {{ bill.rfc }}<br> 
											<a class="btn btn-info btn-sm f-10" target="_blank" v-if="bill.cedula"
												:href="`${globalUrlFiles}${bill.cedula}`">
												<i class="fa-regular fa-file-pdf"></i> Ver cédula fiscal
											</a>
											<small v-else class="btn_now badge badge-secondary"> Sin caratula
												fiscal</small>
										</p>
									</div>
									<div class="col-12" v-if="user.bank_accounts.length > 0">
										<hr>
										<p v-for="(bank_account, index_back_account) in user.bank_accounts"
											:key="index_back_account">

											<strong>{{ bank_account.bank.name }}</strong> <br>
											{{ bank_account.account_number }} <br> 
											<a class="btn btn-info btn-sm" target="_blank" v-if="bank_account.file"
												:href="`${globalUrlFiles}${bank_account.file}`">
												<i class="fa-regular fa-file-pdf"></i> Ver caratula de banco
											</a>
											<small v-else class="btn_now badge badge-secondary"> Sin caratula de
												banco</small>
										</p>
									</div>


								</td>
								<td>
									{{ user.email }} <br>
									{{ user.phone }}
								</td>
								<td v-if="user.profile">
									<strong v-if="user.profile.area">{{ user.profile.area.name }}</strong>
									<p class="mb-1" v-if="user.profile.profil">{{ user.profile.profil.name }}</p>
									<span
										style="border: 1px solid #27a745; margin:2px; border-radius: 10px; padding:5px;"
										v-if="user.approved == 1 && user.organization_charts_id == 2">
										<i class="fa-solid fa-circle-check text-success m-1"></i> Aprobado
									</span>
								</td>
								<td v-else>Sin perfil</td>
								<td>
									<router-link :to="`/users/show/${user.id}`"
										class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-eye "> </i> Ver más
									</router-link>
									<router-link :to="`/users/form/${user.id}`" v-can="'edit_users'"
										class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</router-link>
									<button type="button" @click="deleted(user.id)" v-can="'deleted_users'"
										class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
				<pagination v-model="page" :records="users.total" :per-page="users.per_page" @paginate="list" />

			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import Pagination from 'vue-pagination-2';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
	name: "users-table",
	components: {
		Pagination,
	},
	data() {
		return {
			users: {},
			search: {
				organization_charts_id: '',
				name: '',
			},
			page: 1,
			catalogue: new Catalogue(),
			load: false,
		}
	},
	mounted() {
		this.list();
		this.catalogue.getProfiles();
		this.setHeaderTitle('Usuarios');
	},
	created() {
		Event.$on("event-load-users", () => {
			this.list();
		});
	},
	methods: {
		downloadExcel() {
			let data = new URLSearchParams(this.search).toString();
			let url = this.globalUrlFiles + '/users/export?number=' + this.authUser.id + '&' + data;
			window.open(url, '_blank');
		},
		deleted: function (id) {
			let tit = 'Eliminar usuario';
			let txt = 'Seguro que quiere eliminar al usuario';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/users/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list(page = 1) {
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/users';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.users = data;
					this.load = false;
				}
			} catch (error) {
				console.log(error);
				this.load = false;
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	watch: {
		'search': {
			handler: function () {
				this.list();
			},
			deep: true
		}
	},
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

td {
	vertical-align: middle;
}
</style>
