<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                complement_pdf: '',
                complement_xml: '',
            },
            bill: {
                check: {}
            },
            file: '',
            fileName: '',
            xml: '',
            xmlName: '',
        }
    },
    methods: {
        downloadFile(type, id) {
            let url = this.globalUrlFiles + '/bills/download_file?id=' + id + '&type=' + type;
            window.open(url, '_blank');
        },
        closedModal() {
            this.form.id = ''
            this.form.complement_pdf = '';
            this.form.complement_xml = '';
            this.errors = {};
            this.fileName = '';
            this.xmlName = '';
            this.file = '';
            this.xml = '';
            this.$modal.hide('addPaymentPlugin');
        },
        catch_file(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.complement_pdf = event.target.files[0];
                this.fileName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        catch_xml(event) {
            console.log(event.target.files[0].type);
            if (event.target.files[0].type == "text/xml") {
                this.form.complement_xml = event.target.files[0];
                this.xmlName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos XML',
                });
            }
        },
        async saveBuildings() {
            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('complement_pdf', this.form.complement_pdf)
            data_form.append('complement_xml', this.form.complement_xml)


            let _endpoint = '/bill_complement';

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-checks');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-addPaymentPlugin", (bill) => {
            let vm = this;
            if (bill) {
                vm.bill = bill;
                vm.form.id = bill.id;
                if (bill.complement_file) {
                    vm.file = bill.complement_file;
                }else{
                    vm.file = null;
                }
                if (bill.complement_xml) {
                    vm.xml = bill.complement_xml;
                }else{
                    vm.xml = null;
                }
            }
            this.$modal.show('addPaymentPlugin');

        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }
}
</script>
<template>
    <modal name="addPaymentPlugin" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Complemento de pago</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <h4>Pagos</h4>
                        <hr>
                        <div class="table-responsive">
                            <table class="table table-bordered text-center text-sm">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Pago generado por</th>
                                        <th>Fecha de pago</th>
                                        <th>Banco</th>
                                        <th>Monto</th>
                                        <th>Archivo</th>
                                    </tr>
                                </thead>
                                <tbody v-if="bill.check">
                                    <tr v-for="(pay, index) in bill.check.pay" :key="index">
                                        <td>{{ pay.id }}</td>
                                        <td>
                                            <span v-if="pay.user_approved"> {{ pay.user_approved.name }} {{
                                                pay.user_approved.last_name }}</span>
                                            <span v-else>Sin usuario</span>
                                        </td>
                                        <td>
                                            {{ pay.date | toDateTime }}
                                        </td>
                                        <td>
                                            <div v-if="pay.bank_accounts">
                                                <span v-if="pay.bank_accounts.bank">
                                                    {{ pay.bank_accounts.bank.name }}
                                                </span>
                                                {{ pay.bank_accounts.number }}
                                            </div>
                                            <div v-else>
                                                S/N
                                            </div>
                                        </td>
                                        <td>
                                            <span v-if="pay.is_credit == 1">
                                                {{ (parseFloat(pay.amount) + parseFloat(pay.credit)) | toCurrency }}
                                            </span>
                                            <span v-else>
                                                {{ pay.amount | toCurrency }}

                                            </span>
                                        </td>
                                        <td>
                                            <a :href="`${globalUrlFiles}${pay.file}`" v-if="pay.file" target="_blank"
                                                style="border-radius: 5px 0px 5px 0px;"
                                                class="btn btn-outline-info m-1 btn-sm">
                                                <i class="fa-regular fa-file-pdf"></i> Archivo
                                            </a>
                                            <p v-else>
                                                Sin archivo
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td align="center" colspan="6">Sin pagos.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-12">
                        <hr>
                    </div>


                    <div class="col-md-6">
                        <label>PDF</label><br>
                        <div class="custom-file" :style="file ? 'width: 87%' : 'width: 100%'">
                            <input type="file" class="custom-file-input" :class="file ? 'pr-5' : ''" id="customFile"
                                ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <button v-if="file"
                            class="btn btn-outline-info" @click="downloadFile('complement_pdf', form.id)">
                            <i class="fas fa-file "> </i>
                        </button>
                        <br>
                        <div v-if="errors && errors.complement_pdf" class="text-danger">{{ errors.complement_pdf[0] }}</div>

                    </div>
                    <div class="col-md-6">
                        <label>XML</label><br>
                        <div class="custom-file" :style="xml ? 'width: 87%' : 'width: 100%'">
                            <input type="file" class="custom-file-input" :class="xml ? 'pr-5' : ''" id="customFile"
                                ref="file" @change="catch_xml">
                            <label class="custom-file-label text-left" for="customFile">{{ xmlName }}</label>
                        </div>
                        <button v-if="file"
                            class="btn btn-outline-info" @click="downloadFile('complement_xml', form.id)">
                            <i class="fas fa-file "> </i>
                        </button>
                        <br>
                        <div v-if="errors && errors.complement_xml" class="text-danger">{{ errors.complement_xml[0] }}</div>

                    </div>


                    <div class="col-md-12">
                        <hr>
                        <button v-if="!file" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>