<script>
import ModalFormComponentPays from '@/components/pays/ModalFormComponent.vue';
import request from '@/services/request'
import { mapActions } from 'vuex';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    components: {
        ModalFormComponentPays
    },
    data() {
        return {
            errors: {},
            check: {},
            pays: {}
        }
    },
    methods: {
        closedModal() {
            this.pays = {};
            Event.$emit('event-load-checks');
            this.$modal.hide('ListPays');
        },
        formDataPays(check, edit = {}) {
            Event.$emit('event-show-addPays', check, edit);
        },
        async list() {
            let service = request;
            let search = {
                'id': this.check.id
            };
            let _endpoint = '/catalogue/list_pay_check';
            try {
                let { data } = await service.get(_endpoint, search)
                if (data) {
                    console.log(data);
                    this.pays = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        deleted: function (id) {
            let tit = 'Eliminar pago';
            let txt = 'Seguro que quiere eliminar la pago';

            this.$swal({
                title: tit,
                text: txt,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.callDeleted(id);
                }
            });
        },
        async callDeleted(id) {
            let _endpoint = '/pays/' + id;
            try {
                let response = await request.destroy(_endpoint).then(function (response) { return response; });
                this.list();
                this.successNotification(response.data.message);
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-listPays", (check) => {
            this.check = check;
            this.list();
            this.$modal.show('ListPays');
        });
        Event.$on("event-load-pays", () => {
            this.list();
        });
    }
}
</script>
<template>
    <modal name="ListPays" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Lista de pagos</h5>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-6">
                            <h6>Monto de factura : {{ check.amount | toCurrency }} </h6>
                            <h6>Monto a pagar : {{ check.amount_payable | toCurrency }} </h6>
                        </div>
                       
                        <div class="col-md-6">
                            <button v-if="!prop_edit" @click="formDataPays(check)" v-can="'add_pays'"
                                class="float-right btn btn-sm btn-outline-info">
                                <i class="fa fa-plus"></i> Pago
                            </button>
                        </div>
                    </div>

                    <div class="clearfix"></div>
                    <hr>
                    <div class="table-responsive">
                        <table class="table table-bordered text-center text-sm">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Pago generado por</th>
                                    <th>Fecha de pago</th>
                                    <th>Banco</th>
                                    <th>Monto</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody v-if="pays.length > 0">
                                <tr v-for="(pay, index) in pays" :key="index">
                                    <td>{{ pay.id }}</td>
                                    <td>
                                        <span v-if="pay.user_approved"> {{ pay.user_approved.name }} {{
                                            pay.user_approved.last_name }}</span>
                                        <span v-else>Sin usuario</span>
                                    </td>
                                    <td>
                                        {{ pay.date | toDateTime }}
                                    </td>
                                    <td>
                                        <div v-if="pay.bank_accounts">
                                            <span v-if="pay.bank_accounts.bank">
                                                {{ pay.bank_accounts.bank.name }}  
                                            </span>
                                            {{ pay.bank_accounts.number }}
                                        </div>
                                        <div v-else>
                                            S/N
                                        </div>
                                    </td>
                                    <td>
                                        <span v-if="pay.is_credit == 1">
                                            {{ (parseFloat(pay.amount) + parseFloat(pay.credit)) | toCurrency }}
                                        </span>
                                        <span v-else>
                                            {{ pay.amount | toCurrency }}

                                        </span>
                                    </td>
                                    <td>
                                        <a :href="`${globalUrlFiles}${pay.file}`" v-if="pay.file" target="_blank"
                                            style="border-radius: 5px 0px 5px 0px;" class="btn btn-outline-info m-1 btn-sm">
                                            <i class="fa-regular fa-file-pdf"></i> Archivo
                                        </a>
                                        <button v-if="!prop_edit" @click="formDataPays(check, pay)" v-can="'edit_pays'"
                                            class="btn btn-outline-warning m-1 btn-sm">
                                            <i class="fa-solid fa-hand-holding-dollar"> </i> Editar
                                        </button>
                                        <button @click="formDataPays(check, pay)" v-can="'pays_tax'" v-if="$route.name == 'tax'"
                                            class="btn btn-outline-warning m-1 btn-sm">
                                            <i class="fa-solid fa-hand-holding-dollar"> </i> Editar
                                        </button>
                                        <button v-if="!prop_edit && $route.name != 'tax'" type="button" @click="deleted(pay.id)"
                                            v-can="'deleted_pays'" class="btn-outline-danger btn m-1 btn-sm ">
                                            <i class="fa fa-trash"></i> Eliminar
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td align="center" colspan="6">Sin pagos.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <ModalFormComponentPays />
    </modal>
</template>