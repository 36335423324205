<template>
	<div class="col-12">
		<div class="card card-outline card-secondary">
			<div class="card-header">
				<h3 class="card-title">Lista de Propiedades</h3>
				<div class="card-tools">		
					<button @click="downloadExcel()" v-can="'add_buildings'" class="btn btn-sm btn-outline-success mr-1">
						<i class="fa-solid fa-file-excel"></i> Excel
					</button>			
					<router-link :to="`/buildings/form`" v-can="'add_buildings'" class="btn btn-sm btn-outline-success">
						<i class="fa fa-plus"></i> Propiedad
					</router-link>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search.name" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="row">
					<div class="col-md-12">
						<ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
							<li class="nav-item">
								<a class="nav-link active" @click="search.deactivate = 1" data-toggle="pill" role="tab"
									aria-selected="true">Activas</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" @click="search.deactivate = 2" data-toggle="pill" role="tab"
									aria-selected="false">Archivadas</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>Folio</th>
								<th>Folio Interno</th>
								<th>Nombre</th>
								<th>Dirección</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="buildings">
							<tr v-for="(building, index) in buildings.data" :key="index">
								<td>{{ building.folio }}</td>
								<td>{{ building.folio_int }}</td>
								<td>{{ building.name }}</td>
								<td>{{ building.address }}</td>								
								<td>
									<router-link :to="`/buildings/form/${building.id}`" v-can="'edit_buildings'" class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</router-link>

									<button  @click="changeStatus(building)" v-can="'edit_buildings'" class="btn m-1 btn-outline-info btn-sm">
										<span  v-if="building.deactivate_at" >
											<i class="fa-solid fa-boxes-packing"></i> Sacar del Archivo
										</span>
										<span v-else>
											<i class="fa-solid fa-box-archive"></i> Archivar	
										</span>
										
									</button>

									<button type="button" @click="deleted(building.id)" v-can="'deleted_buildings'" class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
				<pagination v-model="page" :records="buildings.total" :per-page="buildings.per_page" @paginate="list" />

				<!-- <pagination align="center" :data="buildins" :limit="8" @pagination-change-page="list">
            <span slot="prev-nav">&lt; Anterior</span>
            <span slot="next-nav">Siguiente &gt;</span>
        </pagination> -->
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
// import pagination from 'laravel-vue-pagination'
import request from '@/services/request'
import Pagination from 'vue-pagination-2';
export default {
	name: "buildins-table",
	components: {	
		Pagination
	},
	data() {
		return {
			buildings: {},
			search: {
				name:'',
				deactivate: 1,
			},
			page: 1
		}
	},
	mounted() {
		this.list();
		this.setHeaderTitle('Propiedades');
	},
	created() {
			Event.$on("event-load-buildins", () => {
					this.list();
			});
	},
	methods: {
		downloadExcel() {
			let data = new URLSearchParams(this.search).toString();			
			let url = this.globalUrlFiles + '/buildings/export?number=' + this.authUser.id+'&'+data;		
			window.open(url, '_blank');
		},
		changeStatus: function (building) {
			let tit = 'Archivar propiedad';
			let txt = 'Seguro que quiere archivar la propiedad';
			if(building.deactivate_at){
				tit = 'Sacar del archivo';
				txt = 'Seguro que quiere sacar del archivo la propiedad';
			}
			
			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callChangeStatus(building.id);
				}
			});
		},
		async callChangeStatus(id) {
			let _endpoint = '/buildings/buildings_status/' + id;
			try {
				let response = await request.post(_endpoint).then(function (response) { return response;});
				this.list();
				this.successNotification(response.data.message);			
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		deleted: function (id) {
			let tit = 'Eliminar propiedad';
			let txt = 'Seguro que quiere eliminar la propiedad';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/buildings/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response;});
				this.list();
				this.successNotification(response.data.message);			
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport(){
			this.$modal.show('example');
		},
		async list(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/buildings';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.buildings = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	watch: {		
		"search": {
			handler: function () {
				this.list();
			},
			deep: true
		}
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #ffffff;
	background-color: #214d83;
	border-color: #dee2e6 #dee2e6 #fff;
}
</style>
