<script>
import request from '@/services/request'
import { mapActions, mapGetters } from 'vuex';

import ModalFormComponent from '@/components/billing_data_provider/ModalBillingProviderComponent.vue';
export default {
    components: {
        ModalFormComponent,
    },
    data() {
        return {
            id_provider: '',
            listAr: []
        }
    },
    methods: {
        newDilling(account = {}) {
            Event.$emit('event-show-addBillingProvider', account);
        },
        async list(page = 1) {
            let search = {};
            let _endpoint = `/${this.id_provider}/billing_data`;
            try {
                let { data } = await request.list(_endpoint, page, search)
                if (data) {
                    console.log(data);
                    this.listAr = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        deleted: function (id) {
            let tit = 'Eliminar datos de facturación';
            let txt = 'Seguro que quiere eliminar los datos de facturación';

            this.$swal({
                title: tit,
                text: txt,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let _endpoint = `/${this.id_provider}/billing_data/${id}`;
                    try {
                        let response = await request.destroy(_endpoint).then(function (response) { return response; });
                        this.list();
                        this.successNotification(response.data.message);
                    } catch (error) {
                        this.errors = await this.errorNotification(error)
                    }
                }
            });
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    async mounted() {
        let id = this.$route.params.id;
        if (id) {
            this.id_provider = id;
        }
        if (this.authUser.organization_charts_id == 2) {
            this.id_provider = this.authUser.id;
        }
        await this.list();
    },
    created() {
        Event.$on("event-load-bills-provider", () => {
            this.list();
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <h5 v-if="this.authUser.organization_charts_id == 2">Mis datos de facturación</h5>
            <h5 v-else>Datos de facturación</h5>          
        </div>
        <div class="col-12 mt-3">
            <div class="table-responsive">
                <table class="table table-bordered text-center text-sm">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Razón social</th>
                            <th>R.F.C.</th>
                            <th>Dirección Fiscal</th>
                            <th>Tipo</th>
                            <th>Archivos</th>
                            <th>Opciones</th>
                        </tr>
                    </thead>
                    <tbody v-if="listAr.length > 0">
                        <tr v-for="(account, index) in listAr" :key="index">
                            <td>{{ account.id }}</td>
                            <td>{{ account.business_name }}</td>
                            <td>{{ account.rfc }}</td>
                            <td>{{ account.fiscal_address }}</td>
                            <td>
                                <span v-if="account.type">
                                    {{ account.type.name }}
                                </span>
                                <span v-else>
                                    S/T
                                </span>
                            </td>
                            <td>                                
                                <a v-tooltip.bottom-start="'Tooltip on bottom'" v-if="account.cedula" target="_blank"
                                    class="btn m-1 btn-outline-secondary btn-sm" :href="`${globalUrlFiles}${account.cedula}`">
                                    <i class="fas fa-file "> </i> Cédula fiscal
                                </a>
                                <a v-tooltip.bottom-start="'Tooltip on bottom'" v-if="account.compliance_opinion" target="_blank"
                                    class="btn m-1 btn-outline-secondary btn-sm" :href="`${globalUrlFiles}${account.compliance_opinion}`">
                                    <i class="fas fa-file "> </i> Opinion de cumplimiento
                                </a>
                                <a v-tooltip.bottom-start="'Tooltip on bottom'" v-if="account.repse" target="_blank"
                                    class="btn m-1 btn-outline-secondary btn-sm" :href="`${globalUrlFiles}${account.repse}`">
                                    <i class="fas fa-file "> </i> Archivo REPSE
                                </a>
                            </td>
                            <td>

                                <button @click="newDilling(account)" class="m-1 btn btn-outline-primary btn-sm">
                                    <i class="fas fa-eye "> </i> Ver más
                                </button>                               
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td align="center" colspan="6">Sin resultados.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ModalFormComponent :prop_edit="false"/>
    </div>
</template>